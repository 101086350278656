
import modalWrapper from "./modalWrapper.vue";
import { IonButton } from "@ionic/vue";

export default {
  props: {
    title: String,
    header: String,
    message: String,
    confirmationMessage: { Type: String, Default: "Okay" },
    declineMessage: String,
    modalVisible: Boolean,
    close: Function,
  },
  components: {
    modalWrapper,
    IonButton,
  },
  emits: ["accept", "decline"],
  setup(props, { emit }) {
    const handleAccept = () => {
      emit("accept");
      props.close();
    };

    const handleDecline = () => {
      emit("decline");
      props.close();
    };

    return { handleAccept, handleDecline };
  },
};
