import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    fill: _ctx.fill,
    mode: "md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, {
        position: _ctx.stacked ? 'stacked' : 'floating',
        color: "medium"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label ? _ctx.label : "Zip Code"), 1),
          (_ctx.required)
            ? (_openBlock(), _createBlock(_component_ion_text, {
                key: 0,
                color: "danger"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" *")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["position"]),
      _createVNode(_component_ion_input, {
        modelValue: _ctx.localModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
        inputmode: "numeric",
        type: "number",
        maxlength: "5",
        autoComplete: _ctx.autoComplete ? 'postal-code' : false,
        "data-cy": "input-zip",
        name: "zip",
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
        tabindex: _ctx.tabindex
      }, null, 8, ["modelValue", "autoComplete", "tabindex"]),
      (_ctx.searchBtn)
        ? (_openBlock(), _createBlock(_component_ion_button, {
            key: 0,
            slot: "end",
            fill: "clear",
            "item-right": "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('enter'))),
            color: "medium"
          }, {
            default: _withCtx(() => [
              (_ctx.searching)
                ? (_openBlock(), _createBlock(_component_ion_spinner, {
                    key: 0,
                    name: "lines-small"
                  }))
                : (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 1,
                    icon: _ctx.searchSharp,
                    color: "medium"
                  }, null, 8, ["icon"]))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_note, {
        color: "danger",
        "data-cy": "error-zip"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.error), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["fill"]))
}