
import { defineComponent, ref, watch, computed } from "vue";
import { IonButton, IonSpinner, IonRow, IonCol, IonLabel, IonItem, IonInput, IonText, IonCheckbox } from "@ionic/vue";

import { object, string } from "yup";
import { useField, useForm } from "vee-validate";

import modalWrapper from "@/components/modals/modalWrapper.vue";
import useCheckoutJS from "@/composables/useCheckoutJS";
import { ElavonResponse } from "@/models/PaymentModels";
import paymentTypeImage from "../paymentTypeImage.vue";
import { cardTypeEnum } from "@/enums/PaymentTypeEnum";
// @ts-ignore
import { useReCaptcha } from "vue-recaptcha-v3";
import { useStore } from "vuex";

export const creditCardSchema = object({
  cardNumber: string().required("Credit card number is required."),
  expirationDate: string()
    .required("Expiration date is required.")
    .min(4, "Expiration must be exactly 4 digits")
    .max(4, "Expiration must be exactly 4 digits"),
  cvv: string().required("CVV is required.").min(3, "CVV must be at least 3 characters").max(5, "CVV must be no more than 5 characters"),
  zipCode: string().required("Zipcode is required.").min(5, "Please enter a valid Zipcode").max(5, "Please enter a valid Zipcode"),
}).required();

export default defineComponent({
  props: { modalOpen: Boolean, hideCheckBox: Boolean },
  components: { IonSpinner, IonButton, IonRow, IonCol, IonLabel, IonItem, IonInput, IonText, modalWrapper, paymentTypeImage, IonCheckbox },
  emits: ["modalOpen", "addCreditCard"],
  setup(props, { emit }) {
    const store = useStore();

    const { getTokenizedPayment, paymentGatewaySuccess, paymentGatewayError } = useCheckoutJS();
    //@ts-ignore
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    const isCardProcessing = ref(false);
    const gatewayError = ref("");
    const saveCardCheckbox = ref(false);
    const defaultCardCheckbox = ref(false);
    const validatingCard = ref(false);
    const isLoggedIn = computed(() => store.getters.isLoggedIn);

    const { values, validate } = useForm({ validationSchema: creditCardSchema });
    const { value: cardNumber, errorMessage: cardNumberError } = useField("cardNumber");
    const { value: expirationDate, errorMessage: expirationDateError } = useField("expirationDate");
    const { value: cvv, errorMessage: cvvError } = useField("cvv");
    const { value: zipCode, errorMessage: zipCodeError } = useField("zipCode");

    const acceptedCardTypes = [cardTypeEnum.visa, cardTypeEnum.masterCard, cardTypeEnum.discover, cardTypeEnum.amex];

    watch(paymentGatewaySuccess, (response: ElavonResponse) => {
      isCardProcessing.value = false;

      if (response.validElavonResponse) {
        gatewayError.value = "";
        emit("addCreditCard", {
          creditCard: response.convertToCard(),
          saveCard: saveCardCheckbox.value,
          defaultCreditCard: defaultCardCheckbox.value,
        });
        emit("modalOpen", false);
      } else {
        const avsError = "Declined - AVS Mismatch.";
        if (!paymentGatewayError.value) {
          gatewayError.value = avsError;
        }
      }
    });

    watch(paymentGatewayError, (error) => {
      isCardProcessing.value = false;
      if (error === undefined) {
        gatewayError.value = "Card declined, please try again.";
      } else {
        gatewayError.value = error;
      }
    });

    const closeModal = () => {
      emit("modalOpen", false);
      gatewayError.value = "";
      cardNumber.value == "";
      cvv.value == "";
      zipCode.value == "";
      expirationDate.value == "";
    };

    const recaptchaToken = ref("");
    const recaptcha = async () => {
      await recaptchaLoaded();
      // Execute reCAPTCHA with action "paymentValidation".
      recaptchaToken.value = await executeRecaptcha("paymentValidation");
    };

    const handleSubmit = () => {
      validate().then(async (form) => {
        if (!form.valid) return;
        recaptcha().then(() => {
          isCardProcessing.value = true;
          getTokenizedPayment(
            { cardNumber: cardNumber.value, cvv: cvv.value, expirationDate: expirationDate.value, zipCode: zipCode.value },
            recaptchaToken.value
          );
        });
      });
      recaptchaToken.value = "";
    };

    return {
      closeModal,
      handleSubmit,
      validatingCard,
      isCardProcessing,
      gatewayError,
      cardNumber,
      cardNumberError,
      expirationDate,
      expirationDateError,
      zipCode,
      zipCodeError,
      cvv,
      cvvError,
      values,
      acceptedCardTypes,
      saveCardCheckbox,
      isLoggedIn,
      defaultCardCheckbox,
    };
  },
});
