import { DateTime } from "luxon";
import { ToppingPrice } from "./../models/menuModels";
import { StoreLocation, LocationHours, LocationStatus } from "@/models/locationModels";
import { ActionContext } from "vuex";
import { fetchLocationDetail, fetchLocationHours, fetchLocationStatus, isOpen, isStoreCurrentlyOpen } from "@/services/locationService";
import { localStorageEnum, replaceLocalStorage } from "@/services/localStorageService";
import { Menu, MenuCategory, MenuItem } from "@/models/menuModels";
import { MenuCategoryEnum } from "@/enums/MenuCategoryEnum";
import { changeOrderDetails, fetchMenu } from "@/services/orderService";
import locationManager from "@/utils/locationManager";
import { Address } from "@/models/addressModels";
import { AddToCartRes, DeliveryInfo, OrderLogistics, PickupInfo, WebSpecial } from "@/models/cartModels";
import { OrderTypeEnum, OrderTypeSortOrder } from "@/enums/OrderTypeEnum";
import { dateTimeSplit } from "@/services/timeService";
import { PickupVehicle } from "@/models/checkoutModels";
import { useUrlQueryParser } from "@/composables/useUrlQueryParser";
import LocationManager from "@/utils/locationManager";

export interface LocationState {
  fetchingLocation: boolean;
  location?: StoreLocation;
  hours: LocationHours[];
  menu?: Menu;

  deliverToAddress?: Address;
  orderType: OrderTypeEnum;
  selectedDateTime: DateTime;
  isImmediate?: boolean;
  pickupInfo: PickupInfo;
  initializing: boolean;
}
const urlHash = window.location.hash;

const locationState: LocationState = {
  fetchingLocation: false,
  hours: [],
  orderType: urlHash === '#delivery' ? OrderTypeEnum.delivery : OrderTypeEnum.pickup,
  pickupInfo: new PickupInfo(),
  initializing: true,
  selectedDateTime: DateTime.now(),
  isImmediate: true,
};

export default {
  state: locationState,
  mutations: {
    SET_LOCATION_FETCHING: (state: LocationState, fetchingLocation: boolean): void => {
      state.fetchingLocation = fetchingLocation;
    },
    SET_SELECTED_LOCATION: (state: LocationState, location: StoreLocation): void => {
      state.location = new StoreLocation(location);
      replaceLocalStorage(localStorageEnum.storeNumber, location?.id, 0.4); // Dynatrace Data
      // stop re-writting the pickupinfo location if it's been set.
      if (state.pickupInfo.location) return;
      const defaultPickupType = state.location.pickupOrderTypes?.sort(
        //@ts-ignore
        (a, b) => OrderTypeSortOrder.indexOf(a.name) - OrderTypeSortOrder.indexOf(b.name)
      )[0];
      state.pickupInfo.location = state.orderType == OrderTypeEnum.delivery ? "" : defaultPickupType?.name ?? "Pick Up";
    },
    CLEAR_SELECTED_LOCATION: (state: LocationState): void => {
      state.location = undefined;
      state.deliverToAddress = undefined;
    },
    SET_HOURS: (state: LocationState, hours: LocationHours[]): void => {
      state.hours = hours;
      if (state.location) state.location.hours = hours;
    },
    SET_LOCATION_MENU: (state: LocationState, menu: Menu): void => {
      state.menu = menu;
    },
    SET_LOCATION_STATUS: (state: LocationState, status: LocationStatus): void => {
      if (state.location) state.location.storeStatus = status;
    },
    SET_DELIVERY_ADDRESS: (state: LocationState, address: Address): void => {
      state.deliverToAddress = address;
    },
    UPDATE_ORDER_TYPE: (state: LocationState, orderType: OrderTypeEnum): void => {
      replaceLocalStorage(localStorageEnum.orderType, orderType, 0.4); // Dynatrace Data
      state.orderType = orderType;
    },
    UPDATE_PICKUP_LOCATION: (state: LocationState, locationString: string): void => {
      state.pickupInfo.location = locationString;
    },
    UPDATE_ORDER_DATETIME: (state: LocationState, info: DateTime): void => {
      state.selectedDateTime = info?.plus({ hours: state.location?.offsetFromBrowser ?? 0 });
    },
    UPDATE_LOCATION_INIT: (state: LocationState, initializing: boolean): void => {
      state.initializing = initializing;
    },
    UPDATE_IS_IMMEDIATE_FLAG: (state: LocationState, status: boolean): void => {
      state.isImmediate = status;
    },
    UPDATE_PICKUP_VEHICLE: (state: LocationState, car: PickupVehicle) => {
      state.pickupInfo.color = car.color;
      state.pickupInfo.make = car.make;
      state.pickupInfo.model = car.model;
    },
    CLEAR_LOGISTICS_INFO: (state: LocationState) => {
      state.selectedDateTime = DateTime.now();
      state.isImmediate = true;
      state.pickupInfo = new PickupInfo();
    },
  },
  actions: {
    updateSelectedLocation(context: ActionContext<LocationState, any>, locationId: string) {
      return new Promise((resolve, reject) => {
        if (context.getters.isFetchingLocation) return;
        if (locationId == "" || locationId == "0") return context.commit("UPDATE_LOCATION_INIT", false);
        // This used to check if we had location info already stored in nearLocations (data from location search)
        // locationIdInNearLocations: (state: locationSearchState) => (id: string) => state.nearLocations.find((l) => l.id == id),
        // nearLocations doesn't have all of the data we need, updating to fetchLocationDetail every time.
        context.commit("SET_LOCATION_FETCHING", true);
        fetchLocationDetail(locationId)
          .then((location: StoreLocation) => {
            if (!location) return;
            context.commit("SET_SELECTED_LOCATION", location);
            if (!location.hours || location.hours.length === 0) context.dispatch("setLocationHours", locationId);
            if (!context.state.initializing) {
              changeOrderDetails(context.getters.getFullOrder)
                .then((cartResponse: AddToCartRes) => {
                  if (cartResponse.lineItems) context.dispatch("resetCart", cartResponse.lineItems);
                  if (cartResponse.promiseTime) context.dispatch("updateEstimatedTime", cartResponse.promiseTime);
                  resolve(cartResponse);
                })
                .catch((error) => reject(error));
            }
            replaceLocalStorage(localStorageEnum.orderLogistics, context.getters.getOrderLogistics, 0.1);
            context.commit("UPDATE_LOCATION_INIT", false);
            context.dispatch("setMenu", location.id ?? LocationManager.getInstance().getLocationId());
          })
          .catch((error) => reject(error))
          .finally(() => context.commit("SET_LOCATION_FETCHING", false));
      });
    },
    selectPickupLocation(context: ActionContext<LocationState, any>, locationId: string): void {
      context.commit("UPDATE_ORDER_TYPE", OrderTypeEnum.pickup);
      context.dispatch("updateSelectedLocation", locationId).catch((error) => Promise.reject(error));
    },
    selectDeliveryLocation(context: ActionContext<LocationState, any>, locationId: string) {
      context.commit("UPDATE_ORDER_TYPE", OrderTypeEnum.delivery);
      context.commit("SET_DELIVERY_ADDRESS", context.getters.getSearchedDeliveryAddress);

      return context
        .dispatch("updateSelectedLocation", locationId)
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));
    },
    changeOrderType(context: ActionContext<LocationState, any>, orderType: OrderTypeEnum): void {
      context.commit("UPDATE_ORDER_TYPE", orderType);
    },
    changePickupLocation(context: ActionContext<LocationState, any>, location: string): void {
      context.commit("UPDATE_PICKUP_LOCATION", location);
      context.dispatch("updateSelectedLocation", context.getters.getSelectedLocation.id);
    },
    setLocationHours(context: ActionContext<LocationState, any>, locationId: string): void {
      fetchLocationHours(locationId)
        .then((hours: LocationHours[]) => context.commit("SET_HOURS", hours))
        .finally(() => context.commit("SET_LOCATION_FETCHING", false));
    },
    updateLocationStatus(context: ActionContext<LocationState, any>, locaitonId: string): void {
      fetchLocationStatus(locaitonId).then((status: LocationStatus) => context.commit("SET_LOCATION_STATUS", new LocationStatus(status)));
    },
    /**
     * Based on orderDetail, update state to either pickup or delivery and set the selected location.
     * This also loads the menu (uses locationManager set location as a backup so there will always be menu data for web crawlers)
     */
    initStoreLocation(context: ActionContext<LocationState, any>, orderLogistics: OrderLogistics): void {
      const { getLocationSlugOrId } = useUrlQueryParser();
      const forcedLocation = getLocationSlugOrId();
      if (forcedLocation) {
        orderLogistics.storeNumber = forcedLocation;
        context.commit("UPDATE_LOCATION_INIT", false);
      }
      if (orderLogistics) {
        if (orderLogistics.orderType?.toLowerCase().includes("delivery") && !forcedLocation) {
          context.dispatch("setLastDeliveryAddress", orderLogistics.deliveryInfo);
          context.dispatch("selectDeliveryLocation", orderLogistics.storeNumber);
        } else {
          context.dispatch("selectPickupLocation", orderLogistics.storeNumber);
        }
      } else {
        context.dispatch("setMenu", locationManager.getInstance().getLocationId()).catch((error) => Promise.reject(error));
        context.commit("UPDATE_LOCATION_INIT", false);
      }
    },
    updatePickupTime(context: ActionContext<LocationState, any>, info: DateTime): void {
      context.commit("UPDATE_ORDER_DATETIME", info);
    },
    updateIsImmediateFlag(context: ActionContext<LocationState, any>, status: boolean): void {
      context.commit("UPDATE_IS_IMMEDIATE_FLAG", status);
    },
    setMenu(context: ActionContext<LocationState, any>, locationId?: string): void {
      const whichId = locationId ?? context.state.location?.id ?? locationManager.getInstance().getLocationId();
      if (whichId === context.state.menu?.location) return;
      fetchMenu(whichId)
        .then((menu) => context.commit("SET_LOCATION_MENU", menu))
        .catch((error) => Promise.reject(error));
    },
    updatePickupVehicle(context: ActionContext<LocationState, any>, car: PickupVehicle) {
      context.commit("UPDATE_PICKUP_VEHICLE", car);
    },
    clearLogisticsInfo(context: ActionContext<LocationState, any>) {
      context.commit("CLEAR_LOGISTICS_INFO");
      context.commit("CLEAR_SELECTED_LOCATION");
    },
  },
  getters: {
    isFetchingLocation: (state: LocationState): boolean => state.fetchingLocation,
    isOpen: (state: LocationState): boolean => (state.location ? isOpen(state.location.storeStatus, state.hours) : false),
    getSelectedLocation: (state: LocationState): StoreLocation | undefined => state.location,
    getSelectedLocationlunchCombos: (state: LocationState): WebSpecial[] | undefined => state.location?.lunchCombos,
    getSelectedLocationGeolocation: (state: LocationState): { latitude: number; longitude: number } => ({
      latitude: state.location?.coordinate.latitude ?? 0,
      longitude: state.location?.coordinate.longitude ?? 0,
    }),
    getHours: (state: LocationState): LocationHours[] => state.hours,
    isStoreTakingOnlineOrders: (state: LocationState): boolean => {
      return isStoreCurrentlyOpen(state.location?.hours ?? state.hours);
    },
    isDeliveryTurnedOff: (state: LocationState): boolean => {
      if (state.location?.storeStatus && state.location?.storeStatus.isDelTurnedOff) return true;
      return false;
    },
    getDeliveryDelay: (state: LocationState): number => {
      // 45min is the fallback delivery delay
      return state.location?.storeStatus && state.location?.storeStatus.deliverydelay ? state.location?.storeStatus.deliverydelay : 45;
    },
    getNonDeliveryDelay: (state: LocationState): number => {
      // 30min is the fallback non delivery delay
      return state.location?.storeStatus && state.location?.storeStatus.nondeliverydelay ? state.location?.storeStatus.nondeliverydelay : 30;
    },
    isStoreInActive: (state: LocationState): boolean => {
      if (state.location?.storeStatus && (!state.location?.storeStatus.isActive || state.location.storeStatus.offline)) return true;
      return false;
    },
    getLocationMenu: (state: LocationState): MenuCategory[] => state.menu?.category?.filter((m) => m.name !== MenuCategoryEnum.donations.name) ?? [],
    getDonationMenu: (state: LocationState): MenuItem | undefined =>
      state.menu?.category?.find((m) => m.name === MenuCategoryEnum.donations.name)?.items[0],
    getToppingPrices:
      (state: LocationState) =>
      (item: MenuItem): ToppingPrice[] =>
        state.menu?.extraToppings.filter((t) => t?.webcatPid === item?.webcatPid) ?? [],
    getDeliverToAddress: (state: LocationState): Address | undefined => state.deliverToAddress,
    getPickupInfo: (state: LocationState): PickupInfo => {
      return state.pickupInfo;
    },
    getDeliveryInfo: (state: LocationState, getters: any): DeliveryInfo => {
      // API requires deliveryCharge to not be 0 or '0' on delivery orders
      // may be able to remove this later.
      const deliveryCharge = getters.getDeliveryCharge == 0 ? "0.00" : getters.getDeliveryCharge;
      const deliveryAddress = new Address(getters.getDeliverToAddress);
      return new DeliveryInfo({
        ...deliveryAddress,
        zipCode: deliveryAddress.zipCode,
        driverTip: getters.getTip,
        deliveryCharge: deliveryCharge,
      });
    },
    getCurrentOrderType: (state: LocationState): OrderTypeEnum => state.orderType,
    isDelivery: (state: LocationState): boolean => state.orderType === OrderTypeEnum.delivery,
    isLocationReady: (state: LocationState): boolean => !state.initializing,
    getOrderDateTime: (state: LocationState): string => state.selectedDateTime,
    getOrderDate: (state: LocationState): string => dateTimeSplit(state.selectedDateTime).date,
    getOrderTime: (state: LocationState): string => dateTimeSplit(state.selectedDateTime).time,
    getIsImmediate: (state: LocationState): boolean => {
      return state.isImmediate ?? false;
    },
  },
};
