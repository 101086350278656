import { createSlug, firstNumberIndex, stringToBool } from "@/services/stringService";
import { OrderType } from "@/enums/OrderTypeEnum";
import { DateTime } from "luxon";
import { Address, Coordinate } from "./addressModels";
import { isOpen, displayOpenClosedTime, weeklyHours, displayStatus, todayHours } from "@/services/locationService";
import { WebSpecial } from "./cartModels";
import { daysFromNow } from "@/services/timeService";
/**
  @property {string} id
  @property {string} name
  @property {string} slug: dash delimited name -> url
  @property {Address} address
  @property {Coordinate} coordinate: latitude and longitude
  @property {number} deliveryCharge
  @property {number} distance
  @property {boolean} dstIndicator
  @property {boolean} hasLoyalty
  @property {string} phone
  @property {string} pos
  @property {number} radius
  @property {number} timeZone: utc offset
  @property {string} yelp: url
  @property {number} maxTimeOrderDays
  @property {openForWeb} openForWeb
  @property {number} taxRate
  @property {number} maxOrderDays

  @property {boolean} highlighted: map uses to show logo pin

  @property {LocationStatus} storeStatus
  @property {LocationHours[]?} hours
  @property {string[]} paymentTypes
  @property {string[]} orderTypes
  @property {string[]} image
  @property {} webSpecial
  @property {} lunchCombos
  @property {string[]} creditCards
 */
export class StoreLocation {
  id: string;
  name: string;
  slug: string;
  address: Address;
  coordinate: Coordinate;
  deliveryCharge: number;
  distance: number;
  dstIndicator: boolean;
  hasLoyalty: boolean;
  phone: string;
  pos: string;
  radius: number;
  timeZone: number;
  yelp?: string;
  maxTimeOrderDays: number;
  openForWeb: boolean;
  taxRate: number;
  maxOrderDays: number;
  highlighted: boolean;
  storeStatus: LocationStatus;
  hours?: LocationHours[];
  paymentType: string[];
  orderType?: string[];
  image: string[];
  webSpecial: WebSpecial[];
  webSpecials?: WebSpecial[];
  lunchCombos?: WebSpecial[];
  creditCards: string[];

  constructor(data?: Partial<StoreLocation>) {
    this.address = new Address(
      //@ts-ignore
      data?.address ?? { address1: data?.address1, address2: data?.address2, city: data?.city, state: data?.state, zip: data?.zip }
    );
    this.deliveryCharge = data?.deliveryCharge ?? 0;
    this.distance = data?.distance ?? 0;
    this.dstIndicator = data?.dstIndicator ?? false;
    this.hasLoyalty = data?.hasLoyalty ?? true;
    this.id = data?.id ?? "";
    //@ts-ignore
    this.coordinate = new Coordinate(data?.coordinate ?? { latitude: data?.latitude, longitude: data?.longitude });
    //@ts-ignore
    this.maxOrderDays = data?.maxOrderDays ?? 14;
    this.name = data?.name ?? "";
    this.phone = data?.phone ?? "";
    this.pos = data?.pos ?? "";
    this.radius = data?.radius ?? 25;
    this.slug = data?.slug ? createSlug(data?.slug) : "";
    this.timeZone = data?.timeZone ? +data?.timeZone : -5;
    this.yelp = data?.yelp ?? "";
    this.maxTimeOrderDays = data?.maxTimeOrderDays ?? 0;
    this.openForWeb = data?.openForWeb ?? false;
    //@ts-ignore
    this.taxRate = data?.taxRate ?? 0.0725;
    //@ts-ignore
    this.deliveryCharge = data?.deliveryCharge ?? 0;

    this.paymentType = data?.paymentType ?? [];
    this.hours = data?.hours;
    this.orderType = data?.orderType;
    this.image = data?.image ?? [];
    // could be webSpecial or webSpecials
    this.webSpecial = data?.webSpecial
      ? data?.webSpecial.map((s) => new WebSpecial(s))
      : data?.webSpecials
      ? data?.webSpecials.map((s) => new WebSpecial(s))
      : [];
    //@ts-ignore
    if (typeof this.webSpecial[0] == "string" && !this.webSpecial[0]?.includes("no result")) this.webSpecial = [];
    this.lunchCombos = data?.lunchCombos ? data?.lunchCombos.map((s) => new WebSpecial(s)) : [];
    //@ts-ignore
    if (typeof this.lunchCombos[0] == "string" && !this.lunchCombos[0]?.includes("no result")) this.lunchCombos = [];
    this.creditCards = data?.creditCards ?? [];
    this.storeStatus = new LocationStatus(data?.storeStatus);
    this.highlighted = data?.highlighted ?? false;
  }
  get displayOrderTypes() {
    return this.orderType?.map((type) => OrderType.find((o) => o.name === type));
  }
  get pickupOrderTypes() {
    return this.displayOrderTypes?.filter((o) => !o?.isDelivery) ?? [];
  }
  get isOpen() {
    return this.storeStatus && this.hours ? isOpen(this.storeStatus, this.hours) : true;
  }
  get displayTime() {
    return this.storeStatus && this.hours ? displayOpenClosedTime(this.storeStatus, this.hours) : "";
  }
  get isTempClosed() {
    return !this.hours || this.hours?.length === 0 || !this.orderType || this.orderType?.length === 0 || this.hours[0].isClosed;
  }
  /**
   * @returns html
   */
  get boldDisplayTime() {
    const timeStart = firstNumberIndex(this.displayTime);
    return timeStart != -1
      ? `${this.displayTime.slice(0, timeStart - 1)} <b>${this.displayTime.slice(timeStart, this.displayTime.length)}</b>`
      : `<b>${this.displayTime}</b>`;
  }
  get weeklyHours() {
    return this.hours ? weeklyHours(this.hours) : "";
  }
  get displayStatus() {
    return this.storeStatus && this.hours ? displayStatus(this) : "";
  }

  get todayHours() {
    return this.hours ? todayHours(this.hours) : undefined;
  }

  get tomorrowHours() {
    return this.hours ? this.tomorrowHours(this.hours) : undefined;
  }
  get offsetFromBrowser() {
    let browserOffset = DateTime.local().offset / 60;
    if (DateTime.local().isInDST) {
      browserOffset--;
    }
    return this.timeZone - browserOffset;
  }
  deliveryHours(selectedDate = DateTime.now()): DateTime[] {
    const selectedDateIndex = daysFromNow(selectedDate);
    // @ts-ignore
    const selectedDateHours = this.hours[selectedDateIndex];

    // If no hours found or the location is not open return empty array.
    if (!selectedDateHours || selectedDateHours.isClosed) return [];

    let firstWebOrder = selectedDateHours.firstWebOrder;
    if (firstWebOrder.minute % 15 > 0) firstWebOrder = firstWebOrder.plus({ minutes: 15 - (firstWebOrder.minute % 15) });

    const lastWebOrder = selectedDateHours.lastWebOrder;
    let nextOrder = DateTime.now().plus({ minutes: this.storeStatus.deliverydelay ?? 45 });
    if (selectedDateIndex == 0) {
      // setup default start time
      if (nextOrder.minute % 15 > 0) nextOrder = nextOrder.plus({ minutes: 15 - (nextOrder.minute % 15) });
      // if nextOrder is before firstWebOrder, then set nextOrder to firstWebOrder
      if (nextOrder < firstWebOrder) nextOrder = firstWebOrder;
    } else {
      nextOrder = firstWebOrder;
    }

    const times: DateTime[] = [];
    for (let time = nextOrder; time.minus({ minutes: 1 }) <= lastWebOrder; time = time.plus({ minutes: 15 })) {
      if (time >= DateTime.now()) times.push(time);
    }
    // If no times were added (aka 'after hours') return nothing
    if (times.length == 0) return [];
    // Ensure lastWebOrder can get added if missing.
    if (times[times.length - 1].toLocaleString(DateTime.TIME_SIMPLE) !== lastWebOrder.toLocaleString(DateTime.TIME_SIMPLE)) times.push(lastWebOrder);
    return times;
  }
  nonDeliveryHours(selectedDate = DateTime.now()): DateTime[] {
    const selectedDateIndex = daysFromNow(selectedDate);
    // @ts-ignore
    const selectedDateHours = this.hours[selectedDateIndex];

    // If no hours found or the location is not open return empty array.
    if (!selectedDateHours || selectedDateHours.isClosed) return [];

    let firstWebOrder = selectedDateHours.firstWebOrder;
    if (firstWebOrder.minute % 15 > 0) firstWebOrder = firstWebOrder.plus({ minutes: 15 - (firstWebOrder.minute % 15) });

    const lastWebOrder = selectedDateHours.lastWebOrder;
    let nextOrder = DateTime.now().plus({ minutes: this.storeStatus.nondeliverydelay ?? 30 });
    if (selectedDateIndex == 0) {
      // setup default start time
      if (nextOrder.minute % 15 > 0) nextOrder = nextOrder.plus({ minutes: 15 - (nextOrder.minute % 15) });
      // if nextOrder is before firstWebOrder, then set nextOrder to firstWebOrder
      if (nextOrder < firstWebOrder) nextOrder = firstWebOrder;
    } else {
      nextOrder = firstWebOrder;
    }

    const times: DateTime[] = [];
    for (let time = nextOrder; time.minus({ minutes: 1 }) <= lastWebOrder; time = time.plus({ minutes: 15 })) {
      if (time >= DateTime.now()) times.push(time);
    }
    // If no times were added (aka 'after hours') return nothing
    if (times.length == 0) return [];
    // Ensure lastWebOrder can get added if missing.
    if (times[times.length - 1].toLocaleString(DateTime.TIME_SIMPLE) !== lastWebOrder.toLocaleString(DateTime.TIME_SIMPLE)) times.push(lastWebOrder);
    return times;
  }
  pickupMinutes() {
    return this.storeStatus.nondeliverydelay ?? 30;
  }
  deliveryMinutes() {
    return this.storeStatus.deliverydelay ?? 45;
  }
  get paymentTypes() {
    return this.paymentType.map((t) => t.toLowerCase());
  }
  get takesCashPayment() {
    return this.paymentTypes.includes("cash");
  }
  get takesCreditCardPayment() {
    return this.paymentTypes.includes("credit");
  }
  get takesGiftCardPayment() {
    return this.paymentTypes.includes("gift card");
  }
}
/**
 * Store Location single day hours
 * @property {DateTime} date - Accepts sql datetime string
 * @property {DateTime} open - Accepts 24 hour time string _or_ sql datetime string
 * @property {DateTime} close - Accepts 24 hour time string _or_ sql datetime string
 * @property {DateTime} firstWebOrder - Accepts 24 hour time string _or_ sql datetime string
 * @property {DateTime} lastWebOrder - Accepts 24 hour time string _or_ sql datetime string
 * @property {string} day - Day name abbreviation (Fri)
 *
 * @getter {string} openTime - 12hour time string (9:00 am)
 * @getter {string} closeTime - 12hour time string (10:00 pm)
 * @getter {string} fullDayName - Day name (Thursday)
 */
export class LocationHours {
  date: DateTime;
  open: DateTime;
  close: DateTime;
  firstWebOrder: DateTime;
  lastWebOrder: DateTime;
  day: string;
  isOpen: boolean;

  constructor(hours?: Partial<LocationHours>) {
    const firstWebOrder = hours?.firstWebOrder;
    const lastWebOrder = hours?.lastWebOrder;
    this.date = DateTime.fromSQL(hours?.date);
    this.open = hours?.open?.length > 8 ? DateTime.fromISO(hours?.open) : DateTime.fromSQL(`${hours?.date} ${hours?.open}`);
    //* api endpoints return either 24 hour time or sql datetime. This is necessary to determine correct date
    this.close =
      hours?.close?.length > 8
        ? DateTime.fromISO(hours?.close)
        : +hours?.close?.slice(0, 2) <= 4 // less than 4 am
        ? DateTime.fromSQL(`${hours?.date} ${hours?.close}`).plus({ days: 1 })
        : DateTime.fromSQL(`${hours?.date} ${hours?.close}`);
    this.firstWebOrder = firstWebOrder?.length > 8 ? DateTime.fromISO(firstWebOrder) : DateTime.fromSQL(`${hours?.date} ${firstWebOrder}`);
    this.lastWebOrder =
      hours?.close?.length > 8
        ? DateTime.fromISO(lastWebOrder)
        : +lastWebOrder?.slice(0, 2) <= 4 // less than 4 am
        ? DateTime.fromSQL(`${hours?.date} ${lastWebOrder}`).plus({ days: 1 })
        : DateTime.fromSQL(`${hours?.date} ${lastWebOrder}`);
    this.day = hours?.day ?? "";
    this.isOpen = stringToBool(hours?.isOpen);
  }

  get openTime(): string {
    return this.open.toLocaleString(DateTime.TIME_SIMPLE);
  }
  get openTime24(): string {
    return this.open.toLocaleString(DateTime.TIME_24_SIMPLE);
  }
  get closeTime(): string {
    return this.close.toLocaleString(DateTime.TIME_SIMPLE);
  }
  get closeTime24(): string {
    return this.close.toLocaleString(DateTime.TIME_24_SIMPLE);
  }
  get fullDayName(): string {
    return this.date.toFormat("cccc");
  }
  get isClosed(): boolean {
    const timeBetweenFirstAndLast = this.lastWebOrder.diff(this.firstWebOrder, ["minutes"]).values.minutes;
    const timeBetweenOpenAndClose = this.close.diff(this.open, ["minutes"]).values.minutes;
    // location isClosed if isOpen flag is false, if first and last times are a min or less apart.
    return !this.isOpen || timeBetweenFirstAndLast <= 1 || timeBetweenOpenAndClose <= 1;
  }
}
/**
  @property {boolean} offline: force closed message
  @property {boolean} isDelTurnedOff: No delivery
  @property {number} deliverydelay: Delivery delay in minutes
  @property {number} nondeliverydelay: Non-delivery delay in minutes
  @property {boolean} preopen: Store coming soon
  @property {boolean} isActive: Taking only orders
  @property {boolean} isRedRobinStore
  @property {boolean} isContactlessDelivery:
  @property {boolean} useNewPipes:
  @property {boolean} nonDeliveryGiftCard:
 */
export class LocationStatus {
  offline: boolean;
  isDelTurnedOff: boolean;
  deliverydelay: number;
  nondeliverydelay: number;
  preopen: boolean;
  isActive?: boolean;
  isRedRobinStore: boolean;
  isContactlessDelivery: boolean;
  useNewPipes: boolean;
  nonDeliveryGiftCard: boolean;

  constructor(data?: Partial<LocationStatus>) {
    this.offline = stringToBool(data?.offline) ?? false;
    this.isDelTurnedOff = stringToBool(data?.isDelTurnedOff) ?? false;
    this.deliverydelay = data?.deliverydelay ? +data?.deliverydelay : 40;
    this.nondeliverydelay = data?.nondeliverydelay ? +data?.nondeliverydelay : 30;
    this.preopen = stringToBool(data?.preopen) ?? false;
    this.isActive = stringToBool(data?.isActive) ?? true;
    this.isRedRobinStore = stringToBool(data?.isRedRobinStore) ?? false;
    this.isContactlessDelivery = stringToBool(data?.isContactlessDelivery) ?? true;
    this.useNewPipes = stringToBool(data?.useNewPipes) ?? true;
    this.nonDeliveryGiftCard = stringToBool(data?.nonDeliveryGiftCard) ?? false;
  }
}

export class SimpleLocation {
  locationName: string;
  locationUrlSlug: string;
  city: string;
  state: string;
  constructor(location: SimpleLocation) {
    this.locationName = location.locationName;
    this.locationUrlSlug = location.locationUrlSlug;
    this.city = location.city;
    this.state = location.state;
  }
}
