
import { IonButton, IonRow, IonCol, IonCheckbox, IonText, IonLabel, IonItem, toastController } from "@ionic/vue";
import { defineComponent, PropType, ref, watch, computed, nextTick, onMounted } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";

import { Account } from "@/models/account/authModels";
import { passwordResetUrl } from "@/services/authConfig";
import Name from "@/components/inputs/name.vue";
import Email from "@/components/inputs/email.vue";
import TextInput from "@/components/inputs/textInput.vue";
import selectLocation from "@/components/inputs/selectLocation.vue";
import { fetchClosestLocationsAddress, fetchLocationDetail } from "@/services/locationService";
import { LOCATION_SEARCH_LIMIT } from "@/constants";
import { StoreLocation } from "@/models/locationModels";
import { updateAccountInfo, verifyEmail } from "@/services/accountService";
import UserProfile from "@/models/account/userProfile";
import { useApiResponse } from "@/composables/useApiResponse";
import Phone from "../inputs/phone.vue";
import {isPhoneNumberValid, numberSuffix} from "@/services/stringService";
import Birthday from "@/components/inputs/birthday.vue";
import { useStore } from "vuex";
import useShowRewards from "@/composables/useShowRewards";

export default defineComponent({
  props: { account: { type: Object as PropType<Account>, required: true } },
  components: {
    IonButton,
    IonRow,
    IonCol,
    Name,
    Email,
    TextInput,
    selectLocation,
    IonText,
    IonCheckbox,
    IonLabel,
    IonItem,
    Phone,
    Birthday,
  },
  setup(props) {
    const store = useStore();
    const { isLoyaltyMember } = useShowRewards();
    //VALIDATION AND DATA
    const showEditView = ref(false);
    const schema = computed(() => {
      return yup.object({
        customerId: yup.string(),
        firstName: yup.string().required().label("First Name"),
        lastName: yup.string().required().label("Last Name"),
        emailAddress: yup.string().email().required().label("Email"),
        phoneNumber: yup
          .string()
          .label("Phone number")
          .test("phoneNumber", "Phone number is not valid", (value) => isPhoneNumberValid(value)),
        zipCode: yup
          .string()
          .typeError("Must be a valid zip code")
          .min(5, "Must be exactly 5 digits")
          .max(5, "Must be exactly 5 digits")
          .required()
          .label("Zip Code"),
        favoriteStore: yup.number(),
        glutenFree: yup.number(),
        vegan: yup.number(),
        vegetarian: yup.number(),
        birthdate: yup.string(),
      });
    });
    const form = useForm({
      validationSchema: schema,
      initialValues: props.account,
    });

    const vegan = ref(+props.account.vegan);
    const vegetarian = ref(+props.account.vegetarian);
    const glutenFree = ref(+props.account.glutenFree);
    const hasDietaryPreferences = computed(() => vegan.value || vegetarian.value || glutenFree.value);
    const dietaryPreferencesList = computed(() => {
      const arr: string[] = [];
      if (vegan.value) arr.push("Vegan");
      if (vegetarian.value) arr.push("Vegetarian");
      if (glutenFree.value) arr.push("Gluten Free");
      return arr;
    });
    watch(vegan, () => (formValues.value.vegan = vegan.value ? 1 : 0));
    watch(vegetarian, () => (formValues.value.vegetarian = vegetarian.value ? 1 : 0));
    watch(glutenFree, () => (formValues.value.glutenFree = glutenFree.value ? 1 : 0));

    const updateBirthdate = (date: string) => {
      formValues.value.birthdate = date; // yyyy-mm-dd
    };

    const formValues = ref(form.values);
    watch(
      formValues,
      (newVal) => {
        //@ts-ignore
        vegan.value = +newVal?.vegan ?? false;
        //@ts-ignore
        vegetarian.value = +newVal?.vegetarian ?? false;
        //@ts-ignore
        glutenFree.value = +newVal?.glutenFree ?? false;
        form.values = newVal;
      },
      { immediate: true, deep: true }
    );
    const useFields = () => {
      for (let propName in schema.value.fields) {
        useField(propName);
      }
    };

    const locationList = ref(new Array<StoreLocation>());
    const favoriteLocationName = ref("No Location Selected");

    onMounted(() => {
      useFields();
      setFavoriteLocationName();
    });

    const setFavoriteLocationName = () => {
      // if we already have all the info
      if (typeof formValues.value.favoriteStore == "string" && locationList.value.length > 0) {
        favoriteLocationName.value =
          locationList.value.find((location) => +location.id == +(formValues.value.favoriteStore ?? 0))?.name ?? favoriteLocationName.value;
        // if we only have the store number
      } else if (props.account.favoriteStore) {
        fetchLocationDetail(props.account.favoriteStore).then((location) => (favoriteLocationName.value = location.name));
      }
    };

    const isZipReady = computed(() => formValues.value.zipCode?.length == 5);
    watch(
      isZipReady,
      () => {
        if (form.errors && isZipReady.value) {
          fetchClosestLocationsAddress(form.values.zipCode as string)
            .then((response) => {
              locationList.value = response.slice(0, LOCATION_SEARCH_LIMIT);
            })
            .catch();
        }
      },
      { immediate: true, deep: true }
    );

    // UPDATE WITH ACCOUNT
    const updateFields = (account) => {
      // @ts-ignore
      form.values = new UserProfile(account);
    };
    nextTick(() => {
      updateFields(props.account);
    });
    watch(
      () => props.account,
      (a) => updateFields(a)
    );

    // TOAST
    const toastMessage = ref("");
    const toast = ref();

    onMounted(async () => {
      toast.value = await toastController.create({
        message: toastMessage.value,
        duration: 2000,
        position: "middle",
      });
    });

    //REQUEST HANDLING
    const { responseMessage, responseModalVisible } = useApiResponse();
    const onSubmit = form.handleSubmit(() => {
      setFavoriteLocationName();
      updateAccountInfo(new UserProfile(formValues.value))
        .then(() => {
          toastMessage.value = "Profile updated successfully!";
          // eslint-disable-next-line vue/no-mutating-props
          props.account.birthdate = formValues.value.birthdate ?? "";
          store.dispatch("refreshAccountInfo");
        })
        .catch((e) => (toastMessage.value = e))
        .finally(() => {
          toastController
            .create({
              message: toastMessage.value,
              duration: 2000,
              position: "top",
              color: "success",
            })
            .then((toast) => {
              toast.present();
              showEditView.value = false;
            });
        });
    });

    // EMAIL VERIFICATION
    const verificationCode = ref("");
    const isVerificationLoading = ref(false);
    const verificationCodeClassList = ref("");

    const submitEmailVerification = (code) => {
      isVerificationLoading.value = true;
      verifyEmail(code)
        .then((response) => {
          if (response.toLowerCase() === "success") {
            store.dispatch("refreshAccountInfo");
          } else {
            verificationCodeClassList.value = "form-group--error";
          }
        })
        .catch(() => {
          verificationCodeClassList.value = "form-group--error";
        })
        .finally(() => {
          verificationCode.value = "";
          setTimeout(() => (verificationCodeClassList.value = ""), 500);
          isVerificationLoading.value = false;
        });
    };

    watch(verificationCode, (code) => {
      if (code.length == 6) {
        submitEmailVerification(code);
      }
    });

    return {
      passwordResetUrl,
      verificationCode,
      verificationCodeClassList,
      submitEmailVerification,
      isVerificationLoading,
      showEditView,
      meta: form.meta,
      formValues,
      errors: form.errors,
      onSubmit,
      locationList,
      responseMessage,
      responseModalVisible,
      toastMessage,
      vegan,
      vegetarian,
      glutenFree,
      favoriteLocationName,
      hasDietaryPreferences,
      dietaryPreferencesList,
      updateBirthdate,
      numberSuffix,
      isLoyaltyMember,
    };
  },
});
