
import { IonMenu, IonRow, IonToolbar, IonItem, IonHeader, IonImg, IonLabel, IonMenuToggle, IonIcon, IonContent, IonCol, IonButton } from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { close, chevronForward } from "ionicons/icons";
import accountModal from "@/components/modals/accountModal.vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    const selectedIndex = ref(0);
    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const account = computed(() => store.getters.getAccount);
    const firstName = computed(() => store.getters.getAccountFirstName || "Guest");
    const selectedLocation = computed(() => store.getters.getSelectedLocation);
    const isCrawler = computed(() => store.getters.isCrawler);
    const redirectToLocation = computed(() => !(selectedLocation.value || isCrawler.value));
    const logout = () => store.dispatch("setLogout");

    const appPages = computed(() => [
      { title: "Home", url: "/", show: true },
      { title: "Order", url: redirectToLocation.value ? "/location" : "/menu", show: true },
      { title: "Rewards & Deals", show: true, url: "/rewards" },
      { title: "Catering", url: "/catering", show: true },
    ]);

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.value.findIndex((page) => page.title.toLowerCase() === path.toLowerCase());
    }

    return {
      router,
      close,
      chevronForward,
      firstName,
      isLoggedIn,
      appPages,
      selectedLocation,
      logout,
      account,
    };
  },
  components: {
    IonMenu,
    IonRow,
    IonImg,
    IonLabel,
    IonToolbar,
    IonHeader,
    IonItem,
    IonMenuToggle,
    IonContent,
    IonIcon,
    IonCol,
    IonButton,
    accountModal,
  },
});
