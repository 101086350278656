export enum PaymentTypeEnum {
  credit = "Credit",
  giftCard = "Gift Card",
  apple = "Apple Pay",
  google = "Google Pay",
  cash = "Pay at Donatos",
}

export enum PaymentIdEnum {
  cash = "1",
  credit = "3",
  giftCard = "5",
}

export enum cardTypeEnum {
  visa = "VISA",
  masterCard = "MC",
  amex = "AMEX",
  discover = "DISC",
}
