import { StoreLocation } from "@/models/locationModels";
import { ActionContext } from "vuex";
import { fetchClosestLocationsCoords, fetchClosestLocationsAddress, fetchLocationDetail, closestLocation } from "@/services/locationService";
import { Address, Coordinate } from "@/models/addressModels";
import { LOCATION_SEARCH_LIMIT } from "@/constants";
import { LOCATION_SEARCH_RADIUS } from "@/constants";
import ValidateDeliveryAddress from "@/models/ValidateDeliveryAddressRes";

export interface locationSearchState {
  nearLocations: StoreLocation[];
  lastAddressSearch: string;
  lastCoordinateSearch?: Coordinate;
  fetchingNearLocations: boolean;
  highlightedLocationId: string;
  searchCount: number;
  searchError?: string;
  lastDeliveryAddressSearch?: Address;
  displayDeliveryStore?: StoreLocation;
}

const locationSearchState: locationSearchState = {
  nearLocations: [],
  lastAddressSearch: "",
  fetchingNearLocations: false,
  highlightedLocationId: "0",
  searchCount: 0,
};

export default {
  state: locationSearchState,
  mutations: {
    SET_NEAR_LOCATIONS: (state: locationSearchState, locations: StoreLocation[]): void => {
      state.nearLocations = locations;
    },
    SET_LAST_ADDRESS_SEARCH: (state: locationSearchState, address: string): void => {
      state.searchCount++;
      state.lastAddressSearch = address;
    },
    SET_LAST_COORDINATE_SEARCH: (state: locationSearchState, coords: GeolocationCoordinates): void => {
      state.searchCount++;
      state.lastCoordinateSearch = coords;
    },
    SET_FETCHING_NEAR_LOCATION: (state: locationSearchState, processing: boolean): void => {
      state.fetchingNearLocations = processing;
      if (processing) state.searchError = undefined;
    },
    SET_HIGHLIGHTED_NEARBY_LOCATION: (state: locationSearchState, locationId: string): void => {
      state.highlightedLocationId = locationId;
      state.nearLocations.forEach((l) => {
        l.highlighted = l.id == locationId;
      });
    },
    SET_SEARCH_ERROR: (state: locationSearchState, error: string): void => {
      if (error === "Not Found") {
        // Coordinate search
        error = `Search by address above or click on the map to find nearby Donatos`;
      } else if (typeof error === "string" && (error.toLowerCase().includes("create failed") || error.toLowerCase().includes("system error"))) {
        // address search
        error = `Sorry, we could not find any Donatos within ${LOCATION_SEARCH_RADIUS} miles of that location, please try another address.`;
      } else if (error.length > 1000) {
        // html response
        error = `Sorry, something went wrong. Please try again.`;
      }

      state.searchError = error;
    },

    UPDATE_DELIVERY_LOCATION: (state: locationSearchState, displayDeliveryStore: StoreLocation) =>
      (state.displayDeliveryStore = displayDeliveryStore),
    SET_LAST_DELIVERY_ADDRESS_SEARCH: (state: locationSearchState, address: Address) => (state.lastDeliveryAddressSearch = address),
  },
  actions: {
    setNearLocationsByAddress(context: ActionContext<locationSearchState, any>, { address }): Promise<StoreLocation[]> {
      return new Promise((resolve, reject) => {
        const addressStr = typeof address === "string" ? address : address.asString;
        // if (context.state.fetchingNearLocations || addressStr === context.state.lastAddressSearch) return;
        if (context.state.fetchingNearLocations) return;
        context.commit("SET_FETCHING_NEAR_LOCATION", true);
        fetchClosestLocationsAddress(address)
          .then((locations) => {
            context.commit("SET_LAST_ADDRESS_SEARCH", addressStr);
            context.commit("SET_NEAR_LOCATIONS", locations.slice(0, LOCATION_SEARCH_LIMIT));
            if (locations && locations[0] && locations[0].coordinate) {
              context.commit("SET_LAST_COORDINATE_SEARCH", locations[0].coordinate);
            }
            resolve(locations.slice(0, LOCATION_SEARCH_LIMIT));
          })
          .catch((err) => {
            context.commit("SET_SEARCH_ERROR", err);
            reject(err);
          })
          .finally(() => context.commit("SET_FETCHING_NEAR_LOCATION", false));
      });
    },
    setNearLocationsByCoordinates(context: ActionContext<locationSearchState, any>, coordinates: Coordinate): void {
      if (
        context.state.fetchingNearLocations ||
        (context.state.lastCoordinateSearch?.latitude == coordinates.latitude &&
          context.state.lastCoordinateSearch?.longitude == coordinates.longitude)
      ) {
        return;
      }
      context.commit("SET_FETCHING_NEAR_LOCATION", true);
      fetchClosestLocationsCoords(coordinates)
        .then((locations) => {
          context.commit("SET_LAST_COORDINATE_SEARCH", coordinates);
          context.commit("SET_NEAR_LOCATIONS", locations.slice(0, LOCATION_SEARCH_LIMIT));
        })
        .catch((err) => context.commit("SET_SEARCH_ERROR", err instanceof Array ? err[0] : err))
        .finally(() => context.commit("SET_FETCHING_NEAR_LOCATION", false));
    },
    mapHighlight(context: ActionContext<locationSearchState, any>, locationId: string): void {
      context.commit("SET_HIGHLIGHTED_NEARBY_LOCATION", locationId);
    },
    setLastDeliveryAddress(context: ActionContext<locationSearchState, any>, address: Address): void {
      context.commit("SET_LAST_DELIVERY_ADDRESS_SEARCH", new Address(address));
    },
    foundDeliveryLocation(context: ActionContext<locationSearchState, any>, customerAddress: ValidateDeliveryAddress): Promise<StoreLocation> {
      context.dispatch("setLastDeliveryAddress", customerAddress);
      return new Promise((resolve) => {
        if (customerAddress.storeNumber) {
          fetchLocationDetail(customerAddress.storeNumber).then((location) => {
            context.commit("UPDATE_DELIVERY_LOCATION", location);
            resolve(location);
          });
        } else {
          context.dispatch("setNearLocationsByAddress", customerAddress).then((locations) => {
            context.commit("UPDATE_DELIVERY_LOCATION", closestLocation(locations));
            resolve(closestLocation(locations));
          });
        }
      });
    },
  },
  getters: {
    processingNearLocations: (state: locationSearchState): boolean => state.fetchingNearLocations,
    getNearLocations: (state: locationSearchState): StoreLocation[] => state.nearLocations,
    getLimitedNearLocations: (state: locationSearchState): StoreLocation[] => state.nearLocations.filter((l, i) => +l.distance < 50 || i <= 5),
    locationIdInNearLocations: (state: locationSearchState) => (id: string) => state.nearLocations.find((l) => l.id == id),
    getLastLocationSearched: (state: locationSearchState): Coordinate | undefined => state.lastCoordinateSearch,
    getLastAddressSearch: (state: locationSearchState): string => state.lastAddressSearch,
    getHighlightedLocationId: (state: locationSearchState): string => state.highlightedLocationId,
    isFirstSearch: (state: locationSearchState): boolean => state.searchCount === 0,
    getSearchError: (state: locationSearchState): string | undefined => state.searchError,
    getSearchedDeliveryAddress: (state: locationSearchState): Address | undefined => state.lastDeliveryAddressSearch,
    getDisplayDeliveryStore: (state: locationSearchState): StoreLocation | undefined => state.displayDeliveryStore,
  },
};
