import { CustomerAddress } from "@/models/addressModels";
import { capitalize, nullOrEmpty } from "@/services/stringService";
import { FullOrder, OrderHeader } from "../cartModels";
import UserProfile from "./userProfile";
import { CreditCard } from "../PaymentModels";
import { stringToBool } from "@/services/stringService";
import { rewardSummary } from "@/services/cheetah/rewardService";
import { CheetahMemberSso, CheetahResponse, CheetahReward, CheetahSummary } from "./cheetahModels";
import CommunicationPreferences from "./communicationPreferences";
import { MONTHS } from "@/constants";

export class LoginReq {
  email: string;
  password: string;
  persistLogin: boolean;

  constructor() {
    this.email = "";
    this.password = "";
    this.persistLogin = false;
  }
}
export class Account extends UserProfile {
  address1: string;
  address2: string;
  addressType: string;
  businessName: string;
  cart: FullOrder;
  city: string;
  deliveryInstructions: string;
  emailList: any[];
  frontendTypeId: string;
  memberSince: string;
  nickname: string;
  state: string;
  zipCode: string;
  storeNumber: string;
  deliveryAddresses: CustomerAddress[];
  cheetahMemberSummary?: CheetahSummary;
  cheetahMemberRewards: CheetahReward[];
  cheetahMemberSso: CheetahMemberSso;
  cheetahMemberResponses: CheetahResponse[];
  cheetahMemberOffers: [];
  savedCards: CreditCard[];
  emailId: string;
  emailIsVerified: boolean;
  emailIsFavorite: boolean;
  extension: string;
  phoneType: string;
  phoneIsValidated: boolean;
  phoneIsFavorite: boolean;
  birthdate: string;
  rewardsSubscription: boolean;
  rewardsSource?: string | null;
  customerZip?: string;

  pendingEmail: string | undefined;
  favoriteEmailId: number | null;
  favoritePhoneId: number | null;
  favoriteAddressId?: string;
  receiveDonatosNewsAndEventEmails: boolean;
  ltdCommunicationEmail: boolean;
  lrmEmails: boolean;
  receivePersonalizedOffers: boolean;
  receiveSmsOffers: boolean;
  receiveMobileAppOffers: boolean;
  isMarketingEmail: boolean;

  phoneId?: number;

  // Needed to create order header during checkout
  tempFirstName?: string;
  tempLastName?: string;
  tempEmail?: string;
  tempPhone?: string;

  constructor(data?: Partial<Account>) {
    super(data);
    //@ts-ignore
    if (data) data.isFavorite = !!nullOrEmpty(data?.address1); // derived for CustomerAddress below but not from api
    //@ts-ignore
    this.deliveryAddresses = (data?.addressList ?? data?.deliveryAddresses?.DeliveryAddress ?? []).map((a) => new CustomerAddress(a));

    this.customerId = data?.customerId ?? "";
    this.emailId = data?.emailId ?? "";
    this.emailList = data?.emailList ?? [];
    this.address1 = data?.address1 ?? "";
    this.address2 = data?.address2 ?? "";
    this.addressType = data?.addressType ?? "1";
    this.city = data?.city ?? "";
    this.state = data?.state ?? "";
    //@ts-ignore
    this.zipCode = data?.zipCode ?? data?.zip ?? data?.customerZip ?? "";
    this.storeNumber = data?.storeNumber ?? "";
    this.memberSince = data?.memberSince ?? "";
    this.businessName = data?.businessName ?? "";
    this.nickname = data?.nickname ?? "";
    this.cart = data?.cart ? new FullOrder(data?.cart) : ({} as FullOrder);
    this.deliveryInstructions = data?.deliveryInstructions ?? "";
    this.frontendTypeId = data?.frontendTypeId ?? "";
    this.rewardsSource = data?.rewardsSource ?? "";
    this.phoneType = data?.phoneType ?? "";
    this.phoneId = data?.phoneId;
    this.cart = data?.cart ? new FullOrder(data?.cart) : ({} as FullOrder);
    this.deliveryInstructions = data?.deliveryInstructions ?? "";
    this.frontendTypeId = data?.frontendTypeId ?? "";
    this.savedCards = data?.savedCards ?? [];
    this.pendingEmail = data?.pendingEmail ?? undefined;
    this.emailId = data?.emailId ?? "";
    this.emailIsVerified = data?.emailIsVerified ?? false;
    this.emailIsFavorite = data?.emailIsFavorite ?? false;
    this.extension = data?.extension ?? "";
    this.phoneType = data?.phoneType ?? "";
    this.phoneIsValidated = data?.phoneIsValidated ?? false;
    this.phoneIsFavorite = data?.phoneIsFavorite ?? false;
    this.birthdate = data?.birthdate ?? "";
    this.rewardsSubscription = stringToBool(data?.rewardsSubscription);
    this.rewardsSource = data?.rewardsSource ?? null;

    this.favoriteEmailId = data?.favoriteEmailId ?? null;
    this.favoritePhoneId = data?.favoritePhoneId ?? null;
    this.favoriteAddressId = data?.favoriteAddressId;
    this.receiveDonatosNewsAndEventEmails = stringToBool(data?.receiveDonatosNewsAndEventEmails) ?? false;
    this.ltdCommunicationEmail = stringToBool(data?.ltdCommunicationEmail) ?? false;
    this.lrmEmails = stringToBool(data?.lrmEmails) ?? false;
    this.receivePersonalizedOffers = stringToBool(data?.receivePersonalizedOffers) ?? false;
    this.receiveSmsOffers = stringToBool(data?.receiveSmsOffers) ?? false;
    this.receiveMobileAppOffers = stringToBool(data?.receiveMobileAppOffers) ?? false;
    this.isMarketingEmail = stringToBool(data?.isMarketingEmail);
    this.savedCards = data?.savedCards ?? [];
    this.customerZip = data?.customerZip;
    if (this.favoriteAddressId) {
      const findFavorite = this.deliveryAddresses.find((a) => a.addressId == this.favoriteAddressId);
      if (findFavorite) findFavorite.isFavorite = true;
    }

    //@ts-ignore
    this.cheetahMemberSummary = data?.cheetahMemberSummary?.data ? new CheetahSummary(data?.cheetahMemberSummary?.data) : undefined;
    //@ts-ignore
    this.cheetahMemberRewards = data?.cheetahMemberRewards?.data?.rewards?.map((r) => new CheetahReward(r));
    //@ts-ignore
    this.cheetahMemberOffers = data?.cheetahMemberOffers?.data?.rewards?.map((r) => new CheetahReward(r));
    //@ts-ignore
    this.cheetahMemberSso = new CheetahMemberSso(data?.cheetahMemberSso?.data ?? data?.cheetahMemberSso);
    //@ts-ignore
    this.cheetahMemberResponses = data?.cheetahMemberResponses?.map((r) => new CheetahResponse(r));
  }
  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  //#region cheetah rewards
  get currentBalance() {
    return Math.max(this.cheetahMemberSummary?.getPoints?.balance ?? 0, 0);
  }

  get rewardsCount() {
    return this.cheetahMemberSummary?.getEnabledRewards;
  }

  get rewardSummary() {
    return rewardSummary(this);
  }

  get isAccountPending(): boolean {
    return this.rewardsMemberId == "Pending";
  }

  get isRewardsDeactivated(): boolean {
    return this.cheetahMemberSso?.code === 4024;
  }

  get isRewardsSsoRequiredParam(): boolean {
    return this.cheetahMemberSso?.code === 4705;
  }

  get isRewardsInvalidRecord(): boolean {
    return this.cheetahMemberSso?.code === 4400;
  }

  get hasRewardsActivated(): boolean {
    return !this.isAccountPending && !this.isRewardsDeactivated && !this.isRewardsSsoRequiredParam && !this.isRewardsInvalidRecord;
  }

  get isErrorMessage(): any {
    return this.cheetahMemberSso?.message;
  }

  //#endregion

  get asOrderHeader() {
    return new OrderHeader({
      customerId: this.customerId,
      firstName: nullOrEmpty(this.firstName) ?? this.tempFirstName,
      lastName: nullOrEmpty(this.lastName) ?? this.tempLastName,
      email: nullOrEmpty(this.tempEmail) ?? this.emailAddress,
      phoneNumber: nullOrEmpty(this.tempPhone) ?? this.phoneNumber ?? "",
      orderFor: nullOrEmpty(`${this.tempFirstName ?? ""} ${this.tempLastName ?? ""}`) ?? `${this.firstName} ${this.lastName}`,
      orderForName: nullOrEmpty(`${this.tempFirstName ?? ""} ${this.tempLastName ?? ""}`) ?? `${this.firstName} ${this.lastName}`,
      loyaltyMemberId: this.rewardsMemberId,
      isMarketingEmail: +this.isMarketingEmail,
    });
  }

  get hasRewardsAvailable() {
    return this.cheetahMemberResponses && this.cheetahMemberResponses.length > 0;
  }

  get isCheetahMemberRewardsAvailable() {
    return this.cheetahMemberRewards && this.cheetahMemberRewards.length > 0;
  }

  get userProfile() {
    return new UserProfile(this);
  }
  get commPrefs() {
    return new CommunicationPreferences(this);
  }

  get hasValidBirthdate() {
    return !isNaN(new Date(this.birthdate.replace(/-/g, "/")).getDate());
  }
  get birthDay() {
    return this.hasValidBirthdate ? new Date(this.birthdate.replace(/-/g, "/")).getDate() : "";
  }
  get birthMonth() {
    const monthIndex = new Date(this.birthdate.replace(/-/g, "/")).getMonth();
    return this.hasValidBirthdate ? capitalize(MONTHS[monthIndex]) : "";
  }
}

export class AccountLoyaltyInfo {
  cheetahMemberSummary?: CheetahSummary;
  cheetahMemberRewards: CheetahReward[];
  cheetahMemberSso: CheetahMemberSso;
  cheetahMemberResponses: CheetahResponse[];
  cheetahMemberOffers: [];

  constructor(data) {
    this.cheetahMemberSummary = data?.cheetahMemberSummary?.data ? new CheetahSummary(data?.cheetahMemberSummary?.data) : undefined;
    //this.cheetahMemberRewards = data?.cheetahMemberRewards?.data?.rewards?.map((r) => new CheetahReward(r));
    this.cheetahMemberRewards = data?.cheetahMemberRewards?.map((r) => new CheetahReward(r));
    this.cheetahMemberOffers = data?.cheetahMemberOffers?.data?.rewards?.map((r) => new CheetahReward(r));
    this.cheetahMemberSso = new CheetahMemberSso(data?.cheetahMemberSso?.data ?? data?.cheetahMemberSso);
    this.cheetahMemberResponses = data?.cheetahMemberResponses?.map((r) => new CheetahResponse(r));
  }
}
