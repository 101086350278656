import { stringToBool } from "./../services/stringService";
import { LocationHours, LocationStatus, StoreLocation } from "@/models/locationModels";
import Phone from "@/models/Phone";
import { Address, Coordinate } from "@/models/addressModels";

export class NearLocationResponse {
  store: nearStore;
  distance: string;

  constructor(res: Partial<NearLocationResponse>) {
    this.store = new nearStore(res.store);
    this.distance = res.distance ?? "0";
  }
  get asStoreLocation(): StoreLocation {
    return new StoreLocation({
      id: this.store.id,
      name: this.store.name,
      slug: this.store.name.toLowerCase().replace(" ", "-"),
      address: this.store.address,
      coordinate: this.store.coordinate,
      deliveryCharge: +this.store.deliveryCharge,
      distance: +this.distance,
      phone: this.store?.phone.asString,
      pos: this.store?.pos,
      timeZone: +this.store?.timezone,
      maxTimeOrderDays: +this.store?.maxTimeOrderDays,
      openForWeb: stringToBool(this.store?.openForWeb),
      taxRate: +this.store?.taxRate,

      paymentTypes:
        typeof this.store.supportedPaymentTypes !== "string" ? this.store?.supportedPaymentTypes.PaymentType.map((p) => p.description) : [],
      hours: this.store?.hours.StoreSchedule.map((s) => {
        return new LocationHours({
          date: s.day,
          isOpen: stringToBool(s.isOpen),
          open: s.openTime,
          close: s.closeTime,
          firstWebOrder: s.firstWebOrderTime,
          lastWebOrder: s.lastWebOrderTime,
        });
      }),
      orderType: this.store?.orderTypes?.StoreOrderType && Array.isArray(this.store?.orderTypes?.StoreOrderType) ? this.store?.orderTypes?.StoreOrderType.map((o) => o.description) :[],
      creditCards: typeof this.store.supportedCreditCards !== "string" ? this.store?.supportedCreditCards.CreditCard.map((c) => c.description) : [],
      storeStatus: new LocationStatus({
        offline: stringToBool(this.store?.offline),
        isDelTurnedOff: stringToBool(this.store?.isDelTurnedOff),
        deliverydelay: +this.store?.deliverydelay,
        nondeliverydelay: +this.store?.nondeliverydelay,
        preopen: stringToBool(this.store?.preopen),
        isActive: stringToBool(this.store?.isActive),
        isRedRobinStore: stringToBool(this.store?.isRedRobinStore),
        isContactlessDelivery: stringToBool(this.store?.isContactlessDelivery),
        useNewPipes: stringToBool(this.store?.useNewPipes),
        nonDeliveryGiftCard: stringToBool(this.store?.nonDeliveryGiftCard),
      }),
      // webSpecial: this.store?.webSpecial,
      // hasLoyalty = this.store?.hasLoyalty ?? true;
      // yelp = this.store?.yelp ?? "//www.yelp.com/biz/donatos-pizza-columbus-13",
      // image: this.store?.image,
    });
  }
}

export class nearStore {
  id: string;
  name: string;
  address: Address;
  phone: Phone;
  coordinate: Coordinate;
  taxRate: string;
  deliveryCharge: string;
  isActive: string;
  hours: {
    StoreSchedule: StoreSchedule[];
  };
  dstIndicator: string;
  timezone: string;
  orderTypes: {
    StoreOrderType: IdDescription[];
  };
  openForWeb: string;
  pos: string;
  maxTimeOrderDays: string;
  supportedCreditCards: {
    CreditCard: IdDescription[];
  };
  supportedPaymentTypes: {
    PaymentType: IdDescription[];
  };
  offline: string;
  deliverydelay: string;
  nondeliverydelay: string;
  preopen: string;
  isRedRobinStore: string;
  isDelTurnedOff: string;
  isContactlessDelivery: string;
  useNewPipes: string;
  nonDeliveryGiftCard: string;
  isElavonApi: string;

  constructor(res?: Partial<nearStore>) {
    this.id = res?.id ?? "";
    this.name = res?.name ?? "";
    this.address = new Address(res?.address);
    this.phone = new Phone(res?.phone);
    this.coordinate = new Coordinate(res?.coordinate);
    this.taxRate = res?.taxRate ?? "";
    this.deliveryCharge = res?.deliveryCharge ?? "";
    this.isActive = res?.isActive ?? "";
    this.hours = {
      StoreSchedule: res?.hours?.StoreSchedule ?? [],
    };
    this.dstIndicator = res?.dstIndicator ?? "";
    this.timezone = res?.dstIndicator ?? "";
    this.orderTypes = {
      StoreOrderType: res?.orderTypes?.StoreOrderType ?? [],
    };
    this.openForWeb = res?.openForWeb ?? "";
    this.pos = res?.pos ?? "";
    this.maxTimeOrderDays = res?.maxTimeOrderDays ?? "";
    this.supportedCreditCards = {
      CreditCard: res?.supportedCreditCards?.CreditCard ?? [],
    };
    this.supportedPaymentTypes = {
      PaymentType: res?.supportedPaymentTypes?.PaymentType ?? [],
    };
    this.offline = res?.offline ?? "";
    this.deliverydelay = res?.deliverydelay ?? "";
    this.nondeliverydelay = res?.nondeliverydelay ?? "";
    this.preopen = res?.preopen ?? "";
    this.isRedRobinStore = res?.isRedRobinStore ?? "";
    this.isDelTurnedOff = res?.isDelTurnedOff ?? "";
    this.isContactlessDelivery = res?.isContactlessDelivery ?? "";
    this.useNewPipes = res?.useNewPipes ?? "";
    this.nonDeliveryGiftCard = res?.nonDeliveryGiftCard ?? "";
    this.isElavonApi = res?.isElavonApi ?? "";
  }

  // Search results from /getstoresnear is different when searching by name
  // This is for mapping that result

  // Don't think there is a defined type for the data structure coming from
  // /getstoresnear for name searches...
  // TODO: Figure out datatype hint for this
  set fromNameSearchResult(res) {
    this.id = res?.id ?? "";
    this.name = res?.name ?? "";
    this.address = new Address({
      address1: res?.address1 ?? "",
      address2: res?.address2 ?? "",
      city: res?.city ?? "",
      state: res?.state ?? "",
      zip: res?.zip ?? "",
    });

    // TODO: Split into areaCode, phoneNumber, isMobilePhone (false)
    // Now just dumping res.phone into phoneNumber field of new Phone object
    this.phone = new Phone({ phoneNumber: res?.phone });
    this.coordinate = new Coordinate({ latitude: res?.latitude, longitude: res?.longitude });
    this.taxRate = res?.taxRate ?? "";
    this.deliveryCharge = res?.deliveryCharge ?? "";
    this.isActive = res?.storeStatus?.isActive ?? "";
    this.hours = {
      StoreSchedule: res?.hours
        ? res?.hours?.map(
            (hrs) =>
              new StoreSchedule({
                openTime: hrs?.open ?? "",
                closeTime: hrs?.close ?? "",
                firstWebOrderTime: hrs?.firstWebOrder ?? "",
                lastWebOrderTime: hrs?.lastWebOrder ?? "",
                day: hrs?.day ?? "",
                isOpen: hrs?.isOpen ?? "",
              })
          ) ?? []
        : [],
    };

    this.dstIndicator = res?.dstIndicator ?? "";
    this.timezone = res?.timeZone ?? "";
    this.orderTypes = {
      StoreOrderType: res?.orderType
        ? res?.orderType.map(
            (oT, idx) =>
              new IdDescription({
                id: idx + 1,
                description: oT,
              }) ?? []
          )
        : [],
    };
    this.openForWeb = res?.openForWeb ?? "";
    this.pos = res?.pos ?? "";
    this.maxTimeOrderDays = res?.maxTimeOrderDays ?? "";
    this.supportedCreditCards = {
      CreditCard: res?.creditCards
        ? res?.creditCards.map(
            (oT, idx) =>
              new IdDescription({
                id: idx + 1,
                description: oT,
              }) ?? []
          )
        : [],
    };
    this.supportedPaymentTypes = {
      PaymentType: res?.paymentType
        ? res?.paymentType.map(
            (oT, idx) =>
              new IdDescription({
                id: idx + 1,
                description: oT,
              }) ?? []
          )
        : [],
    };
    this.offline = res?.storeStatus?.offline ?? "";
    this.deliverydelay = res?.storeStatus?.deliverydelay ?? "";
    this.nondeliverydelay = res?.storeStatus?.nondeliverydelay ?? "";
    this.preopen = res?.storeStatus?.preopen ?? "";
    this.isRedRobinStore = res?.storeStatus?.isRedRobinStore ?? "";
    this.isDelTurnedOff = res?.storeStatus?.isDelTurnedOff ?? "";
    this.isContactlessDelivery = res?.storeStatus?.isContactlessDelivery ?? "";
    this.useNewPipes = res?.storeStatus?.useNewPipes ?? "";
    this.nonDeliveryGiftCard = res?.storeStatus?.nonDeliveryGiftCard ?? "";
    this.isElavonApi = res?.storeStatus?.isElavonApi ?? "";
  }
  get fromNameSearchResult() {
    return this.fromNameSearchResult;
  }
}

class StoreSchedule {
  openTime: string;
  closeTime: string;
  firstWebOrderTime: string;
  lastWebOrderTime: string;
  day: string;
  isOpen: string;
  constructor(x?: Partial<StoreSchedule>) {
    this.openTime = x?.openTime ?? "";
    this.closeTime = x?.closeTime ?? "";
    this.firstWebOrderTime = x?.firstWebOrderTime ?? "";
    this.lastWebOrderTime = x?.lastWebOrderTime ?? "";
    this.day = x?.day ?? "";
    this.isOpen = x?.isOpen ?? "";
  }
}
class IdDescription {
  id: string;
  description: string;
  constructor(x?: Partial<IdDescription>) {
    this.id = x?.id ?? "";
    this.description = x?.description ?? "";
  }
}
