import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43034d4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { size: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "left-label" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Deals and New Product Emails ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_toggle, {
                    mode: "ios",
                    color: "success",
                    modelValue: _ctx.dealsAndProductEmails,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dealsAndProductEmails) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_radio_group, {
                value: _ctx.selectedDeal,
                mode: "md",
                onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateSelectedDeal($event.detail.value)))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deals, (deal, i) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: deal,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_radio, {
                          slot: "start",
                          value: i,
                          disabled: !_ctx.dealsAndProductEmails
                        }, null, 8, ["value", "disabled"]),
                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(deal), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "left-label" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Reward Emails")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_toggle, {
                    mode: "ios",
                    color: "success",
                    modelValue: _ctx.commPrefs.receivePersonalizedOffers,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.commPrefs.receivePersonalizedOffers) = $event)),
                    onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updatePrefs('receivePersonalizedOffers', _ctx.commPrefs.receivePersonalizedOffers)))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "left-label" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Catering Promotions")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_toggle, {
                    mode: "ios",
                    color: "success",
                    modelValue: _ctx.commPrefs.lrmEmails,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.commPrefs.lrmEmails) = $event)),
                    onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updatePrefs('lrmEmails', _ctx.commPrefs.lrmEmails)))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "left-label" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Text Messages "),
                      (_ctx.account?.phoneNumber)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode("to " + _toDisplayString(_ctx.account?.phoneNumber), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_toggle, {
                    mode: "ios",
                    color: "success",
                    modelValue: _ctx.commPrefs.receiveSmsOffers,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.commPrefs.receiveSmsOffers) = $event)),
                    onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updatePrefs('receiveSmsOffers', _ctx.commPrefs.receiveSmsOffers)))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}