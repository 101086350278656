
import { defineComponent, computed } from "vue";
import { IonHeader } from "@ionic/vue";
import { useStore } from "vuex";
import MobileHeader from "@/components/layout/webHeaderMobile.vue";
import DesktopHeader from "@/components/layout/webHeaderDesktop.vue";
import NativeAppHeader from "./nativeAppHeader.vue";

export default defineComponent({
  components: { NativeAppHeader, IonHeader, MobileHeader, DesktopHeader },
  props: {
    useLogo: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore();
    const isNative = computed(() => store.getters.isNative);
    const selectedLocation = computed(() => store.getters.getSelectedLocation);
    const isCrawler = computed(() => store.getters.isCrawler);
    const redirectToLocation = computed(() => !(selectedLocation.value || isCrawler.value));

    return { isNative, redirectToLocation };
  },
});
