import { LOYALTY_POINTS } from "@/constants";
import { PizzaSideEnum } from "@/enums/PizzaSideEnum";
import { createSlug, scrub, sizeValue, stringToBool, nullOrEmpty, nullOrFirst } from "@/services/stringService";
import { CartRecipe, CartSize } from "./cartModels";
export class Menu {
  readonly location: string;
  readonly category: MenuCategory[];
  readonly extraToppings: ToppingPrice[];
  constructor(menu: Menu) {
    this.location = menu.location;
    this.category = menu.category?.map((c) => new MenuCategory(c));
    this.extraToppings = menu.extraToppings?.map((p) => new ToppingPrice(p));
  }
}

/**
  @class MenuCategory
  @property {string} name
  @property {number} catNum
  @property {MenuItem[]} items: menu items that belong to this category
  @property {Instruction[]} instructions
  @property {RequiredTopping[]} required: (ex. Sauce cups for hand tossed pizzas)
  @getter {boolean} isAddOn: catNum 2 through 6 are `addOns` AKA `recommendations`
 */
export class MenuCategory {
  readonly name: string;
  readonly catNum: number;
  readonly items: MenuItem[];
  readonly instructions: Instruction[];
  readonly required: RequiredTopping[];
  constructor(category?: Partial<MenuCategory>) {
    this.name = category?.name ?? "";
    this.catNum = category?.catNum ?? 0;
    this.items = category?.items?.map((i) => new MenuItem(i)).sort((a, b) => a.itemSort - b.itemSort) ?? [];
    this.instructions = category?.instructions?.map((i) => new Instruction(i)) ?? [];
    this.required = category?.required?.map((i) => new RequiredTopping(i)) ?? [];
  }

  get isAddOn() {
    return this.catNum > 1 && this.catNum < 7;
  }
}

/**
  @class ToppingPrice
  @property {string} itemPid: applies to all if `-1`
  @property {string} recipePid
  @property {string} sizePid: the externalId on Size class
  @property {string[]} toppingPid
  @property {number} toppingprice
  @property {string} webcatPid
 */
export class ToppingPrice {
  readonly itemPid: string;
  readonly recipePid: string;
  readonly sizePid: string;
  readonly toppingPid: string[];
  readonly toppingprice: number;
  readonly webcatPid: string;

  constructor(price: ToppingPrice) {
    this.itemPid = price.itemPid;
    this.recipePid = price.recipePid;
    this.sizePid = price.sizePid;
    this.toppingPid = price.toppingPid;
    this.toppingprice = +price.toppingprice;
    this.webcatPid = price.webcatPid;
  }
}

export class MenuItem {
  readonly itemId: string;
  readonly itemName: string;
  readonly itemDisplayName: string;
  readonly itemDescription: string;
  readonly itemDisplayDescription: string;
  readonly itemExtraDescription: string;
  readonly itemExternalId: string;
  readonly itemPrice: number;
  readonly itemImage: string;
  readonly itemMobileImage?: string;
  readonly itemBanner: string;
  readonly itemIsConfigurable: boolean;
  readonly itemFeatured: boolean;
  readonly itemNew: boolean;
  readonly itemLegalDescription?: string;
  readonly itemLegalMoreInfo?: string;
  readonly itemSort: number;
  readonly itemIsAddon: boolean;
  readonly itemIsLTO: boolean;
  readonly itemTextBanner?: string;
  readonly itemTagname: string;
  readonly itemAdditionalNutritionDescription: string;
  readonly categoryName: string;
  readonly categoryId: string;
  readonly recipes: Recipe[];
  readonly webcatPid: string;

  constructor(item?: Partial<MenuItem>) {
    this.itemId = item?.itemId ?? "";
    this.itemName = item?.itemName ?? "";
    this.itemDisplayName = item?.itemDisplayName ?? "";
    this.itemDescription = item?.itemDescription ?? "";
    this.itemDisplayDescription = item?.itemDisplayDescription ?? "";
    this.itemExtraDescription = item?.itemExtraDescription ?? "";
    this.itemExternalId = item?.itemExternalId ?? "";
    this.itemPrice = +(item?.itemPrice ?? 0);
    this.itemImage = item?.itemImage ?? "";
    this.itemMobileImage = item?.itemMobileImage ?? "";
    this.itemBanner = item?.itemBanner ?? "";
    this.itemIsConfigurable = stringToBool(item?.itemIsConfigurable) ?? false;
    this.itemFeatured = stringToBool(item?.itemFeatured) ?? false;
    this.itemNew = stringToBool(item?.itemNew) ?? false;
    this.itemLegalDescription = item?.itemLegalDescription ?? "";
    this.itemLegalMoreInfo = item?.itemLegalMoreInfo ?? "";
    this.itemSort = +(item?.itemSort ?? 0);
    this.itemIsAddon = stringToBool(item?.itemIsAddon) ?? false;
    this.itemIsLTO = stringToBool(item?.itemIsLTO) ?? false;
    this.itemTextBanner = item?.itemTextBanner ?? "";
    this.itemTagname = item?.itemTagname ?? "";
    this.itemAdditionalNutritionDescription = item?.itemAdditionalNutritionDescription ?? "";
    this.categoryName = item?.categoryName ?? "";
    this.categoryId = item?.categoryId ?? "";
    this.recipes = item?.recipes?.map((r) => new Recipe(r)) ?? [];
    this.webcatPid = item?.webcatPid ?? "";

    if (this.itemPrice <= 0) this.itemPrice = this.firstSize.price;
  }
  get deepCopyItem() {
    return new MenuItem(JSON.parse(JSON.stringify(this)));
  }
  get name() {
    return scrub(this.itemDisplayName.replace(/\([\w\s]*\)/, ""));
  }
  get description() {
    return scrub(this.itemDisplayDescription);
  }
  get nameAndDescription() {
    return `<div>${this.name}</div><p class="small" style="line-height:1.2 !important;" data-cy="menu-item-subdescription">${this.description}</p>`;
  }
  get path() {
    return createSlug(this.itemDisplayName);
  }
  get fullPath() {
    return `/menu/${this.category}/${this.path}`;
  }
  get cdnImg() {
    return this.itemImage != "" ? process.env.VUE_APP_IMAGE_URL + "/" + this.itemImage : require("@/assets/desktopFallback.jpeg");
  }
  get mobileImg() {
    return this.itemMobileImage != "" ? process.env.VUE_APP_IMAGE_URL + "/" + this.itemMobileImage : require("@/assets/mobileFallback.jpeg");
  }
  get banner() {
    return this.itemBanner != "" ? process.env.VUE_APP_IMAGE_URL + "/" + this.itemBanner : "";
  }
  get isClassic() {
    return this.itemTagname.toLowerCase().includes("classic");
  }
  get isCustomPizza() {
    return this.name.toLowerCase().includes("create");
  }
  get category() {
    return this.categoryName.toLowerCase();
  }
  get isGiftCard() {
    return this.itemName.toLowerCase().includes("gift card");
  }
  get isPizza() {
    return this.category.includes("pizza");
  }
  get isSalad() {
    return this.category.includes("salad") || this.name.toLowerCase().includes("salad");
  }
  get isWing() {
    return this.category.includes("wing") || this.name.toLowerCase().includes("wing");
  }
  get isSub() {
    return this.category.includes("sub") || this.name.toLowerCase().includes("sub");
  }
  get isCalzato() {
    return this.name.toLowerCase().includes("calzato");
  }
  get isBakery() {
    return this.name.toLowerCase().includes("bakery");
  }
  get isDessert() {
    return this.category.includes("dessert") || this.name.toLowerCase().includes("dessert");
  }
  get isAppetizer() {
    return this.category.includes("appetizer") || this.name.toLowerCase().includes("appetizer");
  }
  get isBeverage() {
    return this.category.includes("beverage");
  }
  get isMisc() {
    return this.category.includes("misc");
  }
  get isAddOn() {
    return !this.isPizza && !this.isSalad && !this.isWing && !this.isSub && !this.isCalzato;
  }
  get isOneSize() {
    return (this?.firstRecipe?.sizes.length ?? 1) <= 1;
  }
  get isSideSalad() {
    return this.name?.toLowerCase().includes("side");
  }
  get isPartySize() {
    return this.itemDisplayName?.toLowerCase().includes("party size") || this.category?.includes("party size");
  }
  get inTopMenu() {
    return this.isPizza ? this.isClassic : this.isSalad ? this.isSideSalad : false;
  }
  get messageText() {
    let text = this.name;
    if (this.itemNew) text += `<span class="small color-primary new-item"> NEW!</span>`;
    if (this.isPartySize) text += `<br/><span class="small color-medium"> Party Size Only</span>`;
    return `<span>${text}</span>`;
  }
  get pointValue() {
    // if pilot program calculation is needed: donatos-com > loyaltytraits.php > itemRewardsPointsValue()
    return Math.floor(this.itemPrice) * LOYALTY_POINTS;
  }
  get firstRecipe(): Recipe {
    return nullOrFirst(this.recipes);
  }
  get firstSize(): Size {
    return nullOrFirst(this.firstRecipe?.sizes);
  }
}
export class Instruction {
  readonly instructionId: string;
  readonly instructionName: string;
  readonly name: string;
  selected: boolean;

  constructor(instruction: Instruction) {
    this.instructionId = instruction?.instructionId;
    this.instructionName = instruction?.instructionName;
    this.name = instruction?.instructionName;
    this.selected = instruction.selected ?? false;
  }

  get isNoCheese() {
    return this.name.toLowerCase().includes("no cheese");
  }
}
export class Recipe {
  readonly recipeId: string;
  readonly itemName: string;
  readonly externalId: string; // best to use to match recipe to recipe
  readonly recipeName: string;
  readonly caption: string;
  readonly legalDescription: string;
  readonly legalMoreInfo: string;
  readonly sizes: Size[];
  readonly recipePid: string;

  preselected?: boolean; // only for edit (does not come from API)
  constructor(recipe: Recipe) {
    this.recipeId = recipe.recipeId;
    this.itemName = recipe.itemName;
    this.externalId = recipe.externalId;
    this.recipeName = recipe.recipeName?.replace(/[\d"]*/, "").toLowerCase();
    this.legalDescription = recipe.legalDescription;
    this.legalMoreInfo = recipe.legalMoreInfo;
    this.caption = recipe.caption;
    this.sizes = recipe.sizes.map((s) => new Size(s));
    this.recipePid = recipe.recipePid ?? "";
    this.preselected = recipe.preselected ?? false;

    if (this.recipeName.trim() == "vegan crust" || this.recipeName.trim() == "cauliflower crust") {
      this.recipeName = `${this.recipeName} <span class="normal"> (gluten free)</span>`;
    }
  }

  get sizeDescending() {
    return this.sizes.sort((a, b) => sizeValue(b.name) - sizeValue(a.name));
  }
  get isCyo() {
    return this.itemName.toLowerCase().includes("create");
  }
  get isHandTossed() {
    return this.recipeName?.toLowerCase().includes("hand ");
  }
  get isStuffedCrust() {
    return this.recipeName?.toLowerCase().includes("stuffed ");
  }
  get isBakery() {
    return this.itemName.toLowerCase().includes("bakery");
  }

  recipeLineItem(selectedSize: Size[]) {
    if (!selectedSize) return [];
    const selectedSizes =
      selectedSize instanceof Array
        ? selectedSize.map((s) => new CartSize({ id: s.externalId, name: s.sizeName, price: s.price }))
        : //@ts-ignore
          [new CartSize({ id: selectedSize.externalId, name: selectedSize.sizeName, price: selectedSize.price })];
    return [new CartRecipe({ id: this.externalId, base: this.recipeName, size: selectedSizes })];
  }
}
export class Size {
  readonly itemName: string;
  readonly recipeName: string;
  readonly sizeName: string;
  readonly externalId: string; // best to use to match size to size
  readonly sizeId: string;
  readonly price: number;
  readonly calories: string;

  preselected?: boolean; // only for edit (does not come from API)

  constructor(size: Size) {
    this.itemName = size.itemName;
    this.recipeName = size.recipeName;
    this.sizeName = size.sizeName;
    this.externalId = size.externalId;
    this.sizeId = size.sizeId;
    this.price = +size.price;
    this.calories = size.calories?.replace(/\*/g, "");
    this.preselected = size.preselected ?? false;
  }

  get name() {
    return this.sizeName;
  }
  get number() {
    const number = this.sizeName.match(/[0-9]*/g)?.find((n) => !!n);
    return number ? +number.trim() : "";
  }
  get slices() {
    return this.number == 14 ? 17 : this.number == 12 || this.number == 10 ? 14 : 8;
  }
  get pieces() {
    return `${this.number} pieces`;
  }
  get isPartySize() {
    return this.sizeName.toLowerCase().includes("party");
  }
  get partyName() {
    return this.isPartySize ? "Party (serves 10)" : this.sizeName;
  }
  get calorieValue() {
    return +(this.calories?.match(/[0-9]*/g)?.filter((n) => !!n)[0] ?? 0);
  }
  get calorieUnit() {
    return this.calories?.match(/[a-zA-Z/]*/g)?.filter((n) => !!n)[0];
  }
}
export class AvailableTopping {
  readonly flavors: Topping[];
  readonly dressings: Topping[];
  readonly sauces: Topping[];
  readonly toppings: Topping[];
  readonly updatingSelectedToppings?: Topping[];

  readonly flavors2?: Topping[];
  readonly dressings2?: Topping[];
  readonly sauces2?: Topping[];
  readonly toppings2?: Topping[];
  readonly updatingSelectedToppings2?: Topping[];

  constructor(at: AvailableTopping) {
    this.flavors = at.flavors?.map((f) => new Topping(f)).sort((a, b) => a.sort - b.sort) ?? [];
    this.dressings = at.dressings?.map((d) => new Topping(d)).sort((a, b) => a.sort - b.sort) ?? [];
    this.sauces = at.sauces?.map((s) => new Topping(s)).sort((a, b) => a.sort - b.sort) ?? [];
    this.toppings = at.toppings?.map((t) => new Topping(t)).sort((a, b) => a.sort - b.sort) ?? [];
    this.updatingSelectedToppings = at.updatingSelectedToppings?.map((t) => new Topping(t)).sort((a, b) => a.sort - b.sort);

    this.flavors2 = at.flavors2?.map((f) => new Topping(f)).sort((a, b) => a.sort - b.sort);
    this.dressings2 = at.dressings2?.map((d) => new Topping(d)).sort((a, b) => a.sort - b.sort);
    this.sauces2 = at.sauces2?.map((s) => new Topping(s)).sort((a, b) => a.sort - b.sort);
    this.toppings2 = at.toppings2?.map((t) => new Topping(t)).sort((a, b) => a.sort - b.sort);
    this.updatingSelectedToppings2 = at.updatingSelectedToppings2?.map((t) => new Topping(t)).sort((a, b) => a.sort - b.sort);
  }
  /** Immutable copy of `toppings` to limit unintended updates up the chain */
  get deepCopyToppings() {
    return JSON.parse(JSON.stringify(this.toppings))
      ?.map((t) => new Topping(t))
      .sort((a, b) => a.sort - b.sort);
  }

  /** Immutable copy of `dressings` to limit unintended updates up the chain */
  get deepCopyDressings() {
    return JSON.parse(JSON.stringify(this.dressings))
      ?.map((t) => new Topping(t))
      .sort((a, b) => a.sort - b.sort);
  }

  /** Immutable copy of `toppings -> isDefault` to limit unintended updates up the chain */
  get deepDefaultCopyToppings() {
    return JSON.parse(JSON.stringify(this.toppings))
      ?.filter((t) => t.isDefault)
      ?.map((t) => new Topping(t))
      .sort((a, b) => a.sort - b.sort);
  }
  /** Immutable copy of `toppings -> !selected` and `updatingSelectedToppings` (filters duplicates) to limit unintended updates up the chain */
  get deepUnselectedCopyToppings() {
    return JSON.parse(JSON.stringify(this.toppings))
      ?.filter((t) => !t.selected && !this.updatingSelectedToppings?.some((select) => t.toppingPid === select.toppingPid))
      .concat(this.updatingSelectedToppings?.filter((s) => !s.isDefault) ?? [])
      ?.map((t) => new Topping(t))
      .sort((a, b) => a.sort - b.sort);
  }
  /** Immutable copy of `toppings2 -> !selected` and `updatingSelectedToppings2` to limit unintended updates up the chain */
  get deepUnselectedCopyToppings2() {
    return JSON.parse(JSON.stringify(this.toppings2 ?? this.toppings))
      ?.filter((t) => !t.selected && !this.updatingSelectedToppings2?.some((select) => t.toppingPid === select.toppingPid))
      .concat(this.updatingSelectedToppings2?.filter((s) => !s.isDefault) ?? [])
      ?.map((t) => new Topping(t))
      .sort((a, b) => a.sort - b.sort);
  }

  get meatToppings() {
    return this.toppings.filter((t) => t.isMeat);
  }
  get veggieToppings() {
    return this.toppings.filter((t) => !t.isMeat);
  }
}
export class Topping {
  id: string; // ex: "cat=1,recipe=40,base=1,webcat=1,topping=1"
  readonly name: string;
  readonly canSubstitute: boolean;
  readonly isMeat: boolean;
  readonly min: number;
  readonly max: number;
  readonly webOnly: boolean;
  readonly sort: number;
  readonly calories: string;
  readonly toppingPid: string; // best to use to match topping to topping

  readonly isDefault: boolean; // not from API

  selected: boolean;
  placement: PizzaSideEnum;
  multiplier: number;

  constructor(topping: Topping) {
    //@ts-ignore
    this.id = nullOrEmpty(topping?.externalId) ?? nullOrEmpty(topping?.id) ?? "";
    this.name = topping.name?.replace(/\*/g, "") ?? "";
    this.canSubstitute = stringToBool(topping.canSubstitute);
    this.isDefault = !stringToBool(topping.canSubstitute);
    this.isMeat = stringToBool(topping.isMeat);
    this.webOnly = stringToBool(topping.webOnly);
    this.min = +topping.min ?? 1;
    this.max = +topping.max ?? 10;
    this.selected = stringToBool(topping.selected);
    this.sort = +topping.sort ?? 0;
    this.placement = topping.placement ?? PizzaSideEnum.all;
    this.calories = topping.calories ? topping.calories : "0";
    this.toppingPid = topping.toppingPid ?? "";
    this.multiplier = isNaN(+topping.multiplier) ? +this.selected : +topping.multiplier;
  }

  get calorieValue() {
    return this.calories
      ?.match(/[0-9]*/g)
      ?.filter((n) => !!n)
      .map((str) => +str);
  }
  get isExtraCheese() {
    return this.name.toLowerCase().includes("extra provolone cheese");
  }
  get isNoTopping() {
    return this.name.toLowerCase().includes("no ");
  }
}
export class ToppingsGroup {
  readonly displayText: string;
  readonly displayOrder: number;
  readonly minToppingCount: number;
  readonly maxToppingCount: number;
  readonly extracostflag: boolean;
  readonly toppings: Topping[];
  constructor() {
    this.displayText = "";
    this.displayOrder = 0;
    this.minToppingCount = 0;
    this.maxToppingCount = 0;
    this.extracostflag = false;
    this.toppings = [];
  }
}
/**
  sizeId: string;
  recipeId?: string;
  itemId: string;
  categoryId: string;
 */
export class ToppingReq {
  sizeId?: string;
  recipeId: string;
  itemId: string;
  categoryId: string;
  constructor(req: ToppingReq) {
    this.sizeId = req.sizeId;
    this.recipeId = req.recipeId;
    this.itemId = req.itemId;
    this.categoryId = req.categoryId;
  }
}
/** AKA Sauce cups for hand tossed pizzas */
export class RequiredTopping {
  canSubstitute: boolean;
  categoryId: string; // "20"
  crustType: string; // "4"
  externalId: string; //"store=1,cat=1,topping=20"
  isMeat: boolean;
  requiredId: string; //"406"
  requiredName: string; //"No Cup "
  webOnly: boolean;
  constructor(data: RequiredTopping) {
    this.canSubstitute = data.canSubstitute;
    this.categoryId = data.categoryId;
    this.crustType = data.crustType;
    this.externalId = data.externalId;
    this.isMeat = data.isMeat;
    this.requiredId = data.requiredId;
    this.requiredName = data.requiredName;
    this.webOnly = data.webOnly;
  }
  get name() {
    return this.requiredName;
  }
}
