import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_ctx.locations)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        fill: "outline",
        mode: "md",
        "data-cy": _ctx.dataCy
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { position: "floating" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1),
              (_ctx.required)
                ? (_openBlock(), _createBlock(_component_ion_text, {
                    key: 0,
                    color: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" *")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_select, {
            modelValue: _ctx.localModelValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
            interface: "popover",
            mode: "md",
            disabled: _ctx.disabled
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locations, (location) => {
                return (_openBlock(), _createBlock(_component_ion_select_option, {
                  key: location.name,
                  value: location.id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(location.name), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      }, 8, ["data-cy"]))
    : _createCommentVNode("", true)
}