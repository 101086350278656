import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    mode: _ctx.stacked ? 'md' : _ctx.mode,
    fill: "border",
    class: _normalizeClass({ dense: _ctx.dense })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, {
        position: _ctx.stacked ? 'stacked' : 'floating',
        color: _ctx.labelColor
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label ? _ctx.label : "Email Address"), 1),
          (_ctx.required)
            ? (_openBlock(), _createBlock(_component_ion_text, {
                key: 0,
                color: "danger"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("*")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["position", "color"]),
      _createVNode(_component_ion_input, {
        autocomplete: "email",
        type: "email",
        inputmode: "email",
        modelValue: _ctx.localModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
        placeholder: "example@gmail.com",
        pattern: "email",
        name: _ctx.name
      }, null, 8, ["modelValue", "name"])
    ]),
    _: 1
  }, 8, ["mode", "class"]))
}