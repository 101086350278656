import { ThemeEnum } from "./ThemeEnum";
export class StoreStatusEnum {
  static open = new StoreStatusEnum("", ThemeEnum.none);
  static closed = new StoreStatusEnum("Temporarily Closed", ThemeEnum.lightDanger);
  static closeSoon = new StoreStatusEnum("Closing Soon", ThemeEnum.lightWarning);
  static eatIn = new StoreStatusEnum("Pick Up & Eat In Only", ThemeEnum.lightWarning);
  static comingSoon = new StoreStatusEnum("Coming Soon", ThemeEnum.lightSuccess);
  static phoneOnly = new StoreStatusEnum("Phone & In-Person Ordering Only", ThemeEnum.lightWarning);

  status: string;
  color: ThemeEnum;

  constructor(status, color: ThemeEnum) {
    this.status = status;
    this.color = color;
  }
  get toString() {
    return `${this.status}`;
  }
  get toColor() {
    return `${this.color}`;
  }
}
