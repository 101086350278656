import { PaymentIdEnum, PaymentTypeEnum } from "@/enums/PaymentTypeEnum";

export class Payment {
  paymentType?: string;
  amount: string;

  constructor(payment: Partial<Payment>) {
    this.paymentType = payment.paymentType;
    // amount is part of the order, it is the amount charged to a payment type during order process.
    this.amount = payment.amount ?? "0.00";
  }
  get isGiftCard(): boolean {
    return this.paymentType == PaymentIdEnum.giftCard;
  }
  get isCash(): boolean {
    return this.paymentType == PaymentIdEnum.cash;
  }
}

export class Cash extends Payment {
  get selectedPaymentDisplay() {
    return `${PaymentTypeEnum.cash}`;
  }
}

export class CreditCard extends Payment {
  creditCardId: string;
  customerId: string;
  lastFour: string;
  lastFourDigits: string;
  cardType: string;
  cardToken: string;
  expirationDate: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  zipCode: string;
  cardZip: string;
  cardholderName: string;
  isDefaultCard: boolean;
  ps2000: string;

  constructor(card: Partial<CreditCard>) {
    super(card);
    this.paymentType = PaymentIdEnum.credit;
    this.creditCardId = card.creditCardId ?? card.id ?? "";
    this.customerId = card.customerId ?? "";
    this.lastFour = card.lastFour ?? card.lastFourDigits ?? "";
    this.lastFourDigits = card.lastFour ?? card.lastFourDigits ?? "";
    this.cardType = card.cardType ?? "";
    this.cardToken = card.cardToken ?? "";
    this.expirationDate = card.expirationDate ?? "";
    this.cardExpirationMonth = card.expirationDate?.slice(0, 2) ?? "";
    this.cardExpirationYear = card.expirationDate?.slice(-2) ?? "";
    this.zipCode = card.zipCode ?? card.cardZip ?? "";
    this.cardZip = card.cardZip ?? card.zipCode ?? "";
    this.cardholderName = card.cardholderName ?? "";
    this.isDefaultCard = card.isDefaultCard ? +card.isDefaultCard == 1 : false;
    this.ps2000 = card.ps2000 ?? "";
    // 'amount' is only needed for orders
    this.amount = card.amount ?? "";
  }

  get selectedPaymentDisplay(): string {
    return `Card *${this.lastFourDigits}`;
  }
  get id(): string {
    return this.creditCardId;
  }
  set id(id) {
    this.creditCardId = id;
  }
  get default(): boolean {
    return this.isDefaultCard;
  }

  accountPayload = () => {
    return new SavedCard(this);
  };
  asDelete = () => {
    return new DeleteCard(this);
  };
}

export class SavedCard {
  creditCardId: string;
  customerId: string;
  lastFour: string;
  cardType: string;
  cardToken: string;
  expirationDate: string;
  zipCode: string;
  cardholderName: string;
  isDefaultCard: boolean;
  ps2000: string;
  constructor(card: Partial<SavedCard>) {
    this.creditCardId = card.creditCardId ?? "";
    this.customerId = card.customerId ?? "";
    this.lastFour = card.lastFour ?? "";
    this.cardType = card.cardType ?? "";
    this.cardToken = card.cardToken ?? "";
    this.expirationDate = card.expirationDate ?? "";
    this.zipCode = card.zipCode ?? "";
    this.cardholderName = card.cardholderName ?? "";
    this.isDefaultCard = card.isDefaultCard ?? false;
    this.ps2000 = card.ps2000 ?? "";
  }
}
export class DeleteCard {
  creditCardId: string;
  customerId: string;
  newDefaultCardId: string;

  constructor(card: Partial<DeleteCard>) {
    this.creditCardId = card.creditCardId ?? "";
    this.customerId = card.customerId ?? "";
    this.newDefaultCardId = "0";
  }
}

export class GiftCard extends Payment {
  cardNumber: string;
  cardPin: string;
  balance: string;

  constructor(giftCard: Partial<GiftCard>) {
    super(giftCard);
    this.cardNumber = giftCard.cardNumber ?? "";
    this.cardPin = giftCard.cardPin ?? "";
    this.balance = giftCard.balance ?? "";
    this.paymentType = PaymentIdEnum.giftCard;
  }

  get selectedPaymentDisplay() {
    return `${PaymentTypeEnum.giftCard} - Balance $${this.balance}`;
  }
  get asValidationPayload() {
    return {
      cardNumber: this.cardNumber,
      cardPin: this.cardPin,
    };
  }
}

export class validGiftCardResponse {
  valid: boolean;
  balance: string;
  constructor(response: Partial<validGiftCardResponse>) {
    this.valid = response.valid ?? false;
    this.balance = response.balance ?? "";
  }
}

export class ElavonResponse {
  sslCardNumber: string;
  sslAvsZip: string;
  sslPs2000Data: string;
  sslExpDate: string;
  sslCardShortDescription: string;
  sslToken: string;
  sslAvsResponse: string;

  constructor(response: any) {
    this.sslCardNumber = response.ssl_card_number ?? "";
    this.sslAvsZip = response.ssl_avs_zip ?? "";
    this.sslPs2000Data = response.ssl_ps2000_data ?? "";
    this.sslExpDate = response.ssl_exp_date ?? "";
    this.sslCardShortDescription = response.ssl_card_short_description ?? "";
    this.sslToken = response.ssl_token ?? "";
    this.sslAvsResponse = response.ssl_avs_response ?? "";
  }

  get validElavonResponse() {
    return !!this.sslPs2000Data && !!this.sslToken;
  }

  convertToCard = (amount = "") => {
    const cardData = {
      creditCardId: "",
      customerId: "",
      lastFour: this.sslCardNumber.slice(-4),
      cardType: this.sslCardShortDescription,
      cardToken: this.sslToken,
      expirationDate: `${this.sslExpDate.slice(0, 2)}/${this.sslExpDate.slice(-2)}`,
      zipCode: this.sslAvsZip,
      cardholderName: "",
      isDefaultCard: false,
      ps2000: this.sslPs2000Data,

      paymentType: PaymentIdEnum.credit,
      amount: amount,
    };
    return new CreditCard(cardData);
  };
}
