import { DateTime, Interval } from "luxon";
import { AvailableTopping, Instruction } from "@/models/menuModels";
import { Coordinate, DeliveryAddress } from "./addressModels";
import { Topping } from "./menuModels";
import { sumToppingPriceBySelectedSize } from "@/services/orderService";
import { stringToBool, nullOrEmpty, round5, scrubHtmlElements, nullOrFirst, titleCase } from "@/services/stringService";
import {DONATION_WEBCAT, SALAD_WEBCAT, WING_WEBCAT} from "@/constants";
import { useStore } from "vuex";
export class LineItem {
  customerId?: string;
  quantity: number;
  id: string;
  displayName?: string;
  name: string;
  baseprice: number;
  imageSm: string;
  price: number;
  selectedInstructions: Instruction[];
  recipes: CartRecipe[];
  toppings: Topping[];
  selectedToppings: Topping[];
  selectedRequiredToppings: Topping[]; // sauce cup required for hand tossed pizzas
  selectedDressings: Topping[];
  selectedFlavors: Topping[];
  selectedSauces: Topping[];
  lineitemId?: number;

  // Used for two sided pizza
  id2?: string;
  name2?: string;
  displayName2?: string;
  baseprice2?: number;
  imageSm2?: string;
  price2?: number;
  selectedInstructions2?: Instruction[];
  recipes2?: CartRecipe[];
  toppings2?: Topping[];
  selectedToppings2?: Topping[];
  selectedRequiredToppings2?: Topping[];
  selectedDressings2?: Topping[];
  selectedFlavors2?: Topping[];
  selectedSauces2?: Topping[];

  constructor(add: LineItem) {
    this.lineitemId = add.lineitemId;

    this.customerId = add.customerId;
    this.quantity = +add.quantity;
    this.id = add.id;
    this.name = scrubHtmlElements(add.name); // phoenix doesn't like "&"
    this.displayName = add.name;
    this.baseprice = add.baseprice;
    this.imageSm = add.imageSm;
    this.price = +add.price;
    this.selectedInstructions = add.selectedInstructions instanceof Array ? add.selectedInstructions.map((s) => new Instruction(s)) : [];
    this.recipes = add.recipes;
    this.toppings = add.toppings instanceof Array ? add.toppings?.map((t) => new Topping(t)) : [];
    this.selectedToppings = add.selectedToppings instanceof Array ? add.selectedToppings.map((t) => new Topping(t)) : [];
    this.selectedDressings = add.selectedDressings instanceof Array ? add.selectedDressings.map((t) => new Topping(t)) : [];
    this.selectedFlavors = add.selectedFlavors instanceof Array ? add.selectedFlavors.map((t) => new Topping(t)) : [];
    this.selectedSauces = add.selectedSauces instanceof Array ? add.selectedSauces.map((t) => new Topping(t)) : [];
    this.selectedRequiredToppings =
      add.selectedRequiredToppings instanceof Array ? add.selectedRequiredToppings?.map((t) => new Topping(t)) ?? [] : [];
    this.selectedRequiredToppings2 =
      add.selectedRequiredToppings2 instanceof Array ? add.selectedRequiredToppings2?.map((t) => new Topping(t)) ?? [] : [];

    // Used for two sided pizza
    this.id2 = add.id2;
    this.name2 = scrubHtmlElements(add.name2 ?? "");
    this.displayName2 = add.name2;
    this.baseprice2 = add.baseprice2;
    this.imageSm2 = add.imageSm2;
    this.price2 = add.price2;
    this.selectedInstructions2 = add.selectedInstructions2 instanceof Array ? add.selectedInstructions2?.map((s) => new Instruction(s)) : [];
    this.recipes2 = add.recipes2;
    this.toppings2 = add.toppings2 instanceof Array ? add.toppings2?.map((t) => new Topping(t)) : [];
    this.selectedToppings2 = add.selectedToppings2 instanceof Array ? add.selectedToppings2?.map((t) => new Topping(t)) : [];
    this.selectedDressings2 = add.selectedDressings2 instanceof Array ? add.selectedDressings2?.map((t) => new Topping(t)) : [];
    this.selectedFlavors2 = add.selectedFlavors2 instanceof Array ? add.selectedFlavors2?.map((t) => new Topping(t)) : [];
    this.selectedSauces2 = add.selectedSauces2 instanceof Array ? add.selectedSauces2?.map((t) => new Topping(t)) : [];

    // realign selected toppings with recipe/size specific topping ids
    this.selectedToppings.forEach((selTop) => {
      const updatedTop = this.toppings.find((t) => t.toppingPid === selTop.toppingPid);
      if (!updatedTop) return;
      selTop.id = updatedTop.id;
    });
    this.selectedToppings2?.forEach((selTop) => {
      const updatedTop = this.toppings2?.find((t) => t.toppingPid === selTop.toppingPid);
      if (!updatedTop) return;
      selTop.id = updatedTop.id;
    });

    this.selectedRequiredToppings?.forEach((r) => {
      r.selected = true;
      r.multiplier = 1;
    });

    this.selectedRequiredToppings2?.forEach((r) => {
      r.selected = true;
      r.multiplier = 1;
    });
  }

  get showCheeseItUp() {
    return !(
      !this.toppings.some((t) => t.isExtraCheese) ||
      this.selectedInstructions?.some((i) => i.selected && i.isNoCheese) ||
      this.selectedToppings?.some((t) => t.isExtraCheese) ||
      this.toppings?.some((t) => t.isExtraCheese && t.selected) ||
      this.firstRecipe?.isVegan ||
      this.isTwoSidedCustom
    );
  }
  get isTwoSidedCustom() {
    return !!this.id2;
  }
  get isOneSize() {
    return this.firstSize?.name?.toLowerCase().includes("one size");
  }
  get asAvailableToppings() {
    //@ts-ignore
    return new AvailableTopping({
      toppings: this.toppings,
      flavors: this.selectedFlavors,
      dressings: this.selectedDressings,
      sauces: this.selectedSauces,
      updatingSelectedToppings: this.selectedToppings,

      toppings2: this.toppings2,
      flavors2: this.selectedFlavors2,
      dressings2: this.selectedDressings2,
      sauces2: this.selectedSauces2,
      updatingSelectedToppings2: this.selectedToppings2,
    });
  }

  get defaultToppings() {
    return this.toppings.filter((t) => t.isDefault);
  }
  get missingDefaultToppings() {
    const list = this.defaultToppings?.filter((d) => !this.selectedToppings.some((s) => s.toppingPid === d.toppingPid)) ?? [];
    list.forEach((m) => (m.selected = false));
    return list ?? [];
  }

  get cartName() {
    const addToEnd = this.displayName2 ? ` & ${this.displayName2}` : "";
    return this.displayName + addToEnd;
  }
  get flatFlavor() {
    const flavor = nullOrFirst(this.selectedFlavors);
    if (!flavor || flavor.length === 0) return;
    return flavor;
  }
  get totalToppingCount() {
    return this.selectedToppings?.filter((c) => c.selected).reduce((total, topping) => (total += topping.multiplier), 0) ?? 0;
  }
  get isCyoPizza() {
    return this.displayName?.toLowerCase().includes("create");
  }
  get isCyoPizza2() {
    return this.displayName2?.toLowerCase().includes("create");
  }
  get isDonation() {
    return this.id ? this.id.includes(`webcat=${DONATION_WEBCAT}`) : false;
  }
  get isSalad() {
    return (this.id ? this.id.includes(`webcat=${SALAD_WEBCAT}`) : false) || this.name.toLowerCase().includes("salad");
  }
  get isWing(){
    return (this.id ? this.id.includes(`webcat=${WING_WEBCAT}`) : false) || this.name.toLowerCase().includes("wing");
  }
  get twoSideBasePriceCalc() {
    return this.price2 ? (this.price + this.price2) / 2 : undefined;
  }

  get firstRecipe(): CartRecipe {
    return nullOrFirst(this.recipes);
  }
  get firstSize(): CartSize {
    return nullOrFirst(this.firstRecipe?.size);
  }

  twoSidePriceCalculation(crust, size, toppingPrices) {
    return this.twoSideBasePriceCalc
      ? (this.isCyoPizza ? sumToppingPriceBySelectedSize(crust, size, toppingPrices, this.selectedToppings, false) / 2 : 0) +
          (this?.isCyoPizza2 ? sumToppingPriceBySelectedSize(crust, size, toppingPrices, this.selectedToppings2, false) / 2 : 0) +
          (this?.twoSideBasePriceCalc ?? 0)
      : size.price;
  }
}
export class OrderHeader {
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  frontendType: string;
  orderFor: string;
  orderForName: string;
  companyName: string;
  loyaltyMemberId: string; // "Q-000286063"
  isMarketingEmail: number;
  orderDate: string; // "2022-08-17 17:19:12",
  submitResult: {
    resultType: string;
    orderHeaderID?: string;
    promiseTime: {
      date?: number; // minutes
      timezoneType: string;
      timezone: string;
    };
    messages: any;
  };
  constructor(header: Partial<OrderHeader>) {
    this.customerId = header.customerId ?? "";
    this.firstName = header.firstName ?? "";
    this.lastName = header.lastName ?? "";
    this.email = header.email ?? "";
    this.phoneNumber = header.phoneNumber ?? "";
    this.frontendType = header.frontendType ?? "4";
    this.orderFor = header.orderFor ?? "";
    this.orderForName = header.orderForName ?? "";
    this.companyName = "";
    this.loyaltyMemberId = header.loyaltyMemberId ?? "";
    this.isMarketingEmail = +(header.isMarketingEmail ?? false);
    this.orderDate = header.orderDate ?? "";

    const messageArray: string[] = [];
    if (header.submitResult?.messages?.message && typeof header.submitResult?.messages.message == "string") {
      messageArray.push(header.submitResult?.messages.message.toString());
    } else if (header.submitResult?.messages?.message && typeof header.submitResult?.messages.message == "object") {
      header.submitResult?.messages.message.forEach((message) => {
        messageArray.push(message);
      });
    }
    this.submitResult = {
      resultType: header.submitResult?.resultType ?? "",
      orderHeaderID: header.submitResult?.orderHeaderID,
      promiseTime: {
        date: nullOrEmpty(header.submitResult?.promiseTime?.date ?? header.submitResult?.promiseTime),
        timezoneType: header.submitResult?.promiseTime?.timezoneType ?? "",
        timezone: header.submitResult?.promiseTime?.timezone ?? "",
      },
      messages: messageArray,
    };
  }
}
export class DeliveryInfo {
  addressId?: string;
  defaultAddress: boolean;
  addressName?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  coordinate?: Coordinate;
  latitude: string;
  longitude: string;
  deliveryGrid?: string;
  deliveryInstructions?: string;
  deliveryCharge?: number;
  driverTip?: number;
  isContactlessDelivery?: boolean;

  constructor(data?: Partial<DeliveryInfo>) {
    this.addressId = data?.addressId ?? "";
    this.addressName = data?.addressName ?? "";
    //@ts-ignore
    this.coordinate = nullOrEmpty(data?.coordinate) ?? new Coordinate(this);
    this.latitude = data?.latitude ?? String(this.coordinate?.latitude ?? "");
    this.longitude = data?.longitude ?? String(this.coordinate?.longitude ?? "");
    this.deliveryGrid = data?.deliveryGrid ?? "";
    this.deliveryInstructions = data?.deliveryInstructions ?? "";
    this.driverTip = data?.driverTip;
    this.address1 = data?.address1 ?? "";
    this.address2 = data?.address2 ?? "";
    this.city = data?.city ?? "";
    this.state = data?.state ?? "";
    //@ts-ignore
    this.zipCode = data?.zipCode ?? data?.zip ?? data?.zipcode ?? "";
    //@ts-ignore
    this.defaultAddress = stringToBool(data?.default ?? data?.defaultAddress) ?? false;
    this.isContactlessDelivery = data?.isContactlessDelivery ?? false;
    this.deliveryCharge = data?.deliveryCharge ?? 0;
  }
}
export class FullOrder {
  orderHeaderID?: string; //not from api
  timezoneOffset: number; //not from api
  orderHeader: OrderHeader;
  orderDetails: OrderDetail;
  constructor(order: FullOrder) {
    this.orderHeaderID = order.orderHeaderID ?? order.orderHeader?.submitResult?.orderHeaderID;
    this.timezoneOffset = order.timezoneOffset ?? 0;
    this.orderHeader = new OrderHeader(order.orderHeader);
    this.orderDetails = new OrderDetail(order.orderDetails);
  }
  get logistics() {
    return this.orderDetails.orderLogistics;
  }
  get orderPlacedTimeZone() {
    return new DateTime(this.logistics?.orderPlacedForDatetime.minus({ hours: this.timezoneOffset }));
  }
  get promiseInterval() {
    return new Interval(Interval.fromDateTimes(DateTime.now(), new DateTime(this.orderPlacedTimeZone.ts)));
  }
  get promiseTimeMinDiff() {
    return this.promiseInterval?.length("minutes").toFixed(0);
  }

  get promisedMinutesDisplay() {
    let display;
    if (this.promiseTimeMinDiff > 0 && this.promiseTimeMinDiff <= 55) {
      const nearest5 = round5(this.promiseTimeMinDiff);
      display = ` in ${nearest5} - ${nearest5 + 5} minutes`;
    } else if (this.promiseInterval.length("hours") > 12 || this.promiseInterval.length("hours") <= 0) {
      display = ` at ${this.orderPlacedTimeZone.toLocaleString(DateTime.DATETIME_MED)}`;
    } else {
      display = ` at ${this.orderPlacedTimeZone.toLocaleString(DateTime.TIME_SIMPLE)}`;
    }
    return display;
  }
}
export class OrderConfirmation {
  resultType: string;
  orderHeaderID: string;
  promiseTime: string; // minutes from requested time
  constructor(data: OrderConfirmation) {
    this.resultType = data.resultType;
    this.orderHeaderID = data.orderHeaderID;
    this.promiseTime = data.promiseTime;
  }
}
export class AddToCartRes {
  // todo should just be full order?
  orderLogistics: {
    orderType: string;
    deliveryInfo: {
      deliveryToAddress: DeliveryAddress;
    };
  };
  lineItems: LineItem[];
  coupons: [];
  webSpecials: [];
  payments: [];
  subTotal: number;
  taxTotal: number;
  grandTotal: number;
  promiseTime: string;

  constructor(res: AddToCartRes) {
    this.lineItems = res.lineItems;
    this.coupons = res.coupons;
    this.webSpecials = res.webSpecials;
    this.payments = res.payments;
    this.subTotal = res.subTotal;
    this.taxTotal = res.taxTotal;
    this.grandTotal = res.grandTotal;
    this.orderLogistics = res.orderLogistics;
    this.promiseTime = res.promiseTime;
  }
}
export class CartRecipe {
  id: string; //externalId from menu,
  base: string; //recipeName from menu,
  size: CartSize[];
  constructor(item: Partial<CartRecipe>) {
    this.id = item.id ?? "";
    this.base = item.base ?? "";
    this.size = item.size ?? [];
  }
  get isVegan() {
    return this.base.toLowerCase().includes("vegan");
  }
}
export class CartSize {
  id: string; //externalId from menu,
  name: string; //sizeName from menu,
  price: number; //size price from menu
  constructor(item: CartSize) {
    this.id = item.id;
    this.name = item.name;
    this.price = item.price;
  }
}
export class PickupInfo {
  location: string; // This is actually the sub type (Dine In, Pick Up, Window, etc)
  tip = 0;
  make = "";
  model = "";
  color = "";

  constructor(info?: PickupInfo) {
    this.location = info?.location ?? "";
    this.tip = info?.tip ?? 0;
    this.make = info?.make ?? "";
    this.model = info?.model ?? "";
    this.color = info?.color ?? "";
  }
}
export class OrderDetail {
  orderLogistics: OrderLogistics;
  lineItems: LineItem[];
  coupons: Coupon[];
  webSpecials: WebSpecial[];
  payments: any[];

  subTotal?: number;
  taxTotal?: number;
  grandTotal?: number;

  constructor(detail: OrderDetail) {
    this.orderLogistics = new OrderLogistics(detail.orderLogistics);
    //@ts-ignore
    if (detail.lineItems?.LineItem && detail.lineItems?.LineItem.id) {
      //@ts-ignore // singleItem response
      this.lineItems = Object.values(detail.lineItems)?.map((l) => new LineItem({ ...l.item, lineitemId: l.id, quantity: l.quantity }));
      //@ts-ignore
    } else if (detail.lineItems?.LineItem && !detail.lineItems?.LineItem.id) {
      //@ts-ignore // multipleItem response
      this.lineItems = Object.values(detail.lineItems?.LineItem)?.map((l) => new LineItem({ ...l.item, lineitemId: l.id, quantity: l.quantity }));
    } else {
      this.lineItems = detail.lineItems?.map((l) => new LineItem(l)) ?? [];
    }
    if (typeof detail.coupons == "string") {
      this.coupons = [];
    } else if (detail.coupons["Coupon"]) {
      this.coupons =
        detail.coupons["Coupon"].length > 1 ? detail.coupons["Coupon"]?.map((c) => new Coupon(c)) : [new Coupon(detail.coupons["Coupon"])];
    } else {
      this.coupons = detail.coupons?.map((c) => new Coupon(c)) ?? [];
    }
    this.webSpecials = typeof detail.webSpecials == "string" ? [] : detail.webSpecials?.map((w) => new WebSpecial(w)) ?? [];
    this.payments = typeof detail.payments == "string" ? [] : detail.payments ?? [];

    this.subTotal = +(detail?.subTotal ?? 0);
    this.taxTotal = +(detail?.taxTotal ?? 0);
    this.grandTotal = +(detail?.grandTotal ?? 0);
  }
}

export class OrderLogistics {
  customerId: string;
  orderType?: string;
  storeNumber?: string;
  phoneNumber?: string;
  isImmediate: boolean;
  date: string;
  time: string;
  deliveryInfo?: DeliveryInfo;
  pickupInfo?: PickupInfo;

  constructor(detail: OrderLogistics) {
    this.customerId = detail.customerId ?? "";
    this.phoneNumber = detail.phoneNumber ?? "";
    //@ts-ignore
    this.storeNumber = detail.storeNumber ?? detail.storenumber ?? "";
    this.orderType = detail.orderType ?? "";
    this.deliveryInfo = new DeliveryInfo(detail.deliveryInfo);
    //@ts-ignore
    this.isImmediate = detail?.isImmediate ?? true;
    this.date = detail?.date ?? DateTime.now().toFormat("yyyy-LL-dd");
    this.time = detail?.time ?? DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE);
    this.pickupInfo = new PickupInfo(detail.pickupInfo);

    if (detail.orderType?.toLowerCase().includes("delivery")) this.pickupInfo.location = "";
  }

  get orderPlacedForDatetime() {
    return DateTime.fromISO(`${this.date}T${this.time}`);
  }
}
export class Coupon {
  earnedOfferId?: number;
  campaign: string;
  campaignCodeId: string;
  code: string;
  name: string; //"$2.00 Off Any Lrg"
  phoenixCode: string;
  redemptionCount: number;
  redemptionsPerCode: number;
  singleUseCode: number;

  webspecial?: string;

  /** Response from getOrderPrice */
  description?: string; //"$2.00 Off any Large Pizza"
  discount?: number; //"2.00"
  image?: string; //"Default"
  isApplied?: boolean; //"true"
  loyaltyOfferCode?: string; //"OfferResponse-"
  noOtherDiscount?: boolean; //"false"
  type?: boolean; //"MANUAL" || "AUTOAPPLY"

  constructor(coupon: Coupon) {
    this.campaign = coupon.campaign ?? "";
    this.campaignCodeId = coupon.campaignCodeId ?? "";
    this.code = coupon.webspecial ?? coupon.code ?? "";
    this.name = coupon.description ?? coupon.name ?? "";
    this.phoenixCode = coupon.webspecial ?? coupon.phoenixCode ?? coupon.code ?? "";
    this.redemptionCount = +coupon.redemptionCount ?? 0;
    this.redemptionsPerCode = +coupon.redemptionsPerCode ?? 0;
    this.singleUseCode = +coupon.singleUseCode ?? 0;
    this.loyaltyOfferCode = coupon.loyaltyOfferCode ?? "";
    this.earnedOfferId = coupon.earnedOfferId ?? 0;

    this.discount = coupon.discount;
    // this.image = coupon.image;
    // this.isApplied = coupon.isApplied;
    // this.noOtherDiscount = coupon.noOtherDiscount;
    // this.type = coupon.type;
  }

  get loyaltyOfferCodeNumericValues() {
    const input = this.loyaltyOfferCode + "";
    return input.replace(/[^0-9]/g, "");
  }
}

export class OrderTotals {
  tip: number;
  tax: number;
  cartTotal: number;
  grandTotal: number;
  donation: number;
  deliveryCharge: number;
  constructor(totals: Partial<OrderTotals>) {
    this.tip = totals.tip ?? 0;
    this.tax = totals.tax ?? 0;
    this.cartTotal = totals.cartTotal ?? 0;
    this.donation = totals.donation ?? 0;
    this.deliveryCharge = totals.deliveryCharge ?? 0;
    this.grandTotal = totals.grandTotal ?? 0;
  }

  get discounts() {
    const store = useStore();
    return -store.getters.getCouponsDiscount;
  }
  get subtotalMinusDonation() {
    return this.cartTotal - this.donation;
  }
}

export class ItemPriceCal {
  itemPrice: string; //"14.59",
  calories: string; //"160 cal/pc*"
  constructor(item: ItemPriceCal) {
    this.itemPrice = (+item.itemPrice).toFixed(2);
    this.calories = item.calories;
  }
}

export class WebSpecial {
  id: string;
  code: string;
  phoenixCode?: string;
  name: string;
  type: string;
  description: string;
  discount: number;
  shortDescription?: string;
  longDescription?: string;
  image?: string;
  sort: number;
  status: string;
  constructor(data: WebSpecial) {
    this.id = data.id;
    this.code = data.code;
    this.phoenixCode = data.phoenixCode ?? data.code;
    this.name = data.name;
    this.type = data.type;
    this.description = data.description;
    this.discount = +data.discount;
    this.shortDescription = data.shortDescription;
    this.longDescription = data.longDescription;
    this.image = nullOrEmpty(data.image);
    this.sort = +data.sort ?? 0;
    this.status = data.status;
  }

  get cdnImg() {
    return !nullOrEmpty(this.image) || this.image == "default"
      ? require("@/assets/mobileFallback.jpeg")
      : process.env.VUE_APP_IMAGE_URL + "/" + this.image;
  }
}

export class HistoryOrderItem {
  itemName: string;
  crusttype: string;
  size: string;
  json: any;
  constructor(item: HistoryOrderItem) {
    this.itemName = item.itemName;
    this.crusttype = item.crusttype;
    this.size = item.size;
    this.json = item.json;
  }

  get asLineItem() {
    return new LineItem(this.json);
  }
  get crustTypeDisplay() {
    switch (this.crusttype.toLowerCase()) {
      case "all crusts":
        return "";
      case "calzato":
          return "";
      case "calzone":
        return "";
      case "white":
        return "";
      default:
        return titleCase(this.crusttype);
    }
  }
  get nameDisplay() {
    switch (this.crusttype.toLowerCase()) {
      case "all crusts":
        return titleCase(this.itemName);
      case "calzato":
          return titleCase(this.itemName);
      case "calzone":
        return titleCase(this.itemName);
      case "white":
        return titleCase(this.itemName);
      default:
        return this.itemName.toLowerCase().includes("pizza") ? this.itemName : titleCase(`${this.itemName} pizza`);
    }
  }
  get sizeDisplay() {
    switch (this.size.toLowerCase()) {
      case "one size":
        return "";
      case "full":
        return "Full Size";
      case "party":
        return "Party Size";
      default:
        return this.size;
    }
  }
  get description() {
    return `${this.sizeDisplay} ${this.crustTypeDisplay}`;
  }
}

export class HistoryOrder {
  orderHeaderId: string;
  orderDate: string;
  items: HistoryOrderItem[];
  constructor(history: HistoryOrder) {
    this.orderHeaderId = history.orderHeaderId;
    this.orderDate = history.orderDate;
    this.items = history.items.map((i) => new HistoryOrderItem(i));
  }

  get displayDate() {
    return DateTime.fromSQL(this.orderDate).toFormat("MM/dd");
  }
}
