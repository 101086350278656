import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import accountRoutes from "./accountRoutes";
import locationRoutes from "./locationRoutes";
import staticPageRoutes from "./staticPageRoutes";
import menuRoutes from "./menuRoutes";
import updateMeta, { vanillaQueryParam } from "@/services/pageService";
import cateringRoutes from "./cateringRoutes";
import { PERSIST_TRACKING_PARAMS } from "@/constants";

// ! Remember to update baseSitemap.json if adding new routes
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/views/Home.vue"),
    meta: {
      title: "Every Piece Is Important",
    },
  },
  {
    path: '/index.html',
    redirect: '/'
  },
  {
    path: "/404",
    component: () => import("@/views/404.vue"),
    meta: {
      title: "Every Piece Is Important",
    },
  },
  ...accountRoutes,
  ...locationRoutes,
  ...staticPageRoutes,
  ...menuRoutes,
  ...cateringRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
/**
 * Initial tracking params (utm_source, utm_medium, gclid, etc) from ads can be applied
 * External params are collected here
 */
const initialParams: any[] = [];
PERSIST_TRACKING_PARAMS.filter((q) => vanillaQueryParam(q)).forEach((q) => initialParams.push({ [q]: vanillaQueryParam(q) }));

router.beforeEach((to, from, next) => {
  updateMeta(to.meta);
  const addBackInitialQueryParams: any = to.query;
  let anyChange = false;
  initialParams.forEach((p) => {
    const [key, initialParamValue] = Object.entries(p)[0];
    // Check vue query param vs the initial value, if different => use the initial to update next()
    if (to.query[key] != initialParamValue) {
      addBackInitialQueryParams[key] = initialParamValue;
      anyChange = true;
    } else {
      addBackInitialQueryParams[key] = to.query[key];
    }
  });

  anyChange ? next({ path: to.path, query: addBackInitialQueryParams }) : next();
});

export default router;
