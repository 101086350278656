export const MenuCategoryEnum = Object.freeze({
  appetizers: {
    name: "Appetizers",
    order: 4,
    largeColumn: true,
    img: "appetizers.jpeg",
    path: "appetizers",
    identifier: "appetizers",
  },
  beverages: {
    name: "Beverages",
    order: 8,
    img: "beverages.jpeg",
    path: "beverages",
    identifier: "beverages",
  },
  calzatos: {
    name: "Calzatos",
    order: 3,
    largeColumn: true,
    img: "calzato.jpg",
    path: "calzatos",
    identifier: "calzatos",
  },
  desserts: {
    name: "Desserts",
    order: 7,
    img: "desserts.jpeg",
    path: "desserts",
    identifier: "desserts",
  },
  donations: {
    name: "Donations",
  },
  misc: {
    name: "Extras",
    order: 9,
    img: "extras.jpeg",
    path: "misc",
    identifier: "misc",
  },
  lunchCombos: {
    name: "New! Lunch Combos",
    order: 10,
    img: "lunch.png",
    path: "lunch",
    identifier: "lunch-combos",
  },
  // partySizes: {
  //   name: "Party Sizes",
  //   order: 9,
  //   img: "party.jpeg",
  //   path: "party-sizes",
  //   identifier: "party-sizes",
  // },
  pizza: {
    name: "Pizzas",
    order: 1,
    largeColumn: true,
    img: "pizzas.jpeg",
    path: "pizza",
    identifier: "pizza",
  },
  salads: {
    name: "Salads",
    order: 6,
    img: "salads.jpeg",
    path: "salads",
    identifier: "salads",
  },
  shareables: {
    name: "Shareables",
    identifier: "shareables",
  },
  subs: {
    name: "Subs",
    order: 2,
    largeColumn: true,
    img: "subs.jpeg",
    path: "subs",
    identifier: "subs",
  },
  wings: {
    name: "Wings",
    order: 5,
    img: "wings.jpeg",
    path: "wings",
    identifier: "wings",
  },
});
