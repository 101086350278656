export default class Phone {
  areaCode: string;
  phoneNumber: string;
  isMobilePhone: boolean;

  constructor(phone?: Partial<Phone>) {
    this.areaCode = phone?.areaCode ?? "";
    this.phoneNumber = phone?.phoneNumber ?? "";
    this.isMobilePhone = phone?.isMobilePhone ?? false;
  }
  get asString() {
    return this.areaCode + this.phoneNumber;
  }
}
