
import { defineComponent } from "vue";
import CartBtn from "@/components/cartBtn.vue";
import { IonHeader, IonToolbar, IonImg, IonRow, IonCol } from "@ionic/vue";
import accountModal from "@/components/modals/accountModal.vue";

export default defineComponent({
  props: {
    redirectToLocation: Boolean,
  },
  components: { IonHeader, IonToolbar, CartBtn, IonRow, IonCol, IonImg, accountModal },
});
