import Phone from "@/models/Phone";
import { Coordinate } from "@/models/addressModels";
import { Address } from "./addressModels";
import { stringToBool } from "@/services/stringService";

class ApiResponse {
  message: string;
  deliveryAddress: {
    default: string;
    storeNumber: string;
    name: string;
    address: Address;
    phone: Phone;
    coordinate: Coordinate;
    deliveryGrid: string;
    deliveryInstructions: string;
    addressType: string;
  };
  constructor(data: ApiResponse) {
    this.message = data.message;
    this.deliveryAddress = data.deliveryAddress;
  }
}

export default class ValidateDeliveryAddress {
  message: string; //"0012:The specified address is corrected.";
  default: boolean;
  storeNumber: string;
  name: string;
  address: Address;
  phone: string;
  coordinate: Coordinate;
  deliveryGrid: string; //"C6";
  deliveryInstructions: string;
  addressType: string;

  constructor(data: ApiResponse) {
    this.message = data.message;
    this.default = stringToBool(data.deliveryAddress.default);
    this.storeNumber = data.deliveryAddress.storeNumber;
    this.name = data.deliveryAddress.name;
    this.address = data.deliveryAddress.address;
    this.phone = data.deliveryAddress.phone?.asString ?? "";
    this.coordinate = data.deliveryAddress.coordinate;
    this.deliveryGrid = data.deliveryAddress.deliveryGrid;
    this.deliveryInstructions = data.deliveryAddress.deliveryInstructions;
    this.addressType = data.deliveryAddress.addressType;
  }
}
