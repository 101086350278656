import { computed } from "vue";
import { ContentBlock } from "@/models/contentBlockModel";
import { useStore } from "vuex";

export default function useContentBlock(slug) {
  const store = useStore();

  const contentBlocks = computed(() => {
    return store.getters.getContentBlockList;
  });

  const selectedBlock = computed(() => {
    if (contentBlocks.value.length > 0) {
      return contentBlocks.value.find((block) => block.slug == slug);
    } else {
      return new ContentBlock();
    }
  });

  const canDisplayBlock = computed(() => {
    return selectedBlock.value && selectedBlock.value.content !== "";
  });

  return {
    store,
    contentBlocks,
    selectedBlock,
    canDisplayBlock,
  };
}
