import { alertController } from "@ionic/vue";
import store from "@/store";
import router from "@/router";
import { formatPhoneNumber } from "../stringService";

export enum alertEnum {
  storeClosed = "STORE_CLOSED",
  storeInActive = "STORE_INACTIVE",
}
export default function (alert: alertEnum) {
  switch (alert) {
    case alertEnum.storeClosed:
      return alertLocationClosed;
    case alertEnum.storeInActive:
      return alertLocationInActive;
    default:
      return undefined;
  }
}

// const clearBtn = {
//   text: "Cancel",
//   role: "cancel",
//   handler: () => store.dispatch("clearAlert"),
// };

const continueBtn = {
  text: "Order Ahead",
  role: "cancel",
  handler: () => store.dispatch("clearAlert"),
};

const alertLocationInActive = async () => {
  if (!store.getters.getSelectedLocation) return;
  const alert = await alertController.create({
    header: `The ${store.getters.getSelectedLocation?.name} Donatos is only available for phone and in-person orders.`,
    message: `You can call at: ${formatPhoneNumber(store.getters.getSelectedLocation.phone)}. Or try another location.`,
    backdropDismiss: true,
    buttons: [
      {
        text: "Back to Cart",
        handler: () => {
          router.push("/cart");
        },
      },
      {
        text: "Change Location",
        handler: () => {
          store.dispatch("setNearLocationsByCoordinates", store.getters.getSelectedLocationGeolocation);
          router.push("/location");
        },
      },
    ],
  });
  return alert.present();
};

const alertLocationClosed = async () => {
  if (!store.getters.getSelectedLocation) return;

  const displayTime =
    store.getters.getSelectedLocation?.displayTime.charAt(0).toLowerCase() + store.getters.getSelectedLocation?.displayTime.slice(1);

  const alert = await alertController.create({
    header: `The ${store.getters.getSelectedLocation?.name} Donatos is closed right now.`,
    message: `This store ${displayTime}. Order ahead or try another location.`,
    backdropDismiss: true,
    buttons: [
      continueBtn,
      {
        text: "Change Location",
        handler: () => {
          store.dispatch("setNearLocationsByCoordinates", store.getters.getSelectedLocationGeolocation);
          router.push("/location");
        },
      },
    ],
  });
  return alert.present();
};
