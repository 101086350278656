
import { IonButton, IonIcon, IonRow, IonCol, IonGrid } from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { trashOutline, addCircleOutline } from "ionicons/icons";
import { CreditCard } from "@/models/PaymentModels";
import CreditCardItem from "./creditCardItem.vue";
import { Account } from "@/models/account/authModels";
import { useStore } from "vuex";
import CreditCardModal from "./checkout/creditCardModal.vue";
import useCheckoutJS from "@/composables/useCheckoutJS";
import { errorToast } from "@/services/pageService";

export default defineComponent({
  props: { account: Account },
  setup(props) {
    // Payment Gateway
    const { loadElavonScript } = useCheckoutJS();

    const store = useStore();
    const customerId = computed(() => props.account?.customerId ?? "");
    const cards = computed(() => props.account?.savedCards);
    const creditCardModalOpen = ref(false);

    const openCreditCardModal = () => {
      loadElavonScript();
      creditCardModalOpen.value = true;
    };

    const saveCard = async ({ creditCard }) => {
      try {
        creditCard.customerId = customerId.value;
        await store.dispatch("addSavedCard", creditCard);
      } catch (err) {
        if (err === "Card Updated") {
          errorToast(`This card has added already!`, 5000);
        }
      }
    };

    const makePrimaryCard = (card: CreditCard) => {
      //@ts-ignore
      const defaultCard = cards.value?.find((card) => card.isDefaultCard);
      if (defaultCard) {
        let currentDefaultCard: CreditCard = new CreditCard(defaultCard);
        currentDefaultCard.isDefaultCard = false;
        store.dispatch("updateSavedCard", currentDefaultCard);
      }
      card.isDefaultCard = true;
      store.dispatch("updateSavedCard", card);
    };

    const deleteCard = (card) => {
      store.dispatch("deleteSavedCard", card);
    };

    return { cards, trashOutline, addCircleOutline, makePrimaryCard, deleteCard, creditCardModalOpen, openCreditCardModal, saveCard };
  },

  components: {
    IonIcon,
    IonButton,
    IonRow,
    IonCol,
    IonGrid,
    CreditCardItem,
    CreditCardModal,
  },
});
