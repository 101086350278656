import DOMPurify from "dompurify";

export function formatPhoneNumber(phone: string) {
  const phoneArr = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/) ?? [];
  return !phoneArr[2] ? phoneArr[1] : "(" + phoneArr[1] + ") " + phoneArr[2] + (phoneArr[3] ? "-" + phoneArr[3] : "");
}
export function formatDate(date: string) {
  const dateArr = date.replace(/\D/g, "").match(/(0[1-9]|1[012]){0,1}[/]{0,1}(0[1-9]|[12][0-9]|3[01]){0,1}[/]{0,1}(\d{0,4})/) ?? [];
  return !dateArr[1] ? dateArr[0] : `${dateArr[1]}/${dateArr[2] ?? ""}${dateArr[2] && dateArr[2].length == 2 ? "/" + (dateArr[3] ?? "") : ""}`;
}

export function isPhoneNumberValid(phone?: string): boolean {
  if (!phone) return false;
  return /^[0-9]{10}$/.test(phone);
}

export const objectToQueryString = (params: any) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
export const queryStringToObj = (string: string) => Object.fromEntries(new URLSearchParams(string));
export const scrub = (content: string) => DOMPurify.sanitize(content);
export const scrubHtmlElements = (content: string) => content?.replace(/&[a-zA-Z]*;\s{0,1}/g, "");
export const onlyWords = (content: string) => content?.replace(/[^\w\s]/gi, "");
export const removeNumbersFromErr = (err: string) => err?.replace(/[\d]{4}:/, "");

export const stringToBool = (str: string | boolean | number | undefined) =>
  typeof str === "boolean"
    ? str
    : typeof str === "number"
    ? !!str
    : (str as string)?.toLowerCase() === "true" || (str as string)?.toLowerCase() === "1";

export const groupBy = (items: Array<any>, key: string) =>
  items?.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );
export const createSlug = (str: string) =>
  scrubHtmlElements(str?.toLowerCase())?.trim().replace(/\s/g, "-").replace(/\//g, "-").replace(/\(/, "").replace(/\)/, "");
export const unSlug = (str: string) => str?.replace(/-/g, " ").trim();
export const firstNumberIndex = (str: string) => str.search(/\d/);
export const sizeValue = (str: string): number => {
  if (str.includes("Large")) {
    return 4;
  }
  if (str.includes("Medium")) {
    return 3;
  }
  if (str.includes("Small")) {
    return 2;
  }
  if (str.includes("Individual")) {
    return 1;
  }
  return 0;
};

export const randomlyFillArr = (arr: any[], count: number) => {
  const result: any[] = [];
  for (let i = 1; i <= count; i++) {
    const random = arr[Math.floor(Math.random() * arr.length)];
    if (!result.includes(random)) result.push(arr[Math.floor(Math.random() * arr.length)]);
  }
  return result;
};
export const htmlDecode = (input) => new DOMParser().parseFromString(input, "text/html").documentElement.textContent ?? "";
export const nullOrEmpty = (input?): any | undefined =>
  (isNaN(input) && typeof input != "string") || input == undefined || input == "" || input == " " ? undefined : input;
export const nullOrFirst = (input?) => (input && input instanceof Array && input.length > 0 ? input[0] : input);
export const regexCaptureGroup = (input?) => (input && input instanceof Array && input.length > 0 ? input[1] : input);
export const round5 = (x) => Math.ceil(Math.abs(x) / 5) * 5;

export const phoenixError = (err: string) => {
  // Errors come back in all shapes and sizes, going to have to catch them as we see them.
  if (err.includes("Error trying to validate credit card payment")) return "Error trying to validate credit card payment";
  return (
    err?.replace("Unknown Phoenix error", "")?.includes("{")
      ? regexCaptureGroup(err.match(/[0-9]{4}:([a-zA-Z0-9\s.-]*)/)) ?? nullOrFirst(err.match(/([\w\s]*)/))
      : err
  ).replace(/"/g, "");
};

export const isZip = (s) => /^\d{5}(-\d{4})?$|\d{9}/.test(s);

export const capitalize = (s) => {
  return s[0].toUpperCase() + s.slice(1);
};

export const numberSuffix = (n) => {
  return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
};

export const titleCase = (str) => {
  return str.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
};

export const removeHtmlTags = (str) => {
  if (str === null || str === undefined) {
    return "";
  }
  return str.replace(/<[^>]*>/g, "");
};
