import { ContentBlock, HomePageBlock } from "@/models/contentBlockModel";
import { ActionContext } from "vuex";
import { fetchContentBlockList, fetchPromotions } from "@/services/contentBlockService";
import { objectToArray } from "@/services/arrayService";

export interface ContentBlockState {
  contentBlockList: ContentBlock[];
  homepageBlockList: HomePageBlock[];
}

const contentBlockState: ContentBlockState = {
  contentBlockList: [],
  homepageBlockList: [],
};

export default {
  state: contentBlockState,
  mutations: {
    SET_CONTENT_BLOCK_LIST: (state: ContentBlockState, contentBlockList: ContentBlock[]): void => {
      state.contentBlockList = contentBlockList;
    },
    SET_HOMEPAGE_BLOCKS: (state: ContentBlockState, homepageBlockList: HomePageBlock[]): void => {
      state.homepageBlockList = homepageBlockList;
    },
  },
  actions: {
    setContentBlockList(context: ActionContext<ContentBlockState, any>): void {
      fetchContentBlockList().then((response) => {
        context.commit("SET_CONTENT_BLOCK_LIST", objectToArray(response));
      });
    },
    setHomepageBlockList(context: ActionContext<ContentBlockState, any>): void {
      fetchPromotions().then((response) => {
        context.commit("SET_HOMEPAGE_BLOCKS", objectToArray(response));
        // context.dispatch("setAppInfo", { androidVersion: response?.androidversion, iosVersion: response?.iosversion });
      });
    },
  },
  getters: {
    getContentBlockList: (state: ContentBlockState): ContentBlock[] => {
      return state.contentBlockList;
    },
    getHomepageBlockList: (state: ContentBlockState): HomePageBlock[] => {
      // CacheDate is part of array, only return objects wtih ID's
      return state.homepageBlockList.filter((block) => block.id);
    },
  },
};
