
import { defineComponent, ref, computed } from "vue";
import { IonRow, IonCol, IonButton, IonItem, IonAccordionGroup, IonAccordion, IonLabel, IonIcon } from "@ionic/vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import accountDetail from "@/components/account/accountDetail.vue";
import communicationPref from "@/components/account/communicationPref.vue";
import addressList from "@/components/addressList.vue";
import savedCards from "@/components/savedCards.vue";
import modalWrapper from "@/components/modals/modalWrapper.vue";
import confirmationModal from "./confirmationModal.vue";
import { deleteAccount } from "@/services/accountService";
import orderHistory from "@/components/account/orderHistory.vue";

export default defineComponent({
  props: { iconBtn: Boolean, justModal: Boolean },
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonAccordionGroup,
    IonAccordion,
    IonLabel,
    IonIcon,
    modalWrapper,
    accountDetail,
    addressList,
    savedCards,
    communicationPref,
    confirmationModal,
    orderHistory,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    const modalOpen = ref(props.justModal);
    const accordionGroup = ref();
    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const account = computed(() => store.getters.getAccount);
    const isLoginProcessing = computed(() => store.getters.isLoginProcessing);

    // TODO: fix core issue, and remove need for adding margin to scroll with accordian items in a modal
    const contentStyle = ref("margin-bottom: 200px; height: 100%;");
    const accordionGroupChange = (event) => {
      // on updates to communication preferences, don't reset scrolling margin
      if (event.detail.value == "on") return;
      contentStyle.value = "margin-bottom: 200px";
      if (event.detail.value) {
        contentStyle.value = `margin-bottom: ${event.target.offsetHeight}px`;
      }
    };

    const close = () => {
      modalOpen.value = false;
      emit("close");
    };

    const showDeleteAccountConfirmation = ref(false);
    const acceptDeleteAccount = () => {
      deleteAccount(account.value.customerId);
      store.dispatch("setLogout");
    };
    const declineDeleteAccount = () => {
      console.log("Declined to Delete Account.");
    };
    const closeDeleteAccountConfirmation = () => {
      showDeleteAccountConfirmation.value = false;
    };

    const toolbarLinks = [
      {
        name: "Customer Service",
        click: () => {
          router.push("/customer-service");
          modalOpen.value = false;
        },
      },
      {
        name: "Privacy Policy",
        click: () => {
          router.push("/privacy-policy");
          modalOpen.value = false;
        },
      },
      {
        name: "Sign Out",
        click: () => {
          modalOpen.value = false;
          store.dispatch("setLogout");
        },
      },
      { name: "Delete Account", click: () => (showDeleteAccountConfirmation.value = true) },
    ];

    return {
      modalOpen,
      toolbarLinks,
      accordionGroup,
      isLoggedIn,
      account,
      close,
      isLoginProcessing,
      accordionGroupChange,
      contentStyle,
      showDeleteAccountConfirmation,
      acceptDeleteAccount,
      declineDeleteAccount,
      closeDeleteAccountConfirmation,
    };
  },
});
