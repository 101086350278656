import { StoreStatusEnum } from "@/enums/StoreStatusEnum";
import { Address, Coordinate } from "@/models/addressModels";
import ApiHelper from "@/services/ApiHelper";
import { LocationHours, LocationStatus, SimpleLocation, StoreLocation } from "@/models/locationModels";
import { DateTime } from "luxon";
import { CLOSING_SOON_MINUTES, LOCATION_SEARCH_RADIUS } from "@/constants";
import { NearLocationResponse } from "@/models/NearLocationResponse";
import ValidateDeliveryAddress from "@/models/ValidateDeliveryAddressRes";
import { StatesEnum } from "@/enums/StatesEnum";
import { isZip, onlyWords, removeNumbersFromErr } from "./stringService";
import * as addressParser from "parse-address";
import * as usaStates from "usa-states";

const dateString = (date) => DateTime.fromISO(date).toLocaleString();
const cachedHours = new Map();
let cachedAllLocations: SimpleLocation[];
const cachedLocationDetail = new Map();

function getUsaStateMap() {
  // usa-states info from https://www.npmjs.com/package/usa-states
  const usStates = new usaStates.UsaStates();
  const abbrevs = usStates.arrayOf("abbreviations");
  const names = usStates.arrayOf("names");
  const statesDictionary = {};

  for (let index = 0; index < names.length; index++) {
    statesDictionary[names[index].toLocaleUpperCase()] = 1;
    statesDictionary[abbrevs[index].toLocaleUpperCase()] = 1;
  }
  return statesDictionary;
}
const usaStateMap = getUsaStateMap();

/**
 * Today or yesterday's hours depending on current time
 */
export function todayHours(hours: LocationHours[]): LocationHours | undefined {
  if (!hours) return;

  const now = DateTime.now();
  const today = now.toLocaleString();
  const yesterday = now.minus({ days: 1 }).toLocaleString();

  const todayHours = hours.find((h) => dateString(h.date) == today);
  const yesterdayHours = hours.find((h) => dateString(h.date) == yesterday);

  if (yesterdayHours && now <= yesterdayHours.close) return yesterdayHours;

  return todayHours;
}

export function tomorrowHours(hours: LocationHours[]): LocationHours {
  if (!hours) return new LocationHours();
  const tomorrow = DateTime.now().plus({ days: 1 }).toLocaleString();

  const result = hours.find((hour) => dateString(hour.date) == tomorrow);
  return result ?? new LocationHours();
}

export function nextOpenHours(hours: LocationHours[]): LocationHours | undefined {
  if (!hours) return undefined;
  const result = hours.find((hour) => hour.isOpen);
  return result ?? undefined;
}
/**
 * Takes [offline, isActive, today.open, today.close] into account compared to current time
 */
export function isOpen(status: LocationStatus, hours: LocationHours[]): boolean {
  if (status?.offline !== undefined && status?.offline) return false;
  else if (status?.isActive !== undefined && !status?.isActive) return false;

  const locationTodayHours = todayHours(hours);
  const now = DateTime.now();
  return !!locationTodayHours && now >= locationTodayHours.open && now <= locationTodayHours.close;
}
/**
 * Is location.close within CLOSING_SOON_MINUTES?
 */
export function closingSoon(hours: LocationHours[]): boolean {
  const tHours = todayHours(hours);
  if (!tHours) return false;
  const now = DateTime.now();
  const warningTime = tHours.close.minus({ minutes: CLOSING_SOON_MINUTES });

  return now >= warningTime && now <= tHours.close;
}
/**
 * Is current time between open times and firstWebOrder or lastWebOrder
 */
export function isStoreCurrentlyOpen(hours: LocationHours[]): boolean {
  const locationTodayHours = todayHours(hours);
  const now = DateTime.now();
  return !!locationTodayHours && now >= locationTodayHours.firstWebOrder && now <= locationTodayHours.lastWebOrder;
}
/**
 * @returns [Coming Soon, Open until 1:00am, Reopens at 10:00 am]
 */
export function displayOpenClosedTime(status: LocationStatus, hours: LocationHours[]) {
  const locationTodayHours = todayHours(hours);

  if (status.preopen) {
    return "Coming Soon";
  } else if (locationTodayHours && isOpen(status, hours)) {
    return `Open until ${locationTodayHours.closeTime}`;
  } else if (locationTodayHours && DateTime.now() < locationTodayHours.open) {
    // added one more condition to check store is open or not in that time using BE flag
    return locationTodayHours?.isOpen ? `Reopens at ${locationTodayHours.openTime}` : "Temporarily Closed";
  } else if (!locationTodayHours) {
    return "";
  }

  const nextAvailableHours = nextOpenHours(hours);
  return nextAvailableHours ? `Reopens ${nextAvailableHours.day} at ${nextAvailableHours.openTime}` : "Temporarily Closed";
}
/**
 * Checks various statuses and hours to return status badge message and color
 * @returns StoreStatusEnum {status, color}
 */
export function displayStatus(storeLocation: StoreLocation): StoreStatusEnum {
  if (storeLocation.storeStatus?.preopen) return StoreStatusEnum.comingSoon;
  else if (storeLocation.isTempClosed) return StoreStatusEnum.closed;
  else if (!storeLocation.storeStatus.isActive || storeLocation.storeStatus.offline) return StoreStatusEnum.phoneOnly;
  else if (closingSoon(storeLocation.hours ?? [])) return StoreStatusEnum.closeSoon;
  else if (!storeLocation.displayOrderTypes?.some((o) => o?.isDelivery)) return StoreStatusEnum.eatIn;

  // const open = isOpen(storeLocation.storeStatus, storeLocation.hours ?? []);
  return StoreStatusEnum.open;
}
/**
 * @returns day: name (Friday)
 * @returns times: open -> to close (10:00am - 1:00am)
 * @returns schema: for web crawlers (Sun 10:00am - 1:00am)
 */
export function weeklyHours(storeHours: LocationHours[]): { day: string; times: string; schema: string }[] {
  if (!storeHours) return [];
  return storeHours.map((h) => {
    return {
      day: h.fullDayName,
      times: h.isOpen ? `${h.openTime} - ${h.closeTime}` : "Closed",
      schema: `${h.fullDayName.slice(0, 2)} ${h.openTime24}-${h.closeTime24}`,
    };
  });
}
export const googleDirectionLink = (l: Address | string) =>
  l instanceof Address
    ? `https://www.google.com/maps?saddr&daddr=${l.address1},${l.city},${l.state}+${l.zip}`
    : `https://www.google.com/maps?saddr&daddr=${l}`;
export const closestLocation = (locations: StoreLocation[]) => locations.reduce((min, loc) => (loc.distance < min.distance ? loc : min));
export const stateAbvName = (abrv: string): string => StatesEnum[abrv.toUpperCase()];

//#region API
export const fetchLocationDetail = async (idOrSlug: any): Promise<StoreLocation> => {
  return new Promise((resolve, reject) => {
    if (cachedLocationDetail.has(idOrSlug)) return resolve(cachedLocationDetail.get(idOrSlug));
    if (!idOrSlug || idOrSlug === "0") return reject();
    ApiHelper.get(`/location?locationId=${idOrSlug}`).then((l) => {
      // if multiple results come back, check for specifics; else return 1st result;
      if (Array.isArray(l)) {
        l = l.find((location: StoreLocation) => location.id === idOrSlug || location.slug.toLowerCase() === idOrSlug.toLowerCase()) ?? l[0];
      }
      l.hours = l.hours?.map((h) => new LocationHours(h));
      cachedLocationDetail.set(l.id, new StoreLocation(l));
      return resolve(new StoreLocation(l));
    });
  });
};
export const fetchLocationHours = async (locationId: string): Promise<LocationHours[]> => {
  return new Promise((resolve) => {
    if (cachedHours.has(locationId)) return resolve(cachedHours.get(locationId));
    ApiHelper.get(`/locationhours?locationId=${locationId}`).then((lh) => {
      lh = lh instanceof Array ? lh : Object.values(lh).filter((o) => typeof o !== "string");
      const lhModeled = lh?.map((h) => new LocationHours(h));
      cachedHours.set(locationId, lhModeled);
      resolve(lhModeled);
    });
  });
};
export const fetchLocationStatus = (locationId: string): Promise<LocationStatus> => ApiHelper.get(`/getstatus?locationId=${locationId}`);
export const fetchLocationOrderType = async (locationId: string): Promise<string[]> => ApiHelper.get(`/locationordertype?locationId=${locationId}`);
export const fetchClosestLocationsCoords = (coordinates: Coordinate): Promise<StoreLocation[]> => {
  return new Promise((resolve, reject) => {
    ApiHelper.post(`/getstoresnearcords`, { ...coordinates, distance: LOCATION_SEARCH_RADIUS })
      .then((res) => {
        res
          ? resolve(
              res instanceof Array
                ? res.map((result) => {
                    result.details.hours = result.details.hours?.map((h) => new LocationHours(h));
                    return new StoreLocation({ ...result.details, distance: result.distance });
                  })
                : [new StoreLocation({ ...res.details, distance: res.distance })]
            )
          : reject(res[0]);
      })
      .catch((err) => reject(err));
  });
};

/**
 * Check if Array is valid and length > 0
 * @param arrayToCheck any[]
 * @returns boolean
 */
function isArrayWithValues(arrayToCheck) {
  if (!arrayToCheck || arrayToCheck.length === 0 || !(arrayToCheck instanceof Array)) return false;
  return true;
}

/**
 * Convert a location type array to standard StoreLocation[].
 * Non-array inputs will return an empty array.
 *
 * @param inputArray any
 * @returns StoreLocation[]
 */
function mapToStoreLocationArray(inputArray): StoreLocation[] {
  return !isArrayWithValues(inputArray) ? [] : inputArray.map((location) => new NearLocationResponse(location).asStoreLocation);
}

/**
 * Takes an Address Object or a String value, performs a getStoresNear request.
 * If the user searched by name or id, run the found location through nearCords request to return full results
 *
 * @param address Address | String
 * @returns Promise<StoreLocation[]>
 */
export const fetchClosestLocationsAddress = (address: Address | string): Promise<StoreLocation[]> => {
  return new Promise((resolve, reject) => {
    // Start - setup Address value for getstoresnear request
    const addrTemp: Address = typeof address === "object" ? address : new Address({ address1: address });
    const searchValue: string = addrTemp.address1;
    const mappedAddressForSearch: Address = getMappedAddressForLocSearch(searchValue);

    ApiHelper.post(`/getstoresnear`, {
      ...mappedAddressForSearch,
      distance: LOCATION_SEARCH_RADIUS,
      hasdriveupwindow: false,
      haspartyroom: false,
    })
      .then((response) => {
        const getStoresNearResponse = response;
        // Start Error Handling
        if (typeof getStoresNearResponse === "string") reject(getStoresNearResponse);
        if (getStoresNearResponse instanceof Array && getStoresNearResponse.length > 0 && typeof getStoresNearResponse[0] === "string") {
          reject(getStoresNearResponse[0]);
        } // End Error Handling

        // Setup variables from search results
        const searchResults = getLocArrayFromSearchResults(getStoresNearResponse);
        if (searchResults.error) reject(searchResults.error);
        const isNameSearch = searchResults.fromNameSearch;
        const foundLocations = searchResults.locations;

        if (isNameSearch) {
          const storeCords = new Coordinate({ latitude: foundLocations[0].latitude, longitude: foundLocations[0].longitude });
          fetchClosestLocationsCoords(storeCords)
            .then((res) => resolve(res))
            .catch((error) => reject(error ?? "Error in fetchClosestLocationsAddress"));
        } else {
          foundLocations.length > 0 ? resolve(mapToStoreLocationArray(foundLocations)) : reject(getStoresNearResponse[0]);
        }
      })
      .catch((error) => {
        reject(error ?? "Error occurred in fetchClosestLocationsAddress");
      });
  });

  /**
   *
   * This function is for handling the various return types/contracts from the api.
   * Coord search StoreLocations will either be in a single object OR an array
   * Name search results will be within a StoreSearchResult property and will
   * either be a single object OR an array
   *
   * @param getStoresNearResponse
   * @returns
   */
  function getLocArrayFromSearchResults(getStoresNearResponse) {
    let searchResFromNameSearch = false;
    let errorMessage: string | undefined;
    // Error Handling
    if (!getStoresNearResponse) return { locations: [], fromNameSearch: false, error: "No Results Found" };
    if (typeof getStoresNearResponse === "string") {
      errorMessage = getStoresNearResponse;
    } else if (getStoresNearResponse instanceof Array && typeof getStoresNearResponse[0] === "string") {
      errorMessage = getStoresNearResponse[0];
    }
    if (errorMessage) return { locations: [], fromNameSearch: false, error: errorMessage };

    // If user performed a normal search
    if ("StoreSearchResult" in getStoresNearResponse) {
      const stores: any[] =
        getStoresNearResponse.StoreSearchResult instanceof Array
          ? getStoresNearResponse.StoreSearchResult
          : [getStoresNearResponse.StoreSearchResult];
      return { locations: stores, fromNameSearch: searchResFromNameSearch, error: errorMessage };
    }

    // If user performed a name or id search
    searchResFromNameSearch = true;
    const isSingleLocation = "id" in getStoresNearResponse;
    const isArrayOfLocations = getStoresNearResponse instanceof Array && "id" in (getStoresNearResponse[0] ?? {});

    if (isSingleLocation) return { locations: [getStoresNearResponse], fromNameSearch: searchResFromNameSearch, error: errorMessage };
    if (isArrayOfLocations) return { locations: getStoresNearResponse, fromNameSearch: searchResFromNameSearch, error: errorMessage };
    // If nothing else; fail.
    return { locations: [], fromNameSearch: false, error: "No Results Found" };
  }

  /**
   * Input: searchValue (name, zip, or partial address) -> Output: Address
   * @param searchValue string
   * @returns Address
   */
  function getMappedAddressForLocSearch(searchValue: string) {
    let parsedAddress;
    const strippedValue = onlyWords(searchValue);
    const searchIsZip = isZip(searchValue);

    if (searchIsZip) {
      parsedAddress = { zip: searchValue };
    } else {
      parsedAddress = addressParser.parseLocation(strippedValue);
      parsedAddress.state = determineValidStateFromAddress(strippedValue, parsedAddress.state);
      parsedAddress.zip = isZip(parsedAddress?.zip) ? parsedAddress.zip.replace("-", "") : null;
    }

    // The address parser doesn't do great with shorter strings
    // We'll only use the formatted address if the address is long enough for the
    // parser to do well. Abitrarily setting min length of 3.
    const addressIsTooShort = 3 >= strippedValue.split(" ").length;

    return new Address({
      name: !searchIsZip ? searchValue : undefined,
      address1: !searchIsZip ? strippedValue : undefined,
      city: !searchIsZip ? parsedAddress.city : undefined,
      state: addressIsTooShort || searchIsZip ? undefined : parsedAddress.state,
      zip: parsedAddress.zip ?? undefined,
      type: "business",
    });
  }

  /**
   * Check state abbriviation from parser & searched address.
   * `parse-address` package is prone to fail, this double checks it.
   * @param searchedAddress string
   * @param autoParsedState string
   * @returns string
   */
  function determineValidStateFromAddress(searchedAddress: string, autoParsedState: string) {
    const searchedWords: string[] = onlyWords(searchedAddress).toLocaleUpperCase().split(" ");
    try {
      for (let index = 0; index < searchedWords.length; index++) {
        const potentialStateValue = searchedWords[index];
        const isLastValue = index + 1 === searchedWords.length;
        if (usaStateMap[potentialStateValue]) {
          if (isLastValue) {
            return potentialStateValue;
          } else {
            return isZip(searchedWords[index + 1]) ? potentialStateValue : null;
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
    if (usaStateMap[autoParsedState?.toLocaleUpperCase()] === undefined) return null;
    return autoParsedState;
  }
};

const validateAddressErrorMessage = (err): string | undefined => {
  if (err.message?.includes("0004")) {
    return removeNumbersFromErr(err.message);
  }
  if (err.message?.includes("0011") || !!err[0]) {
    return "Sorry, we could not find that address. Please make sure the address you entered is correct";
  }
  return undefined;
};
export const validateDeliveryAddress = (address: Address): Promise<ValidateDeliveryAddress> => {
  if (address.zip && !address.zipCode) address.zipCode = address.zip;
  return new Promise((resolve, reject) =>
    ApiHelper.post("/validatedeliveryaddress", address).then((v) => {
      const error = validateAddressErrorMessage(v);
      error ? reject(validateAddressErrorMessage(v)) : resolve(new ValidateDeliveryAddress(v));
    })
  );
};

export const fetchAllLocations = (): Promise<SimpleLocation[]> => {
  return new Promise((resolve, reject) => {
    if (cachedAllLocations) return resolve(cachedAllLocations);
    ApiHelper.get(`/alllocations`).then(
      (locations) => {
        const cachedAllLocations = Object.values(locations) as SimpleLocation[];
        resolve(cachedAllLocations);
      },
      (error) => reject(error)
    );
  });
};

// #endregion
