
import { IonApp, IonRouterOutlet, isPlatform } from "@ionic/vue";
import { defineComponent, computed, watch, onMounted, onUnmounted } from "vue";
import { App } from "@capacitor/app";
import {Geolocation} from "@capacitor/geolocation";
import { useStore } from "vuex";
import useVault from "@/services/vaultService";
import useAuth from "@/services/authService";

import { BrowserVault, Vault } from "@ionic-enterprise/identity-vault";
import UniversalHeader from "@/components/layout/universalHeader.vue";
import TabNavigation from "@/components/layout/tabNavigation.vue";
import WebNavDrawer from "./components/layout/webNavDrawer.vue";
import { SplashScreen } from "@capacitor/splash-screen";
import { errorToast } from "@/services/pageService";
import Stellar from "@/components/stellar.vue";
import LocationManager from "@/utils/locationManager";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    UniversalHeader,
    WebNavDrawer,
    TabNavigation,
    // eslint-disable-next-line vue/no-unused-components
    Stellar,
  },
  setup() {
    const store = useStore();
    const { vault, clearVault, unlockVault } = useVault() as {
      vault: Vault | BrowserVault;
      clearVault: any;
      unlockVault: any;
    };

    // No refreshToken when logged into the browser
    const onResume = (event: any) => {
      if (event && event.isActive) isAuthenticated();
    };

    onMounted(() => {
      App.addListener("appStateChange", onResume);
      if (!isNative.value) {
        var donGTM = document.createElement('script');
        donGTM.type = 'text/javascript';
        donGTM.src = '/js/don-gtm.js';

        document.head.appendChild(donGTM);
      }

    });

    onUnmounted(() => {
      App.removeAllListeners();
    });

    const { isAuthenticated } = useAuth();
    vault.onLock(async () => {
      const isEmpty = await vault.isEmpty();
      if (!isEmpty) {
        unlockVault().catch(() => {
          // exampleError: {"extra":[],"code":8,"message":"User canceled auth attempt."}
          store.dispatch("setLogout", "/").then(() => errorToast("Authentication failed. You've been logged out."));
          clearVault();
        });
      }
    });
    // }

    store.dispatch("setDevice");
    store.dispatch("initUserData");
    store.dispatch("setContentBlockList");

    onMounted(() => SplashScreen.hide({ fadeOutDuration: 1000 }));
    //@ts-ignore
    onMounted(() => (window.prerenderReady = true));

    const activePageAlert = computed(() => store.getters.activePageAlert);
    watch(activePageAlert, (callback) => callback && callback());

    const isNative = computed(() => store.getters.isNative);

    // Old Dynatrace Injection - Web Only
    const dynaScript = document.createElement("script");
    dynaScript.setAttribute("src", process.env.VUE_APP_OLD_DYNATRACE_URL);
    dynaScript.setAttribute("crossorigin", "anonymous");
    if (!isNative.value && !isPlatform("hybrid") && process.env.NODE_ENV != "localhost") document.head.appendChild(dynaScript);
    // END Dynatrace JS Injection

    //
    //  New Dynatrace JS Injection - Native + Web
    //  This should be handled by the @dynatrace/cordova-plugin package.
    //  03.10.23 - Dynatrace is looking into a fix for us, so we do not need to manually inject the js

    // const dynaScript = document.createElement("script");
    // dynaScript.setAttribute("src", process.env.VUE_APP_DYNATRACE_URL);
    // dynaScript.setAttribute("crossorigin", "anonymous");
    // if (isNative.value || isPlatform("hybrid") || process.env.NODE_ENV == "production") document.head.appendChild(dynaScript);

    // END Dynatrace JS Injection
    //

    window.addEventListener("resize", () => store.dispatch("setDevice"));
    return { isNative };
  },
});
