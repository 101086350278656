
import { defineComponent, PropType } from "vue";
import { IonCardSubtitle } from "@ionic/vue";
import dynamicSkeleText from "@/components/dynamicSkeleText.vue";
import { skeleText } from "@/models/props/skeleText";
import { Address } from "@/models/addressModels";

export default defineComponent({
  props: { address: { type: Object as PropType<Address> }, inline: Boolean, dense: Boolean, showZip: Boolean, large: Boolean },
  components: { IonCardSubtitle, dynamicSkeleText },
  setup(props) {
    const skeleText: skeleText[] = [
      { width: 60 },
      { width: 20, display: "inline-block", marginRight: "5px" },
      { width: 5, display: "inline-block", marginRight: "5px" },
      { width: 15, display: "inline-block", marginRight: "5px" },
    ];
    if (props.inline) skeleText.shift();

    return { skeleText };
  },
});
