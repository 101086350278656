import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "is-open": _ctx.open,
    onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close', $event))),
    canDismiss: _ctx.canDismiss,
    backdropDismiss: _ctx.backdropDismiss,
    style: _normalizeStyle({ '--min-width': _ctx.minWidth, '--min-height': _ctx.minHeight, '--max-height': _ctx.maxHeight, '--max-width': _ctx.maxWidth })
  }, {
    default: _withCtx(() => [
      (!_ctx.hideToolbar)
        ? (_openBlock(), _createBlock(_component_ion_toolbar, {
            key: 0,
            class: "modal-toolbar"
          }, {
            default: _withCtx(() => [
              (_ctx.canDismiss)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    slot: "end",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close', $event))),
                    fill: "clear"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        color: _ctx.isHeaderActive ? 'light' : 'medium',
                        icon: _ctx.closeOutline
                      }, null, 8, ["color", "icon"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_title, {
                mode: "ios",
                class: _normalizeClass({ active: _ctx.isHeaderActive }),
                innerHTML: _ctx.title
              }, null, 8, ["class", "innerHTML"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }),
      _createVNode(_component_ion_footer, { mode: "ios" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["is-open", "canDismiss", "backdropDismiss", "style"]))
}