import { RouteRecordRaw } from "vue-router";
import { MOBILE_BREAKPOINT } from "@/constants";

const menuRoutes: Array<RouteRecordRaw> = [
  {
    path: "/menu",
    name: "Menu",
    meta: {
      title: "Order now",
      description: "",
      hideTabNavigation: true,
      backBtn: true,
      backText: "Back",
      orderingRoute: true,
    },
    component: () => import("@/views/menu/menuPage.vue"),
    beforeEnter(to, __, next) {
      window.innerWidth > MOBILE_BREAKPOINT ? next({ path: "/menu/pizza", query: to.query }) : next();
    },
  },
  {
    path: "/menu/:category",
    name: "Menu Category",
    meta: {
      title: "Category",
      description: "",
      hideTabNavigation: true,
      backBtn: true,
      backText: "Menu",
      orderingRoute: true,
    },
    component: () => import("@/views/menu/menuCategoryPage.vue"),
    props: true,
  },
  {
    path: "/menu/:category/:itemName",
    name: "Menu Item",
    meta: {
      title: "Menu Item",
      description: "",
      hideTabNavigation: true,
      backBtn: true,
      backText: "Back",
      orderingRoute: true,
    },
    component: () => import("@/views/menu/menuItemPage.vue"),
    props: true,
  },
  {
    path: "/menu/item/:category/:itemName",
    redirect(to) {
      return { path: to.fullPath.replace("/item", "") };
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    meta: {
      title: "Checkout",
      description: "",
      hideTabNavigation: true,
      index: "noindex",
      orderingRoute: true,
    },
    component: () => import("@/views/checkoutPage.vue"),
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    meta: {
      title: "Order Complete!",
      description: "",
      hideTabNavigation: true,
      index: "noindex",
      orderingRoute: true,
    },
    component: () => import("@/views/confirmationPage.vue"),
  },
  {
    path: "/confirmation/:id",
    name: "ConfirmationId",
    meta: {
      title: "Order Complete!",
      description: "",
      hideTabNavigation: true,
      index: "noindex",
      orderingRoute: true,
    },
    props: true,
    component: () => import("@/views/confirmationPage.vue"),
  },
  {
    path: "/cart",
    name: "Cart",
    meta: {
      title: "Cart",
      description: "",
      hideTabNavigation: true,
      index: "noindex",
      orderingRoute: true,
    },
    component: () => import("@/views/cartPage.vue"),
  },
  {
    path: "/cart/:lineItemId",
    name: "Edit Cart Item",
    meta: {
      title: "Edit Cart Item",
      description: "",
      hideTabNavigation: true,
      index: "noindex",
      orderingRoute: true,
    },
    component: () => import("@/views/menu/menuItemPage.vue"),
    props: true,
  },
  {
    path: "/menu/lunch",
    name: "lunch Combos",
    component: () => import("@/views/menu/lunchCombos.vue"),
    meta: {
      title: "lunch Combos",
      description: "",
    },
  },
];

export default menuRoutes;
