import { RouteRecordRaw } from "vue-router";

const locationRoutes: Array<RouteRecordRaw> = [
  {
    path: "/location",
    name: "Locations",
    meta: {
      title: "Find a nearby Donatos",
      description: "",
      hideTabNavigation: true,
    },
    component: () => import("@/views/locations/locationsPage.vue"),
    props: true,
    children: [
      {
        path: "/location/:slug",
        name: "Location Detail",
        component: () => import("@/views/blankRoute.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/all-locations",
    name: "All Locations",
    component: () => import("@/views/locations/allLocationsPage.vue"),
  },
  {
    path: "/locations",
    redirect: "/location",
  },
  {
    path: "/locations/all",
    redirect: "/all-locations",
  },
  {
    path: "/locations/:slug",
    redirect: (to) => {
      return { path: `/location/${to.params.slug}` };
    },
  },
  {
    path: "/redrobin",
    name: "redRobin",
    component: () => import("@/views/locations/redRobinJumpPage.vue"),
  },
];

export default locationRoutes;
