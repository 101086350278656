
import { defineComponent, computed, } from "vue";
import { useRouter } from "vue-router";
import { IonHeader, IonToolbar, IonButtons, IonTitle, IonImg, IonBackButton } from "@ionic/vue";
import { homeOutline } from "ionicons/icons";
import CartBtn from "@/components/cartBtn.vue";

export default defineComponent({
  components: { IonHeader, IonToolbar, IonButtons, CartBtn, IonTitle, IonImg, IonBackButton },
  props: {},
  computed: {
    prevRoute() {
      const arr = this.$router.currentRoute.value.path.split("/");
      return arr.splice(0, arr.length - 1).join("/");
    },
    titleRoute() {
      return "/";
      // 08/10/22
      // setting the logo on native to route /home for now
      // keeping the old routing commented, incase we need to swtich it back.
      // return this.$route.meta?.orderingRoute ? "/menu" : "/location";
    },
  },
  setup() {
    const router = useRouter();
    const backBtn = computed(() => router.currentRoute.value.meta?.backBtn);
    const backText = computed(() => router.currentRoute.value.meta?.backText);
    return { homeOutline, backBtn, backText };
  },
});
