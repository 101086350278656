import { ref, computed, nextTick, watch } from "vue";

import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default function useShowRewards() {
  const store = useStore();
  const route = useRoute();

  const isLoggedIn = computed(() => store.getters.isLoggedIn);
  const account = computed(() => store.getters.getAccount);
  const rewardTabAvailable = computed(() => isLoggedIn.value && account.value?.rewardsMemberId && !account.value?.isVisitor);
  const isLoyaltyMember = computed(() => isLoggedIn.value && account.value?.rewardsMemberId && !account.value?.isVisitor);
  const isLoyaltyVisitor = computed(() => account.value?.isVisitor);
  const selectRewardsTab = ref(rewardTabAvailable.value && !route.path.includes('deals'));

  const shouldDisplay = () => (selectRewardsTab.value = rewardTabAvailable.value && !route.path.includes('deals') && !route.path.includes("offers"));
  nextTick(() => shouldDisplay());
  watch(rewardTabAvailable, () => shouldDisplay());

  return { isLoggedIn, account, selectRewardsTab, rewardTabAvailable, isLoyaltyVisitor, isLoyaltyMember };
}
