
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { IonContent, IonModal, IonTitle, IonIcon, IonToolbar, IonButton, IonFooter } from "@ionic/vue";
import { closeOutline } from "ionicons/icons";

export default defineComponent({
  props: {
    title: String,
    open: Boolean,
    hideToolbar: Boolean,
    isHeaderActive: { default: false },
    minHeight: { default: "85vh" },
    minWidth: { default: "35vw" },
    maxHeight: { default: "unset" },
    maxWidth: { default: "unset" },
    height: { default: "45vh" },
    width: { default: "unset" },
    canDismiss: { default: true },
    backdropDismiss: { default: true },
  },
  components: {
    IonContent,
    IonModal,
    IonTitle,
    IonIcon,
    IonToolbar,
    IonButton,
    IonFooter,
  },
  emits: ["confirm", "close", "dismiss"],

  setup() {
    const store = useStore();
    const isPortrait = computed(() => {
      return store.getters.isPortraitOrientation;
    });
    return { closeOutline, isPortrait };
  },
});
