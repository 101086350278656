import { computed } from "vue";
import router from "@/router";
import { fetchLocationDetail } from "@/services/locationService";
import { useStore } from "vuex";
import { errorToast } from "@/services/pageService";
import { COUPON_QUERY_PARAM, CODE_QUERY_PARAM, LOCATION_ID_QUERY_PARAM, LOCATION_QUERY_PARAM } from "@/constants";
import { checkCouponCode } from "@/services/orderService";

export function useUrlQueryParser() {
  const queryParams = computed(() => router.currentRoute.value.query ?? {});
  const store = useStore();

  /**
   * set location & coupon from url query params
   */
  const handleQueries = () => {
    const locationIdorSlug = getLocationSlugOrId();
    if (locationIdorSlug) {
      fetchLocationDetail(locationIdorSlug).then((location) => {
        store.dispatch("updateSelectedLocation", location.id).catch((error) => errorToast(error));
      });
    }

    const couponCode = getQueriedCouponCode();
    if (couponCode) {
      // Don't display coupon success/error messaging, since we don't know if the user is done building thier cart
      checkCouponCode(couponCode as string).then((coupon) => store.dispatch("addCoupon", coupon));
    }
  };

  /**
   * get location id or slug from query param `?location_id` || `?location`
   */
  const getLocationSlugOrId = (): string | undefined => {
    if (queryParams?.value[LOCATION_ID_QUERY_PARAM]) {
      return queryParams?.value[LOCATION_ID_QUERY_PARAM]?.toString();
    } else {
      return queryParams?.value[LOCATION_QUERY_PARAM]?.toString();
    }
  };

  /**
   * get coupon code or alias from query param `?coupon` || `?code`
   */
  const getQueriedCouponCode = (): string | undefined => {
    if (queryParams?.value[COUPON_QUERY_PARAM]) {
      return queryParams?.value[COUPON_QUERY_PARAM]?.toString();
    } else {
      return queryParams?.value[CODE_QUERY_PARAM]?.toString();
    }
  };

  return {
    handleQueries,
    getLocationSlugOrId,
    getQueriedCouponCode,
  };
}
