import { nullOrEmpty, stringToBool } from "@/services/stringService";

export default class UserProfile {
  customerId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  zipCode: string;
  glutenFree: number;
  vegan: number;
  vegetarian: number;
  loyaltyOptInCreateMember: number;
  favoriteStore: number;
  rewardsMemberId?: string;
  birthdate: string;
  isVisitor: boolean | string; // is '0' or '1';

  constructor(res?: Partial<UserProfile>) {
    this.customerId = res?.customerId ?? "";
    this.firstName = res?.firstName ?? "";
    this.lastName = res?.lastName ?? "";
    this.emailAddress = res?.emailAddress ?? "";

    this.phoneNumber = res?.phoneNumber ?? "";
    this.birthdate = res?.birthdate ?? "";

    // @ts-ignore
    this.zipCode = res?.zipCode ?? res?.customerZip ?? "";
    this.glutenFree = res?.glutenFree ?? 0;
    this.vegan = res?.vegan ?? 0;
    this.vegetarian = res?.vegan ?? 0;
    this.loyaltyOptInCreateMember = res?.loyaltyOptInCreateMember ?? 0;
    this.favoriteStore = res?.favoriteStore ?? 0;
    // @ts-ignore
    this.rewardsMemberId = nullOrEmpty(res?.rewardsMemberId ?? res?.cheetahMemberSso?.rewardsMemberId);
    this.isVisitor = res?.isVisitor ? stringToBool(res?.isVisitor) : true;
  }
}
