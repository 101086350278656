import { RouteRecordRaw } from "vue-router";

const cateringRoutes: Array<RouteRecordRaw> = [
  {
    path: "/catering",
    name: "Catering",
    meta: {
      description: "",
    },
    component: () => import("@/views/catering/cateringPage.vue"),
  },
];

export default cateringRoutes;
