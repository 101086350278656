import { RouteRecordRaw } from "vue-router";

const accountRoutes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "Login",
      description: "",
    },
    component: () => import("@/views/account/loginPage.vue"),
  },
  {
    path: "/signup",
    name: "Sign up",
    meta: {
      title: "Sign up",
      description: "",
    },
    component: () => import("@/views/account/signUpPage.vue"),
  },
  {
    path: "/account/deals",
    redirect: "/deals"
  },
  {
    path: "/account/offers",
    redirect: "/deals",
  },
  {
    path: "/offers",
    redirect: "/deals",
  },
  {
    path: "/account/rewards",
    redirect: "/rewards",
  },
  {
    path: "/deals",
    name: "Deals",
    meta: {
      title: "Deals",
      description: "",
    },
    component: () => import("@/views/account/rewardPage.vue"),
  },
  {
    path: "/rewards",
    meta: {
      title: "My Rewards",
    },
    component: () => import("@/views/account/rewardPage.vue"),
  },
  {
    path: "/rewards/menu",
    meta: {
      title: "Rewards Menu",
    },
    component: () => import("@/views/account/rewardMenuPage.vue"),
  },
  {
    path: "/rewards/bonus-points",
    meta: {
      title: "Bonus Points",
    },
    component: () => import("@/views/account/bonusPointsPage.vue"),
  },
  {
    path: "/rewards/signup",
    meta: {
      title: "Rewards Signup",
    },
    component: () => import("@/views/account/rewardsSignUpForm.vue"),
  },
  {
    path: "/history",
    name: "history",
    meta: {
      title: "Account History",
    },
    props: true,
    component: () => import("@/views/account/historyPage.vue"),
  },
];

export default accountRoutes;
