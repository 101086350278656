
import { defineComponent, ref, PropType, watch, computed } from "vue";
import { IonGrid, IonItem, IonInput, IonLabel, IonButton, IonCheckbox, IonRow, IonText, IonCol, IonNote, IonSpinner } from "@ionic/vue";
import name from "@/components/inputs/name.vue";
import zip from "@/components/inputs/zip.vue";
import { CustomerAddress } from "@/models/addressModels";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    initialValues: {
      type: Object as PropType<CustomerAddress>,
      required: false,
    },
    submitBtnText: {
      type: String,
      default: "Submit",
    },
    submitFunction: {
      type: Function,
      required: true,
    },
    submissionError: { type: String || undefined },
    showname: Boolean,
    showDefaultCheck: Boolean,
    devalueAction: Boolean,
  },
  components: { IonGrid, IonItem, IonInput, IonLabel, IonRow, IonCol, IonText, IonCheckbox, IonNote, name, zip, IonButton, IonSpinner },
  emits: ["submit", "updatingAddress"],
  setup(props, { emit }) {
    const store = useStore();
    const submitting = ref(false);
    const loading = ref(false);
    const schema = yup.object({
      addressId: yup.string(),
      nickname: yup.string().label("Address Name").max(45),
      address1: yup.string().required().label("Street address").max(45),
      address2: yup.string().label("Address 2").max(45),
      city: yup.string().required().label("City").max(45),
      state: yup.string().required().label("State").min(2).max(2),
      zip: yup.string().required().label("Zip Code").min(5).max(5),
      deliveryInstructions: yup.string().label("Instructions").max(80),
      isFavorite: yup.boolean(),
    });
    const isMobile = computed(() => store.getters.isMobile);

    const form = useForm({
      validationSchema: schema,
      //@ts-ignore
      initialValues: props.initialValues,
    });
    const { value: nickname, errorMessage: nicknameError } = useField<string | undefined>("nickname");
    const { value: address1, errorMessage: addressError } = useField<string | undefined>("address1");
    const { value: address2, errorMessage: address2Error } = useField<string | undefined>("address2");
    const { value: city, errorMessage: cityError } = useField<string | undefined>("city");
    const { value: state, errorMessage: stateError } = useField<string | undefined>("state");
    const { value: zip, errorMessage: zipError } = useField<string | undefined>("zip");
    const { value: deliveryInstructions, errorMessage: instructionsError } = useField<string | undefined>("deliveryInstructions");
    const { value: isFavorite } = useField<boolean | undefined>("isFavorite");

    const onSubmit = form.handleSubmit((values) => {
      submitting.value = true;
      loading.value = submitting.value;
      values.addressId = props.initialValues?.addressId ?? undefined;
      return props.submitFunction(new CustomerAddress(values)).finally(() => (submitting.value = false));
    });
    watch(
      () => props.submissionError,
      () => {
        loading.value = false;
      }
    );

    watch(
      () => props.initialValues,
      (customerAddress) => {
        form.setValues({
          address1: customerAddress?.address1,
          address2: customerAddress?.address2,
          city: customerAddress?.city,
          state: customerAddress?.state,
          zip: customerAddress?.zip,

          nickname: customerAddress?.nickname,
          deliveryInstructions: customerAddress?.deliveryInstructions,
          isFavorite: customerAddress?.isFavorite,
        });
      }
    );

    watch(
      () => form.values.address1,
      () => emit("updatingAddress")
    );

    return {
      loading,
      onSubmit,
      nickname,
      address1,
      address2,
      city,
      state,
      zip,
      deliveryInstructions,
      isFavorite,
      addressError,
      cityError,
      stateError,
      zipError,
      form,
      submitting,
      isMobile,
      nicknameError,
      address2Error,
      instructionsError,
    };
  },
});
