import ApiHelper from "@/services/ApiHelper";

//#region API
export const fetchContentBlockList = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiHelper.get(`/contentblock`)
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

export const fetchPromotions = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiHelper.get(`/promotions`)
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

// #endregion
