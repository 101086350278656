
import useContentBlock from "@/composables/useContentBlock";
export default {
  props: {
    slug: { type: String, required: true },
    backupText: String,
    backupSlot: Boolean,
  },
  setup(props) {
    const { selectedBlock, canDisplayBlock, contentBlocks } = useContentBlock(props.slug);
    return {
      contentBlocks,
      selectedBlock,
      canDisplayBlock,
    };
  },
};
