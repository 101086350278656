/* eslint-disable */
import axios from "axios";
import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";
import useAuth from "@/services/authService";
const { isAuthenticated, getAccessToken, getIdToken } = useAuth();

let headers = { apikey: process.env.VUE_APP_API_KEY, devicetype: "web" };
const axiosRequestConfig = { withCredentials: true };

Device.getInfo().then((device) => {
  if (device.platform == "web") {
    headers.devicetype = ["ios", "android"].includes(device.operatingSystem) ? "mobile" : "web";
  } else {
    headers.devicetype = device.platform;
    App.getInfo().then((info) => {
      headers.appVersion = info.version;
    });
  }
});

const addAuthHeader = async () => {
  const request = { headers: headers };
  if (await isAuthenticated()) {
    request.headers.Authorization = `Bearer ${await getAccessToken()}`;
    request.headers.idToken = (await getIdToken())?.id_token;
  }
  return Promise.resolve(request);
};

const parseError = (error) => {
  if (error.response) {
    return typeof error.response.data === "object"
        ? error.response.data.title || error.response.data.message || JSON.stringify(error.response.data)
        : error.response.data;
  }
  return "Error occurred without a response object";
};


export default {
  async get(path) {
    return axios
      .get(process.env.VUE_APP_API_URL + path, await addAuthHeader(), axiosRequestConfig)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(parseError(error)));
  },
  async getLoyaltyApi(path) {
    return axios
      .get(process.env.VUE_APP_LOYALTY_API_URL + path, await addAuthHeader(), axiosRequestConfig)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(parseError(error)));
  },
  async post(path, data) {
    return axios
      .post(process.env.VUE_APP_API_URL + path, data, await addAuthHeader(), axiosRequestConfig)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(parseError(error)));
  },
  async patch(path, data) {
    return axios
      .patch(process.env.VUE_APP_API_URL + path, data, await addAuthHeader(), axiosRequestConfig)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(parseError(error)));
  },
  async delete(path, data) {
    return axios
      .delete(process.env.VUE_APP_API_URL + path, await addAuthHeader(), axiosRequestConfig)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(parseError(error)));
  },
};
