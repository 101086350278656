export function useGoogleAnalytics() {
  const gaIsUndefined = () => {
    // @ts-ignore
    return typeof gtag === "undefined";
  };

  // START: ORDER SUBMISSION
  /**
   * Google Analytics: Track key order data when order is placed
   *
   * {EventCategory: "Purchase", EventAction: "Complete"}
   * @param id orderHeaderId
   * @param tax tax
   * @param total grandTotal
   * @returns void
   */
  const trackOrderPlacement = (id, tax, total) => {
    if (gaIsUndefined()) return;
    const gaPurchaseData = {
      id: id,
      tax: tax,
      revenue: total,
    };
    // @ts-ignore
    gtag("event", "purchase", {
      event_category: "Purchase",
      purchase_data: gaPurchaseData,
    });
    // @ts-ignore
    gtag("event", "purchase_complete", {
      event_category: "Purchase",
      event_label: id,
    });
  };

  /**
   * Google Analytics: Track order placement failure
   *
   * {EventCategory: "Order Form", EventAction: "Form Submit Error"}
   * @param error string: Error Message
   * @returns void
   */
  const trackOrderPlacementError = (error) => {
    if (gaIsUndefined()) return;
    // @ts-ignore
    gtag("event", "form_submit_error", {
      event_category: "Order Form",
      event_label: error,
    });
  };

  /**
   * Google Analytics: Track each product on a submitted order
   * @param id itemId
   * @param name item display name
   * @param price Single item price
   * @param qty Quantity on order
   * @returns void
   */
  const trackProductPurchase = (id, name, price, qty) => {
    if (gaIsUndefined()) return;
    const gaPurchaseData = {
      id: id,
      name: name,
      price: price,
      quantity: qty,
    };
    // @ts-ignore
    gtag("event", "add_product", gaPurchaseData);
  };

  /**
   * Google Analytics: Track coupons added to a submitted order
   * @param couponCode
   * @param name
   * @returns void
   */
  const trackCouponPurchase = (couponCode, name) => {
    if (gaIsUndefined()) return;

    const gaPurchaseData = {
      id: `Coupon_${couponCode}`,
      name: name,
      category: "Coupon",
      price: "0.00",
    };
    // @ts-ignore
    gtag("event", "add_coupon", gaPurchaseData);
  };
  // END: ORDER SUBMISSION

  //
  // START: CART
  /**
   * Google Analytics: Track item add to cart
   *
   * {EventCategory: "Menu", EventAction: "Add to Cart"}
   * @param itemName
   * @returns void
   */
  const trackAddItem = (itemName) => {
    if (gaIsUndefined()) return;
    // @ts-ignore
    gtag("event", "add_to_cart", {
      event_category: "Menu",
      event_label: itemName,
    });
  };

  /**
   * Google Analytics: Track coupon code success/failure rate (add to order)
   *
   * @param couponCode string
   * @param success
   * @returns void
   */
  const trackAddCoupon = (couponCode: string, success: boolean) => {
    if (gaIsUndefined()) return;
    const eventCategory = success ? "Coupons" : "Coupon Errors";
    const eventAction = success ? "successful_entry" : "unsuccessful_entry";
    // @ts-ignore
    gtag("event", eventAction, {
      event_category: eventCategory,
      event_label: couponCode,
    });
  };

  /**
   * Google Analytics: Track coupon code removal (cart)
   *
   * @param couponCode string
   * @returns void
   */
  const trackRemoveCoupon = (couponCode: string) => {
    if (gaIsUndefined()) return;
    // @ts-ignore
    gtag("event", "remove_coupon", {
      event_category: "Coupons",
      event_label: couponCode,
    });
  };

  /**
   * Google Analytics: Track item removed from cart
   *
   * {EventCategory: "Cart", EventAction: "Remove Item"}
   * @param itemName
   * @returns void
   */
  const trackRemoveItem = (itemName) => {
    if (gaIsUndefined()) return;
    // @ts-ignore
    gtag("event", "remove_item", {
      event_category: "Cart",
      event_label: itemName,
    });
  };
  //END: CART

  //
  // START: USER DATA
  /**
   * Google Analytics: Set `userId` & `dimension1` for tracking session
   * @param userId string
   * @returns void
   */
  const trackUserId = (userId) => {
    if (gaIsUndefined()) return;
    // @ts-ignore
    gtag("config", "G-9GRE9TCGX5", {
      user_id: userId,
      dimension1: userId,
    });
  };

  return {
    trackOrderPlacement,
    trackOrderPlacementError,
    trackProductPurchase,
    trackCouponPurchase,
    trackAddItem,
    trackAddCoupon,
    trackRemoveCoupon,
    trackRemoveItem,
    trackUserId,
  };
}
