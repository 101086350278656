import { stringToBool, nullOrEmpty } from "@/services/stringService";
import Phone from "./Phone";
import { DateTime } from "luxon";

export class plainAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  constructor(a?: Partial<plainAddress>) {
    /** Address can sometimes come through as a nested value (address). Also, handle zip or zipcode */
    //@ts-ignore
    this.address1 = a?.address?.address1 ?? a?.address1 ?? "";
    //@ts-ignore
    this.address2 = a?.address?.address2 ?? a?.address2 ?? "";
    //@ts-ignore
    this.city = a?.address?.city ?? a?.city ?? "";
    //@ts-ignore
    this.state = a?.address?.state ?? a?.state ?? "";
    //@ts-ignore
    this.zip = a?.address?.zip ?? a?.address?.zipcode ?? a?.zip ?? a?.zipcode ?? a?.zipCode ?? "";
  }
}
export class Address extends plainAddress {
  zipCode?: string;
  zipcode?: string;
  type: string;

  name: string;
  instructions: string;
  deliveryInstructions: string;
  default: boolean;
  id: string;
  addressId: string;
  deliveryGrid?: string;
  latitude?: string;
  longitude?: string;

  constructor(a?: Partial<Address>) {
    super(a);
    // @ts-ignore
    this.zipCode = a?.address?.zip ?? a?.address?.zipcode ?? a?.zip ?? a?.zipCode ?? "";
    this.deliveryGrid = a?.deliveryGrid ?? "";
    // @ts-ignore
    this.latitude = a?.coordinate?.latitude ?? a?.latitude ?? "";
    // @ts-ignore
    this.longitude = a?.coordinate?.longitude ?? a?.longitude ?? "";
    // this.coordinate = { latitude: +(this.latitude ?? 1), longitude: +(this.longitude ?? 2) };
    this.name = a?.name ?? "";
    this.instructions = a?.instructions ?? a?.deliveryInstructions ?? "";
    this.deliveryInstructions = a?.instructions ?? a?.deliveryInstructions ?? "";
    this.default = a?.default ?? false;
    this.type = a?.type ?? "house";

    this.id = a?.addressId ?? a?.id ?? "";
    this.addressId = a?.addressId ?? a?.id ?? "";
  }

  get asString() {
    return `${this.address1} ${this.address2 ? this.address2 + " " : ""}, ${this.city}, ${this.state}`;
  }
}

export class Coordinate {
  latitude: number;
  longitude: number;
  constructor(data?: Partial<Coordinate>) {
    this.latitude = data?.latitude ?? 0;
    this.longitude = data?.longitude ?? 0;
  }
}
/**
  - storeNumber: string;
  - name: string;
  - address: Address;
  - phone: Phone;
  - coordinate: Coordinate;
  - deliveryGrid: string;
  - deliveryInstructions: string;
  - addressType: string;
  - customerId: string;
  - default: boolean;
 */
export class DeliveryAddress {
  // todo -> DeliveryInfo
  storeNumber?: string;
  name?: string;
  address?: Address;
  phone?: Phone | string;
  coordinate?: Coordinate;
  grid?: string;
  instructions?: string;
  addressType?: string;
  customerId?: string;
  default?: boolean;
  tip?: number;
  date?: string;
  time?: string;
  isImmediate?: boolean;
  isContactlessDelivery?: boolean;
  deliveryToAddress?: Address;
  id?: string;
  deliveryCharge?: number;

  constructor(data?: Partial<DeliveryAddress>) {
    //@ts-ignore
    this.storeNumber = data?.deliveryToAddress?.storeNumber ?? data?.storeNumber;
    //@ts-ignore
    this.name = data?.deliveryToAddress?.name ?? data?.name ?? "";
    //@ts-ignore
    this.address = new Address(data?.deliveryToAddress?.address ?? data?.address ?? new plainAddress());
    //@ts-ignore
    this.phone = nullOrEmpty(data?.deliveryToAddress?.phone) ?? nullOrEmpty(data?.phone) ?? new Phone();
    //@ts-ignore
    this.coordinate = nullOrEmpty(data?.deliveryToAddress?.coordinate) ?? nullOrEmpty(data?.coordinate) ?? new Coordinate(this);
    //@ts-ignore
    this.grid = data?.deliveryToAddress?.grid ?? data?.grid ?? "";
    //@ts-ignore
    this.instructions = data?.deliveryToAddress?.instructions ?? data?.instructions ?? "";
    //@ts-ignore
    this.addressType = data?.deliveryToAddress?.addressType ?? data?.addressType ?? "";
    //@ts-ignore
    this.customerId = data?.deliveryToAddress?.customerId ?? data?.customerId;
    this.tip = data?.tip;
    //@ts-ignore
    this.default = stringToBool(data?.deliveryToAddress?.customerId ?? data?.default ?? data?.defaultAddress) ?? false;
    //@ts-ignore
    this.date = data?.date ?? DateTime.now().toFormat("yyyy-LL-dd");
    //@ts-ignore
    this.time = data?.time ?? DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE);
    this.isImmediate = data?.isImmediate ?? true;
    this.isContactlessDelivery = data?.isContactlessDelivery ?? false;
    this.deliveryToAddress = new Address(this.address);
    this.id = this.address?.id ?? "";
    //@ts-ignore
    this.type = "";
    this.deliveryCharge = data?.deliveryCharge ?? 0;
  }
  get addressId() {
    return this.id;
  }
}

export class CustomerAddress {
  addressId: string;
  customerId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  zipCode?: string;
  storeNumber: string;
  deliveryInstructions: string;
  businessName: string;
  addressType: string;
  nickname: string;
  isFavorite: boolean;

  constructor(data: Partial<CustomerAddress>) {
    //@ts-ignore
    this.addressId = nullOrEmpty(data.addressId) ?? nullOrEmpty(data.favoriteAddressId) ?? "";
    this.customerId = nullOrEmpty(data.customerId) ?? "";
    this.address1 = data.address1 ?? "";
    this.address2 = data.address2 ?? "";
    this.city = data.city ?? "";
    this.state = data.state ?? "";
    this.zip = data.zip ?? data?.zipCode ?? "";
    this.zipCode = data?.zipCode ?? data.zip ?? "";
    this.storeNumber = data.storeNumber ?? "";
    this.deliveryInstructions = data.deliveryInstructions ?? "";
    this.businessName = nullOrEmpty(data.businessName) ?? "";
    this.addressType = nullOrEmpty(data.addressType) ?? 1;
    this.nickname = data.nickname ?? "";
    this.isFavorite = stringToBool(data.isFavorite) ?? false;
  }

  get asDelete() {
    return new DeleteAddress({
      addressId: this.addressId ?? "",
      customerId: this.customerId ?? "",
    });
  }
  get default() {
    return this.isFavorite;
  }
  get name() {
    return this.nickname;
  }
  get address() {
    return this;
  }
  get zipcode() {
    return this.zip;
  }
}

export class StoreOrderType {
  id: number;
  description: string;
  instore: boolean;
  constructor() {
    this.id = 0;
    this.description = "";
    this.instore = false;
  }
}
export class PickupAddress {
  id: number;
  storeNumber: number;
  storeName: string;
  address: Address;
  phone: Phone;
  orderTypes: StoreOrderType[];
  constructor() {
    this.id = 0;
    this.storeNumber = 0;
    this.storeName = "";
    this.address = new Address();
    this.phone = new Phone();
    this.orderTypes = [];
  }
}
export class DeleteAddress {
  addressId: string;
  customerId: string;
  newFavoriteAddressId?: string;

  constructor(data: DeleteAddress) {
    this.addressId = data.addressId ?? "";
    this.customerId = data.customerId ?? "";
    this.newFavoriteAddressId = data.newFavoriteAddressId ?? "0";
  }
}
