
import { IonInput, IonItem, IonLabel, IonText } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    label: String,
    required: {
      type: Boolean,
      default: true,
    },
    modelValue: String,
    name: String,
    labelColor: String,
    dense: Boolean,
    stacked: Boolean,
    mode: String,
  },

  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  components: { IonInput, IonLabel, IonItem, IonText },
});
