
import { IonButton, IonChip, IonIcon, IonCol, IonText, IonItemDivider } from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { trashOutline, addCircleOutline } from "ionicons/icons";
import PaymentTypeImage from "@/components/paymentTypeImage.vue";
import { CreditCard } from "@/models/PaymentModels";

export default defineComponent({
  props: {
    card: CreditCard,
    handleDelete: Function,
    canRemoveCardFromOrder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handleDelete", "handleMakePrimary", "undo"],
  setup(props, { emit }) {
    const store = useStore();
    const canSaveCard = computed(() => store.getters.isLoggedIn);
    const canDeleteCard = computed(() => store.getters.isLoggedIn && props?.card?.id !== "");

    const deleteCard = () => {
      emit("handleDelete", props.card);
    };

    const makePrimary = () => {
      emit("handleMakePrimary", props.card);
    };

    return { trashOutline, addCircleOutline, canSaveCard, canDeleteCard, deleteCard, makePrimary };
  },
  components: {
    IonIcon,
    IonButton,
    IonChip,
    IonCol,
    IonText,
    PaymentTypeImage,
    IonItemDivider,
  },
});
