
import { defineComponent, computed, ref } from "vue";
import { IonIcon, IonLabel, IonTabBar, IonTabButton, IonFab, IonFabButton } from "@ionic/vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { home, homeOutline, pricetags, pricetagsOutline, person, personOutline, gift, giftOutline } from "ionicons/icons";
import accountModal from "@/components/modals/accountModal.vue";

export default defineComponent({
  components: {
    IonLabel,
    IonTabButton,
    IonTabBar,
    IonIcon,
    IonFab,
    IonFabButton,
    accountModal,
  },
  methods: {
    isActiveRoute(path: string): boolean {
      return this.router.currentRoute.value.path == path;
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isNative = computed(() => store.getters.isNative);
    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const isAccountModalOpen = ref();
    const hideTabNavigation = computed(() => router.currentRoute.value.meta?.hideTabNavigation);

    const delayAccountModalClose = () => setTimeout(() => (isAccountModalOpen.value = false), 200);

    return {
      router,
      isNative,
      home,
      homeOutline,
      pricetags,
      pricetagsOutline,
      person,
      personOutline,
      gift,
      giftOutline,
      isAccountModalOpen,
      isLoggedIn,
      delayAccountModalClose,
      hideTabNavigation,
    };
  },
});
