import { IonicAuth } from "@ionic-enterprise/auth";
import useVault from "./vaultService";
import useAuthConfig from "./authConfig";

/**
 * https://ionic.io/docs/auth-connect/classes/ionicauth
 */
class AuthenticationService extends IonicAuth {
  constructor() {
    const { vault } = useVault();
    const { config } = useAuthConfig();
    config.tokenStorageProvider = vault;
    super(config);
  }
}

export const authService = new AuthenticationService();

const getCognitoUserInfo = async () => {
  const idToken = await authService.getIdToken();
  if (!idToken) return;
  let email = idToken.email;
  if (idToken.emails instanceof Array) {
    email = idToken.emails[0];
  }

  return {
    id: idToken.sub,
    email: email,
    emailVerified: idToken.email_verified,
    name: idToken.name,
  };
};

const handleLoginCallback = async () => {
  authService.handleLoginCallback();
};

/**
 * Auth Connect includes a method called isAuthenticated().
 * This method resolves true if a valid access token exists, and false otherwise.
 * If the current access token has expired, this method will attempt to refresh the token
 * before determining if it should resolve true or false.
 * @returns Promise<boolean>
 */
const isAuthenticated = async (): Promise<boolean> => {
  return await authService.isAuthenticated();
};

export default (): any => {
  return {
    getAccessToken: (): Promise<string | undefined> => authService.getAccessToken(),
    getIdToken: (): Promise<string | undefined> => authService.getAuthResponse(),
    getCognitoUserInfo,
    handleLoginCallback,
    isAuthenticated,
    login: (): Promise<void> => authService.login(),
    logout: () => authService.logout(),
    refreshSession: () => authService.refreshSession(),
    isAccessTokenExpired: () => authService.isAccessTokenExpired(),
    clearStorage: () => authService.clearStorage(),
    isRefreshTokenAvailable: (): Promise<boolean> => authService.isRefreshTokenAvailable(),
    getRefreshToken: (): Promise<string | undefined> => authService.getRefreshToken(),
  };
};
