
import { computed, defineComponent, PropType } from "vue";
import { IonRow, IonCol, IonButton, IonItem, IonLabel } from "@ionic/vue";
import contentBlock from "@/components/contentBlock.vue";
import { Account } from "@/models/account/authModels";
import { inlineOrderHistory } from "@/services/orderService";
import inlineList from "@/components/inlineList.vue";
import { HistoryOrder } from "@/models/cartModels";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  emits: ["close"],
  props: { account: { type: Object as PropType<Account>, required: true }, addOrderBtn: Boolean },
  components: { contentBlock, IonRow, IonCol, IonButton, IonItem, IonLabel, inlineList },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const history = computed((): HistoryOrder[] => store.getters.getOrderHistory);
    const isMobile = computed(() => store.getters.isMobile);
    const addToCart = (order: HistoryOrder) => {
      emit("close");
      store.dispatch("addOrderToCart", order).then(() => router.push("/cart"));
    };

    return { history, addToCart, inlineOrderHistory, emit, isMobile };
  },
});
