
import { cardTypeEnum } from "@/enums/PaymentTypeEnum";

export default {
  props: {
    type: { required: true, type: String },
  },
  setup() {
    return {
      cardTypeEnum,
    };
  },
};
