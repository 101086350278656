import { IonicAuthOptions } from "@ionic-enterprise/auth";
import { isPlatform } from "@ionic/vue";

export default (): { config: IonicAuthOptions } => {
  const baseConfig = {
    clientID: process.env.VUE_APP_COGNITO_ID,
    discoveryUrl: process.env.VUE_APP_COGNITO_DISCOVERY_URL,
    clientSecret: process.env.VUE_APP_COGNITO_SECRET,
    scope: "openid email profile aws.cognito.signin.user.admin",
    audience: "",
  };

  const mobileAuthConfig: IonicAuthOptions = {
    ...baseConfig,
    authConfig: "cognito",
    redirectUri: isPlatform("ios") ? "com.donatos.app://login" : "com.donatos.phoenix://login",
    logoutUrl: isPlatform("ios") ? "com.donatos.app://" : "com.donatos.phoenix://",
    platform: "capacitor",
    // The type of iOS webview to use. 'shared' will use a webview that can
    // share session/cookies on iOS to provide SSO across multiple apps but
    // will cause a prompt for the user which asks them to confirm they want
    // to share site data with the app. 'private' uses a webview which will
    // not prompt the user but will not be able to share session/cookie data
    // either for true SSO across multiple apps.
    iosWebView: "private",
    // the setup we need is "CURRENT", popups are blocking login on safari
    // currently unable to figure out callbacks
    // https://ionic.io/docs/auth-connect/classes/ionicauth#handlelogoutcallback
    implicitLogin: "CURRENT",
    androidToolbarColor: "#ad1b29",
  };

  const webAuthConfig: IonicAuthOptions = {
    ...baseConfig,
    authConfig: "cognito",
    redirectUri: process.env.VUE_APP_BASE_URL + "/login",
    logoutUrl: process.env.VUE_APP_BASE_URL + "/",
    // the setup we need is "CURRENT", popups are blocking login on safari
    // currently unable to figure out callbacks
    // https://ionic.io/docs/auth-connect/classes/ionicauth#handlelogoutcallback
    implicitLogin: "CURRENT",
    platform: "web",
  };

  const config = isPlatform("hybrid") ? mobileAuthConfig : webAuthConfig;

  return { config };
};

export const passwordResetUrl = `${process.env.VUE_APP_COGNITO_URL}/forgotPassword?client_id=${
  process.env.VUE_APP_COGNITO_ID
}&redirect_uri=${encodeURIComponent(process.env.VUE_APP_BASE_URL)}/login&response_type=token&response_mode=fragment`;

export const SignUpUrl = `${process.env.VUE_APP_COGNITO_URL}/signup?client_id=${
    process.env.VUE_APP_COGNITO_ID
}&redirect_uri=${encodeURIComponent(process.env.VUE_APP_BASE_URL)}/login&response_type=token&scope=openid+email+profile+aws.cognito.signin.user.admin&response_mode=fragment`;
