import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, { fill: _ctx.fill }, {
    default: _withCtx(() => [
      (_ctx.label)
        ? (_openBlock(), _createBlock(_component_ion_label, {
            key: 0,
            position: "floating",
            color: _ctx.labelColor
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1),
              (_ctx.required)
                ? (_openBlock(), _createBlock(_component_ion_text, {
                    key: 0,
                    color: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("*")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["color"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_input, {
        placeholder: _ctx.placeholder,
        modelValue: _ctx.localModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
        type: _ctx.type,
        autocomplete: _ctx.autocomplete
      }, null, 8, ["placeholder", "modelValue", "type", "autocomplete"])
    ]),
    _: 1
  }, 8, ["fill"]))
}