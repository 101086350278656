import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_payment_type_image = _resolveComponent("payment-type-image")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_item_divider, { mode: "md" }),
    (_ctx.canSaveCard)
      ? (_openBlock(), _createBlock(_component_ion_col, {
          key: 0,
          size: "5",
          class: "interstate-bold"
        }, {
          default: _withCtx(() => [
            (_ctx.card?.default)
              ? (_openBlock(), _createBlock(_component_ion_chip, {
                  key: 0,
                  class: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Primary")
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_chip, {
                  key: 1,
                  class: "secondary",
                  outline: "",
                  onClick: _ctx.makePrimary
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Make Primary")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_col, {
      class: "interstate-bold",
      size: _ctx.canSaveCard ? '5' : '8'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_payment_type_image, {
          type: _ctx.card?.cardType ?? 'MC',
          class: "small"
        }, null, 8, ["type"]),
        _createTextVNode(" *" + _toDisplayString(_ctx.card?.lastFourDigits) + " ", 1),
        _createElementVNode("div", null, [
          _createVNode(_component_ion_text, {
            color: "medium",
            style: {"font-size":"14px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode("Expires " + _toDisplayString(_ctx.card?.cardExpirationMonth) + "/" + _toDisplayString(_ctx.card?.cardExpirationYear), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["size"]),
    (_ctx.canDeleteCard || _ctx.canRemoveCardFromOrder)
      ? (_openBlock(), _createBlock(_component_ion_col, {
          key: 1,
          size: "2",
          class: "text-right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              onClick: _ctx.deleteCard,
              class: "icon-button"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  ios: _ctx.trashOutline,
                  md: _ctx.trashOutline,
                  slot: "icon-only"
                }, null, 8, ["ios", "md"])
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_item_divider, { mode: "md" })
  ], 64))
}